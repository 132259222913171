import i18next from 'i18next'
import { initReactI18next } from 'react-i18next';

i18next
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    resources: {
      es: {
        translation: {
          "search": {
            "allergicKit": "El kit del paciente alérgico",
            "allergies": "Alergias",
            "articles": "¿Sabías que…?",
            "diagnosticTreatment": "Diagnóstico y tratamiento",
            "glossary": "Glosario",
            "imagesVideos": "Images y videos",
            "linksOfInterest": "Enlaces de interés",
            "manifestations": "Manifestaciones alérgicas",
            "podcasts": "Podcasts",
            "testimonials": "Testimoniales",
          },
          "menu": {
            "allergies": "ALERGIAS",
            "manifestations": "MANIFESTACIONES ALÉRGICAS",
            "diagnostic_treatment": "DIAGNÓSTICO Y TRATAMIENTO",
            "resources": "RECURSOS",
            "map": "Mapa polínico",
            "testimonials": "Testimoniales",
            "articles": "¿Sabías que…?",
            "podcasts": "Podcasts",
            "advices": "Consejos para padres",
            "videos": "Vídeos",
            "images_videos": "Imágenes y Vídeos",
          },
          "footer": {
            "contact": "Consúltanos",
            "about": "Quiénes somos",
            "faqs": "FAQs",
            "glossary": "Glosario",
            "links_of_interest": "Enlaces de interés",
            "legal_notices": "Avisos legales",
            "cookies_policy": "Política de uso de cookies",
            "data_protection": "Protección de datos",
            "with_approval_of": "Con el aval de:",
          },
          "home": {
            "title": "Conoce todo sobre la alergia, su diagnóstico y tratamiento",
            "search": {
              "placeholder": "Busca en curatualergia",
            },
            "allergies": {
              "title": "Tipos de alergia",
              "subtitle": "Sabe más sobre los tipos de alergia",
              "pollen": "PÓLENES",
              "al_pollen": "Alergia al polen",
              "mites": "ÁCAROS DEL POLVO",
              "animals": "ANIMALES",
              "other": "TODAS LAS ALERGIAS"
            },
            "manifestations": {
              "title": "Manifestaciones alérgicas",
              "subtitle": "¿De qué diferentes maneras se puede manifestar la alergia?",
              "rhinitis": "RINITIS ALÉRGICA",
              "skin_allergy": "MANIFESTACIONES EN LA PIEL",
              "asthma": "ASMA ALÉRGICA",
              "conjunctivitis": "CONJUNTIVITIS ALÉRGICA",
              "anaphylaxis": "ANAFILAXIA",
              "children_manifestations": "Manifestaciones alérgicas en niños",
              "other": "OTRAS MANIFESTACIONES ALÉRGICAS"
            },
            "diagnostic_treatment": {
              "title": "Diagnóstico y tratamiento",
              "of_allergy": "de la alergia",
              "subtitle": "Descubre como puedes saber si tienes alergia y los métodos más eficaces para su tratamiento",
              "squareOne": "¿Cómo saber si tengo alergia?",
              "squareTwo": "¿Cómo puedo tratar mi alergia?",
            },
            "articles": {
              "title": "¿Sabías que…?",
              "button": "Saber más",
              "load_more": "Cargar más..."
            },
            "newsletter": {
              "title": "¡Supera la alergia!",
              "subtitle": "Suscríbete a nuestra Newsletter para recibir consejos sobre la alergia.",
              "email": "Introduce tu email abajo:",
              "accept": "Accepto la",
              "privacy_policy": "política de privacidad",
              "example_email": "email@ejemplo.com",
              "btn": "SUSCRIBIRME"
            },
            "resources": {
              "title": "Recursos",
              "subtitle": "Aquí puedes encontrar herramientas, consejos prácticos y mucho más para ayudar a prevenir y controlar las alergias.",
              "allergist": "Escucha al alergólogo",
              "allergic_kit": "El kit del paciente alérgico",
            },
            "map": {
              "title": "Mapa polínico",
              "north": "Zona Norte",
              "north_description": "Calendario de niveles de polen dominantes durante el año en la zona norte de España (Galicia, Asturias, Navarra, Norte de Aragón, Rioja, Norte de Castilla y León y en Euskadi) con los diferentes tipos de polen y la reactividad cruzada con otros pólenes.",
              "center": "Zona Centro",
              "center_description": "Calendario de niveles de polen dominantes durante el año en la zona centro de España (Madrid, Cataluña occidental, sur de Andalucía, Aragón, Extremadura, Castilla la Mancha y sur de Castilla y León) con los diferentes tipos de polen predominantes y la reactividad cruzada con otros pólenes.",
              "canarias": "Canarias",
              "canarias_description": "Calendario de niveles de polen dominantes durante el año en las Islas Canarias con los diferentes tipos de polen y la reactividad cruzada con otros pólenes.",
              "mediterranean": "Zona Mediterránea",
              "mediterranean_description": "Calendario de niveles de polen dominantes durante el año en el área del Mediterráneo (Valencia, Murcia, Cataluña oriental, sur de Andalucía, Mallorca, Ibiza, Menorca, Ceuta y Melilla) con los diferentes tipos de polen y la reactividad cruzada con otros pólenes.",
              "subtitle": "El mapa de la alergia es una herramienta útil para conocer los tipos de pólenes, donde predominan y en que estaciones."
            },
          },
          "diagnostic_treatment": {
            "diagnostic": "Diagnóstico",
            "treatment": "Tratamiento"
          },
          "related_content": {
            "title": "Contenido relacionado",
            "subtitle": "Información útil, consejos y otras páginas con contenido de interés relacionado con la alergia",
            "fathers": "Consejos para padres",
            "allergist": "Escucha al alergólogo",
          },
          "map": {
            "title": "Mapa polínico",
            "text": "<p>El mapa de la alergia es una herramienta útil para conocer las zonas geográficas en las que predominan unos tipos de polen u otros según la vegetación, así como también en qué estaciones abundan más o menos.</p><p>Esta herramienta permite organizar las salidas, de manera que la persona alérgica pueda evitar las molestias respiratorias debidas al polen del ambiente.</p><p>Haz clic sobre una zona para ver su calendario de polinización.</p>",
            "fathers": "Consejos para padres",
            "allergist": "Escucha al alergólogo",
          },
          "contact": {
            "title": "Consúltanos",
            "name": "Tu nombre",
            "email": "Tu e-mail",
            "message": "Tu mensaje",
            "warning": "<p>Por favor ten en cuenta que este acceso no ha sido diseñado para recoger información de seguridad de los productos LETI. Para notificar sospechas de reacciones adversas o efectos no deseados ocurridos con productos de LETI u obtener información sobre la seguridad de los mismos, por favor contacta con <a href='mailto:farmacovigilancia@leti.com'>farmacovigilancia@leti.com</a></p>",
            "privacy": "<p>He leído y estoy de acuerdo con el <a>aviso de privacidad</a></p>",
            "newsletter": "Deseo recibir información de productos y servicios de LETI Pharma, S.L.U. por correo electrónico",
          },
          "about": {
            "title": "Quiénes somos",
            "text_1": "<p>Bienvenidos a Curatualergia, un recurso dedicado a apoyar a pacientes alérgicos y sus familias en su viaje hacia una vida más saludable y libre de alergias. En Curatualergia, entendemos que vivir con alergias puede presentar desafíos únicos y a menudo desafiantes. Nuestra misión es brindar información valiosa y orientación experta para ayudarte a comprender, prevenir y manejar las alergias de manera efectiva. </p>",
            "text_2": "<p><b>Educación:</b> Creemos que la educación es fundamental para el manejo de las alergias. Proporcionamos información precisa y actualizada sobre una amplia gama de alergias, sus desencadenantes, síntomas y tratamientos. </p><p><b>Apoyo Emocional:</b> Reconocemos que vivir con alergias puede ser emocionalmente desafiante. Ofrecemos recursos y orientación para ayudarte a lidiar con el estrés y la ansiedad que a veces acompañan a las alergias.</p><p><b>Colaboración:</b> Trabajamos en estrecha colaboración con profesionales de la salud, alergólogos y expertos en alergias para asegurarnos de proporcionar información basada en evidencia y consejos confiables.</p> ",
            "text_3": "<p>Te invitamos a explorar nuestro sitio web y unirte a nuestra comunidad. Ya sea que estés buscando consejos sobre cómo prevenir alergias, información sobre pruebas de diagnóstico o simplemente un lugar donde compartir tus experiencias, Curatualergia está aquí para ti.</p><p>Gracias por confiar en nosotros como tu recurso de alergias. Juntos, podemos enfrentar las alergias y trabajar hacia una vida más saludable y libre de preocupaciones.</p><p>¡Bienvenidos a Curatualergia!</p>",
          },
          "faqs": {
            "title": "FAQs",
            "title_1": "¿Qué es curatualergia?",
            "text_1": "<p>Es una web especializada en alergia, con información útil y práctica para conocer esta enfermedad, su prevención y su tratamiento. También pretende ser un punto de encuentro entre el especialista en alergia, es decir, el médico alergólogo, y el paciente.</p>",
            "title_2": "¿Qué objetivo tiene?",
            "text_2": "<p>Esta plataforma dirigida al paciente afectado de alergia pretende realizar una acción divulgativa sobre la alergia y las diferentes opciones de tratamiento, con el objetivo de que el paciente consulte con el profesional de la salud facilitando la búsqueda de la solución más conveniente en cada caso.</p>",
            "title_3": "¿Cómo está distribuida la web?",
            "text_3": "<p>La información se reparte en cuatro bloques: la alergia, manifestaciones alérgicas, diagnóstico y tratamiento y recursos útiles para pacientes alérgicos. La información en la web se presenta por módulos cambiantes en función de las secciones que se destaquen en cada temporada, aunque desde los menús fijos se puede acceder con facilidad a todos los apartados.</p>",
            "title_4": "¿Qué información se puede encontrar?",
            "text_4": "<p><b>La alergia:</b> contiene información general para conocer la enfermedad, las causas, los tratamientos, curiosidades y avances científicos.</p><p><b>Manifestaciones alérgicas:</b> contiene información general para conocer las manifestaciones de alergia, los síntomas principales, sus causas, asi como hacer el diagnóstico y tratamiento.</p><p><b>Diagnóstico y tratamiento:</b> en este bloque encontrarás información sobre como saber si tienes alergia y todas las formas de tratamiento adecuadas.</p><p><b>Recursos:</b> várias páginas con información útil para pacientes alérgicos, que incluyen un mapa polínico, artículos de curiosidades y avances científicos, consejos para padres de niños alérgicos, testimonios de pacientes que han comprobado que convivir con la alergia es posible, podcasts con especialistas en alergia y videos informativos.</p>",
            "title_5": "¿A quién le puede interesar?",
            "text_5": "<p>A las personas afectadas por algún tipo de alergia, a sus familiares y a aquellos profesionales que tengan contacto con niños o adultos con problemas de alergia. Y, en general, a toda persona que quiera estar informada sobre el tema.</p>",
            "title_6": "¿Quién esponsoriza esta web?",
            "text_6": "LETI Pharma, S.L.U. es el responsable de propulsar esta web informativa sobre la enfermedad. Para conocer más sobre Leti, ir al apartado ",
          },
          "glossary": {
            "title": "Glosario"
          },
          "links_of_interest": {
            "title": "Enlaces de interés",
            "subtitle": "¿Buscas más información sobre la alergia? Estos enlaces te permitirán profundizar en aquellos aspectos que más te interesen.",
          },
          "legal_notices": {
            "title": "Avisos legales",
          },
          "cookies": {
            "title": "Política de uso de cookies"
          },
          "advices": {
            "title": "Consejos para padres de niños con alergia",
            "button_kids": "La alergia y los niños",
            "button_school": "Alergia y el colegio",
            "button_play": "Aprender jugando",
            "kids": {
              "subtitle": "Descubre consejos, remedios caseros y otras medidas preventivas para las alergias.",
              "title_1": "¿Cuándo aparece la alergia?",
              "text_1": "<p>Los niños pueden desarrollar alergia igual que cualquier persona adulta. De hecho, aunque puede aparecer en cualquier momento de la vida, las alergias son más frecuentes en niños y adolescentes.<br/>Existe una predisposición hereditaria a las alergias, lo que significa que un niño cuyos padres son alérgicos probablemente desarrolle algún tipo de sensibilización, aunque no tiene por qué ser la misma que la de sus padres. Por ejemplo, si el padre es alérgico al polen, tiene más probabilidades de desarrollar una alergia, pero no necesariamente al polen, sino que puede ser a otros alérgenos ambientales o incluso a alimentos como el huevo.<br/>La rinitis alérgica puede confundirse con un simple resfriado debido a que los síntomas son muy parecidos. En ambos casos puede aparecer dolor de cabeza, estornudos, mucosidad y lagrimeo de ojos, entre otros síntomas, pero hay algunas diferencias que puedes identificar tú mismo.</p>",
              "title_2": "¿La alergia es hereditaria?",
              "text_2": "<p>Existe una predisposición hereditaria a las alergias, lo que significa que un niño cuyos padres son alérgicos probablemente desarrolle algún tipo de sensibilización, aunque no tiene por qué ser la misma que la de sus padres. Por ejemplo, si el padre es alérgico al polen, tiene más probabilidades de desarrollar una alergia, pero no necesariamente al polen, sino que puede ser a otros alérgenos ambientales o incluso a alimentos como el huevo.</p>",
              "title_3": "¿Cómo sé si mi hijo tiene alergia?",
              "text_3": "<p>La puede confundirse con un simple resfriado debido a que los síntomas son muy parecidos. En ambos casos puede aparecer dolor de cabeza, estornudos, mucosidad y lagrimeo de ojos, entre otros síntomas, pero hay algunas diferencias que puedes identificar tú mismo.</p>",
              "title_4": "¿Quieres saber si tu hijo o tú tenéis alergia?",
              "text_4": "¡Descúbrelo con el siguiente prueba!",
              "table": {
                "heading": [
                  "Rinitis Alérgica",
                  "Resfriado Común"
                ],
                "row_1": [
                  "<p><b>Los síntomas aparecen</b> de repente ante la exposición al alérgeno</p>",
                  "<p><b>Los síntomas aparecen</b> de forma progresiva</p>",
                ],
                "row_2": [
                  "<p><b>La duración de los episódios</b> es corta pero periódico. A veces unas horas, dependiendo del tiempo de exposición al alérgeno</p>",
                  "<p><b>La duración de los episódios</b> es geralmente de 7 a 10 dias</p>",
                ],
                "row_3": [
                  "<p><b>Fiebre:</b> no, nunca</p>",
                  "<p><b>Fiebre:</b> si, a veces</p>",
                ],
                "row_4": [
                  "<p><b>Mucosidad:</b> acuosa y transparente</p>",
                  "<p><b>Mucosidad:</b> espessa y amarila</p>",
                ],
                "row_5": [
                  "<p><b>Picor o prurito:</b> picor nasal y/o ocular</p>",
                  "<p><b>Picor o prurito:</b> raramente</p>",
                ],
                "row_6": [
                  "<p><b>En los ojos:</b> lagrimeo</p>",
                  "<p><b>En los ojos:</b> congestión</p>",
                ],
                "row_7": [
                  "<p><b>Estornudos:</b> si, frecuentemente y más de 5 seguidos</p>",
                  "<p><b>Estornudos:</b> si, a veces e menos de 5 seguidos</p>",
                ],
                "row_8": [
                  "<p><b>Nariz taponada:</b> si, alternando los dois lados</p>",
                  "<p><b>Nariz taponada:</b> taponada en un lado</p>",
                ],
                "row_9": [
                  "<p><b>Tos:</b> a veces</p>",
                  "<p><b>Tos:</b> frecuentemente</p>",
                ],
                "row_10": [
                  "<p><b>Ronquera:</b> a veces</p>",
                  "<p><b>Ronquera:</b> frecuentemente</p>",
                ],
                "row_11": [
                  "<p><b>Malestar general:</b> no</p>",
                  "<p><b>Malestar general:</b> si, pero leve</p>",
                ]
              },
              "title_5": "¿Cómo explicarle qué es la alergia?",
              "text_5": "<p>Las personas podemos protegernos de animales o factores que podrían hacernos daño o molestarnos. Por ejemplo: si de repente nos encontramos una araña frente a nosotros, saldremos corriendo. Pero ¿qué pasa cuando lo que nos puede hacer daño es tan pequeño que no lo vemos? Nuestro cuerpo también está preparado para luchar contra estas pequeñas sustancias extrañas. Un ejemplo son los virus o bacterias que entran en nuestro cuerpo causándonos una infección.</p><p>Para luchar contra estas sustancias externas, nuestro cuerpo tiene un ejército de defensa que se llama <b>sistema inmune</b>. El sistema inmune los ataca para destruirlos y expulsarlos del cuerpo. Así cuando un virus entra a través de nuestra nariz al respirar, el sistema inmune se activa y nos hace estornudar para que podamos expulsarlo fuera del cuerpo.</p><p>Pero <b>en algunas personas el sistema inmune se equivoca</b> y responde de manera exagerada frente a estas sustancias extrañas. Estas sustancias son inocuas para la mayoría de las personas, pero no para las personas alérgicas, las cuales, en contacto con ellas, desarrollan una reacción alérgica, pudiendo experimentar diferentes síntomas como picor, hinchazón y/o enrojecimiento de la piel, dificultad para respirar, problemas digestivos… o incluso problemas más graves.</p>",
              "title_6": "Tratamiento con inmunoterapia",
              "text_6": "<p>Además de los tratamientos médicos utilizados para aliviar los síntomas, la alergia se puede combatir con tratamientos de inmunoterapia, también conocidos como vacunas antialérgicas. Estos tratamientos consisten en administrar pequeñas dosis del agente causante de la alergia, en repetidas ocasiones. De este modo el cuerpo se acostumbra al alérgeno progresivamente, hasta que deja de reaccionar de forma exagerada.</p>",
            },
          },
          "general": {
            "newsletter_button": "¿QUIERES SABER CÓMO SUPERAR LA ALERGIA?",
            "share": "Compartir",
            "required": "Este campo es obligatorio",
            "download_pdf": "Descargar PDF",
            "button": {
              "more": "Saber más"
            },
            "name": "Nombre",
            "slug": "Slug",
            "image": "Image",
            "principal content": "Contenido Principal",
            "related content": "Contenido Relacionado",
            "symptoms": "Síntomas y causas",
            "prevention": "Prevención",
            "diagnostic": "Diagnóstico y tratamiento",
            "allergy_know_more": "Conoce más sobre la prevención y tratamiento de la alergia",
            "allergies": "Alergias",
            "articles": "Artículos",
            "manifestations": "Manifestaciones"
          },
          "allergies": {
            "body_1": "Llamamos alergia a la <b>reacción que algunas personas tienen a ciertas sustancias</b> que para la mayoría no es problemática, pero, sin embargo, <b>en las personas hipersensibles, la reacción es desmesurada</b>.",
            "body_2": "La sustancia causante de la reacción se conoce como alérgeno, que, según su naturaleza, causará un tipo de alergia u otro.",
            "pollen": "PÓLENES",
            "animals": "ANIMALES",
            "mites": "ÁCAROS DEL POLVO",
            "foods": "ALIMENTOS",
            "fungi": "HONGOS",
            "insects": "INSECTOS",
            "medicines": "MEDICAMENTOS",
            "allergy_in_children": "La alergia en niños",
            "other": "TODAS AS ALERGIAS"
          },
          "symptoms_and_causes": {
            "title": "Principales síntomas y causas",
            "body_1": "La alergia es una respuesta inmunológica anormal del cuerpo ante la exposición a ciertas sustancias, conocidas como alérgenos. Estos alérgenos pueden variar desde el polen y los ácaros del polvo hasta los alimentos, los medicamentos, animales domésticos, hongos, etc. Cuando una persona alérgica entra en contacto con su alérgeno desencadenante, el sistema inmunológico reacciona de forma exagerada, lo que provoca una serie de síntomas molestos.",
            "body_2": "Los síntomas de la alergia pueden manifestarse de diferentes maneras en cada individuo, pero algunos de los más comunes incluyen:",
            "symptoms_1": "Picazón en la piel, los ojos, la nariz o la garganta.",
            "symptoms_2": "Estornudos frecuentes.",
            "symptoms_3": "Congestión nasal y secreción nasal.",
            "symptoms_4": "Enrojecimiento y picazón en los ojos.",
            "symptoms_5": "Tos seca o irritación de garganta.",
            "symptoms_6": "Dificultad para respirar o respiración sibilante.",
            "symptoms_7": "Fatiga y sensación de cansancio.",
            "symptoms_8": "Irritabilidad o cambios de humor.",
            "symptoms_9": "Aparición de ronchas o erupciones cutáneas.",
            "highlight_1": "Es importante destacar que los síntomas pueden variar en intensidad y duración, y pueden verse influenciados por el tipo de alérgeno al que se está expuesto. Además, es fundamental reconocer las causas subyacentes de la alergia para evitar o minimizar la exposición a los alérgenos desencadenantes.",
            "causes_of_allergy": "Las causas de la alergia son diversas y pueden incluir factores genéticos y ambientales. Algunos de los causantes más frecuentes de alergia son:",
            "causes_1": "Polen de plantas y árboles.",
            "causes_2": "Ácaros del polvo presentes en el hogar.",
            "causes_3": "Pelo o caspa de animales domésticos.",
            "causes_4": "Alimentos, como leche, huevos, mariscos, nueces y trigo.",
            "causes_5": "Medicamentos, como penicilina o aspirina.",
            "causes_6": "Insectos, como las abejas o las avispas.",
            "body_3": "El diagnóstico preciso de la alergia se realiza a través de pruebas específicas, como pruebas cutáneas o análisis de sangre, que ayudan a identificar los alérgenos responsables de los síntomas. Una vez que se ha determinado el diagnóstico, el tratamiento de la alergia puede implicar medidas de prevención, medicamentos y terapias específicas, según las necesidades de cada paciente. ",
            "body_4": "Si experimentas alguno de los síntomas mencionados o sospechas que podrías tener una alergia, es recomendable consultar a un especialista en alergias para recibir un diagnóstico adecuado y un plan de tratamiento personalizado.",
            "highlight_2": "Recuerda que comprender los síntomas y las causas de la alergia es fundamental para tomar medidas preventivas y mantener un control adecuado de la enfermedad. Consulta siempre a un profesional de la salud para obtener orientación específica sobre tu situación alérgica.",
          },
          "prevent_allergy": {
            "title": "Como prevenir la alergia",
            "body_1": "En este apartado, te proporcionaremos información sobre la prevención de las alergias, que es fundamental para reducir la exposición a los alérgenos y evitar las reacciones alérgicas. Tomar medidas preventivas adecuadas puede ayudar a controlar los síntomas y mejorar la calidad de vida de las personas alérgicas.",
            "strategies": "Aquí tienes algunas estrategias de prevención que puedes implementar:",
            "strategy_1_title": "Identifica los desencadenantes",
            "strategy_1_text": "Es importante conocer los alérgenos específicos que te afectan. Pueden ser polen, ácaros del polvo, pelo de mascotas, hongos u otros. Consulta a un especialista en alergias para determinar tus desencadenantes y recibir orientación personalizada.",
            "strategy_2_title": "Controla el entorno doméstico",
            "strategy_2_text": "Realiza una limpieza regular en tu hogar para reducir la presencia de alérgenos. Aspira con frecuencia, utiliza fundas protectoras en colchones y almohadas, lava la ropa de cama con agua caliente y mantén un ambiente con niveles de humedad adecuados.",
            "strategy_3_title": "Evita la exposición a alérgenos en el exterior",
            "strategy_3_text": "Si eres alérgico al polen, intenta evitar salir al aire libre durante los días con niveles altos de polen. Si necesitas estar fuera, usa gafas de sol para proteger tus ojos y considera usar una mascarilla para filtrar el polen del aire.",
            "strategy_4_title": "Controla la exposición a los alérgenos alimentarios",
            "strategy_4_text": "Si tienes alergia a alimentos, lee cuidadosamente las etiquetas de los productos y evita los alimentos que contengan ingredientes a los que eres alérgico. Considera llevar contigo una identificación médica o una tarjeta de alergia en caso de emergencia.",
            "strategy_5_title": "Consulta a un especialista en alergias",
            "strategy_5_text": "Un especialista en alergias puede brindarte orientación específica sobre cómo prevenir y controlar tus alergias. Ellos pueden recomendarte medidas adicionales de prevención y opciones de tratamiento adecuadas para tu situación.",
            "highlight_1": "Recuerda que la prevención de las alergias es un enfoque individualizado y puede variar según la persona y el tipo de alergia. Siempre consulta a un profesional médico para recibir orientación adecuada a tu caso particular.",
          },
          "our_patients": {
            "title": "Que dicen nuestros pacientes",
            "body_1": "Descubre las voces de pacientes que han comprobado que convivir con la alergia es posible",
            "more_testimonials": "Más testimoniales",
          },
          "manifestations": {
            "body_1": "En esta sección, profundizaremos en las <b>principales reacciones alérgicas</b> que pueden experimentar las personas alérgicas, proporcionando el conocimiento esencial para reconocerlas y abordarlas de manera oportuna.",
            "body_2": "Exploraremos <b>manifestaciones alérgicas respiratorias como la rinitis alérgica y el asma</b>, que pueden afectar la respiración y alterar las actividades diarias. ",
            "body_3": "Las manifestaciones alérgicas cutáneas, como la urticaria y la dermatitis atópica, pueden causar erupciones incómodas y picazón. También abordaremos manifestaciones alérgicas oculares y alimentarias.",
          },
          "images_videos": {
            "images": "Imágenes",
            "videos": "Vídeos",
            "body": "Te invitamos a sumergirte en este espacio interactivo, aprovechar al máximo nuestros recursos y sentirte más capacitado para enfrentar las alergias en tu día a día. A medida que explores y aprendas, recuerda que estamos aquí para brindarte apoyo y respuestas a tus preguntas. Juntos, avanzamos hacia un futuro en el que las alergias sean más manejables y menos restrictivas. Disfruta de tu viaje de descubrimiento en nuestra Galería de Alérgenos y Recursos Audiovisuales",
          },
          "testimonials": {
            "title": "Testimoniales",
            "subtitle": "Vea los testimonios de pacientes alérgicos y descubra cómo es vivir con alergias."
          },
          "podcasts": {
            "title": "Escucha al alergólogo",
            "subtitle": "Descubre los últimos podcasts con alergólogos sobre el tratamiento, el diagnóstico y preguntas frecuentes sobre alergia."
          },
          "allergic_kit": {
            "title": "El kit del paciente alérgico"
          },
          "privacy_warning": {
            "title": "Aviso de privacidad",
            "subtitle": "Información básica sobre Protección de Datos referente al tratamiento de datos de personas que contactan con nosotros a través de la web",
            "topic_1": "Responsable",
            "text_1": "LETI Pharma, S.L.U.",
            "topic_2": "Finalidad",
            "text_2": "Atender su petición",
            "topic_3": "Legitimación",
            "text_3": "Consentimiento del interesado",
            "topic_4": "Destinatarios",
            "text_4": "No se comunicarán datos a terceros, salvo obligación legal.",
            "topic_5": "Derechos",
            "text_5": "Acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional",
            "topic_6": "Información adicional",
            "text_6": "<p className='m-0'>Puede consultar la información adicional y detallada sobre Protección de Datos en <a href='https://www.leti.com/protecciondedatos' target='_blank'>www.leti.com/protecciondedatos</a></p>",
          }
        }
      },
      pt: {
        translation: {
          "search": {
            "allergicKit": "O kit do paciente alérgico",
            "allergies": "Alergias",
            "articles": "¿Sabías que…?",
            "diagnosticTreatment": "Diagnóstico y tratamiento",
            "glossary": "Glosario",
            "imagesVideos": "Images y videos",
            "linksOfInterest": "Enlaces de interés",
            "manifestations": "Manifestaciones alérgicas",
            "podcasts": "Podcasts",
            "testimonials": "Testimoniales",
          },
          "menu": {
            "allergies": "ALERGIAS",
            "manifestations": "MANIFESTAÇÕES ALÉRGICAS",
            "diagnostic_treatment": "DIAGNÓSTICO E TRATAMENTO",
            "resources": "RECURSOS",
            "map": "Mapa polínico",
            "testimonials": "Testemunhos",
            "articles": "Sabia que…?",
            "podcasts": "Podcasts",
            "advices": "Conselhos para pais",
            "videos": "Vídeos",
            "images_videos": "Imagens e Vídeos",
          },
          "footer": {
            "contact": "Consulte-nos",
            "about": "Quem somos",
            "faqs": "FAQs",
            "glossary": "Glossário",
            "links_of_interest": "Links de interesse",
            "legal_notices": "Avisos legais",
            "cookies_policy": "Política de uso de cookies",
            "data_protection": "Proteção de dados",
            "with_approval_of": "Com o apoio de:",
          },
          "home": {
            "title": "Saiba tudo sobre alergias, o seu diagnóstico e tratamento",
            "search": {
              "placeholder": "Procurar em curatualergia",
              "text": "o único portal para saber tudo sobre alergias, seu diagnóstico e tratamento"
            },
            "allergies": {
              "title": "Tipos de alergia",
              "subtitle": "Saiba mais sobre os tipos de alergias",
              "pollen": "PÓLENES",
              "al_pollen": "Alergia ao pólen",
              "animals": "ANIMAIS",
              "mites": "ÁCAROS DO PÓ",
              "other": "TODAS AS ALERGIAS"
            },
            "manifestations": {
              "title": "Manifestações alérgicas",
              "subtitle": "De que forma se podem manifestar as diferentes alergias?",
              "rhinitis": "RINITE ALÉRGICA",
              "skin_allergy": "MANIFESTAÇÕES ALÉRGICAS NA PELE",
              "asthma": "ASMA ALÉRGICA",
              "conjunctivitis": "CONJUNTIVITE ALÉRGICA",
              "anaphylaxis": "ANAFILAXIA",
              "children_manifestations": "Manifestações alérgicas em crianças",
              "other": "OUTRAS MANIFESTAÇÕES ALÉRGICAS"
            },
            "diagnostic_treatment": {
              "title": "Diagnóstico e tratamento",
              "of_allergy": "da alergia",
              "subtitle": "Descubra como pode saber se tem alergia e os métodos mais eficazes para o seu tratamento",
              "squareOne": "Como posso saber se tenho alergia?",
              "squareTwo": "Como posso tratar a minha alergia?",
            },
            "articles": {
              "title": "Sabia que...?",
              "button": "Saber mais",
              "load_more": "Carregar mais..."
            },
            "newsletter": {
              "title": "Supere a alergia!",
              "subtitle": "Suscreva a nossa Newsletter para receber conselhos sobre a alergia.",
              "email": "Introduza o seu e-mail em baixo:",
              "accept": "Aceito a",
              "privacy_policy": "política de privacidade",
              "example_email": "email@exemplo.com",
              "btn": "SUBSCREVER"
            },
            "resources": {
              "title": "Recursos",
              "subtitle": "Aqui pode encontrar ferramentas, conselhos práticos e muito mais para ajudar a prevenir e controlar as alergias.",
              "fathers": "Conselhos para pais",
              "allergist": "Ouvir o alergologista",
              "allergic_kit": "O kit do paciente alérgico",
            },
            "map": {
              "title": "Mapa polínico",
              "north": "Zona Norte",
              "north_description": "Calendário dos níveis polínicos dominantes durante o ano no norte de Espanha (Galiza, Astúrias, Navarra, Norte de Aragão, Rioja, Norte de Castela e Leão e Euskadi) com os diferentes tipos de pólen e reatividade cruzada com outros pólens.",
              "center": "Zona Centro",
              "center_description": "Calendário dos níveis polínicos dominantes durante o ano na zona centro de Espanha (Madrid, oeste da Catalunha, sul da Andaluzia, Aragão, Extremadura, Castela-la-Mancha e sul de Castela e Leão) com os diferentes tipos de pólen predominantes e reatividade cruzada com outros pólens.",
              "canarias": "Canárias",
              "canarias_description": "Calendário dos níveis polínicos dominantes durante o ano nas Ilhas Canárias com os diferentes tipos de pólen e reatividade cruzada com outros pólens.",
              "mediterranean": "Área Mediterrânica",
              "mediterranean_description": "Calendário dos níveis polínicos dominantes durante o ano na zona mediterrânica (Valência, Múrcia, leste da Catalunha, sul da Andaluzia, Maiorca, Ibiza, Menorca, Ceuta e Melilha) com os diferentes tipos de pólen e reatividade cruzada com outros pólens.",
              "subtitle": "O mapa de alergias é uma ferramenta útil para saber quais são as zonas geográficas dominadas por certos tipos de pólen, bem como as estações do ano em que são mais ou menos abundantes."
            },
          },
          "diagnostic_treatment": {
            "diagnostic": "Diagnóstico",
            "treatment": "Tratamento"
          },
          "related_content": {
            "title": "Conteúdo relacionado",
            "subtitle": "Informações úteis, dicas e outras páginas com conteúdos de interesse relacionados a alergias",
            "fathers": "Conselho para pais",
            "allergist": "Ouvir o alergologista",
          },
          "contact": {
            "title": "Consulte-nos",
            "name": "Nome (obrigatório)",
            "email": "E-mail (obrigatório)",
            "message": "Consulte-nos",
            "warning": "<p>Por favor tenha em conta que este acesso não foi desenhado para recolher informação de segurança dos produtos LETI. Para notificar suspeitas de reações adversas ou efeitos não desejados ocorridos com produtos LETI ou obter informação sobre a segurança dos mesmos, por favor contate a <a href='mailto:farmacovigilancia@leti.com'>farmacovigilancia@leti.com</a></p>",
            "privacy": "<p>Li e estou de acordo com o <a>aviso de privacidade</a></p>",
            "newsletter": "Desejo receber a informação de produtos e serviços de LETI Pharma, S.L.U. por correio eletrónico.",
          },
          "about": {
            "title": "Quem somos",
            "text_1": "<p>Bem-vindo ao Curatualergia, um recurso dedicado a apoiar doentes alérgicos e as suas famílias no seu percurso para uma vida mais saudável e livre de alergias. No Curatualergia, entendemos que viver com alergias pode apresentar desafios únicos e desafiantes. A nossa missão é fornecer informações valiosas e uma orientação especializada para ajudá-lo a compreender, prevenir e tratar as alergias de maneira eficaz. </p>",
            "text_2": "<p><b>Educacão:</b> Acreditamos que a educação é fundamental para a gestão das alergias. Proporcionamos informações precisas e atualizadas sobre uma ampla gama de alergias, os seus desencadeadores, sintomas e tratamentos.</p><p><b>Apoio Emocional:</b> Reconhecemos que viver com alergias pode ser emocionalmente desafiante. Disponibilizamos recursos e orientação para ajudá-lo(a) a lidar com o stress e a ansiedade que às vezes acompanha as alergias.</p><p><b>Colaboração:</b> Trabalhamos em estreita colaboração com profissionais de saúde, alergologistas e especialistas em alergias para garantir que possamos fornecer informação baseada em evidência e conselhos confiáveis.</p> ",
            "text_3": "<p>Convidamo-lo(a) a explorar o nosso site e a unir-se à nossa comunidade. Se procura conselhos sobre como prevenir alergias, informações sobre testes de diagnóstico ou simplesmente um lugar onde partilhar a sua experiência, Curatualergia está aqui para si. </p><p>Obrigado por confiar em nós como o seu recurso de alergias. Juntos, podemos enfrentar as alergias e trabalhar para uma vida mais saudável e livre de preocupações. </p><p>Bem-vindo(a) ao Curatualergia!</p>",
          },
          "faqs": {
            "title": "FAQs",
            "title_1": "O que é curatualergia?",
            "text_1": "<p>É uma pagina web especializada em alergia, com informação útil e prática para conhecer a sua doença, a sua prevenção e o seu tratamento. Também pretende ser um ponto de encontro entre o médico alergologista, e o paciente.</p>",
            "title_2": "Qual é o seu objectivo?",
            "text_2": "<p>Esta plataforma, dirigida ao paciente alérgico, pretende realizar uma ação de divulgação sobre a alergia e as opções de tratamento existentes, com o objetivo de capacitar o paciente com informações válidas sobre a doença alérgica para eventual discussão com o seu médico alergologista, facilitando a procura da solução mais conveniente para cada caso.</p>",
            "title_3": "Como está distribuída a página web?",
            "text_3": "<p>A informação está distribuída em cinco grupos: A alergia, Viver com alergia, Alergia no trabalho, Alergia na escola e Viajar com alergia. A informação na página web apresenta-se em módulos que se alteram em função das secções que se destaquem em cada temporada, ainda que a partir dos menus fixos se possa aceder a todas secções.</p>",
            "title_4": "Que informação se pode encontrar?",
            "text_4": "<p><b>A alergia:</b> contém informação geral para conhecer a doença, as causas, os tratamentos, curiosidades e evoluções científicas.</p><p><b>Viver com alergia:</b> apresenta a informação de maneira prática, contendo conselhos para prevenir as alergias e os problemas associados. A partir deste site também temos a oportunidade de partilhar a nossa experiência com outras pessoas que vivem com alergia.</p><p><b>Alergia no trabalho:</b> dada a importância e a relação que em alguns casos existe entre o trabalho e determinadas alergias, bem como, a repercussão que pode chegar a ter para manter a atividade do dia-a-dia, este site brinda-nos com informação sobre este tema e sobre as medidas que temos ao nosso alcance para prevenir as alergias ocupacionais.</p><p><b>Alergia na escola:</b> As alergias são afeções habituais entre as crianças, por esta razão, é conveniente que as pessoas adultas que cuidam o ambiente escolar, bem como professores, monitores ou outro tipo de funcionários, conheçam esta doença e tenham recursos para poder atuar em certas situações. Neste site poderão encontrar uma serie de conselhos.</p><p><b>Viajar com alergia:</b> para completar toda a visão sobre as alergias, devemos ter em conta as situações especiais como as viagens, já que as alergias em muitas ocasiões estão associadas a zonas geográficas e estações do ano. O site oferece ferramentas para organizar uma viagem com maior comodidade.</p>",
            "title_5": "A quem pode interessar?",
            "text_5": "<p>As pessoas que apresentam algum tipo de alergia, aos seus familiares e aqueles profissionais que tenham contato com crianças ou adultos com problemas de alergia. E, em geral, a todas as pessoas que queiram estar informadas sobre o assunto.</p>",
            "title_6": "Quem é o responsável pelo website?",
            "text_6": "LETI Pharma, S.L.U. é o responsável por impulsionar este site informativo sobre a doença alérgica. Para conhecer mais sobre LETI, clique em",
          },
          "glossary": {
            "title": "Glossário"
          },
          "links_of_interest": {
            "title": "Enlaces de interés",
            "subtitle": "¿Buscas más información sobre la alergia? Estos enlaces te permitirán profundizar en aquellos aspectos que más te interesen.",
          },
          "legal_notices": {
            "title": "Avisos legales"
          },
          "cookies": {
            "title": "Política de uso de cookies"
          },
          "advices": {
            "title": "Conselhos para pais de crianças com alergia",
            "button_kids": "A alergia e as crianças",
            "button_school": "Alergia e a escola",
            "button_play": "Aprender a jogar",
            "kids": {
              "subtitle": "Descubre consejos, remedios caseros y otras medidas preventivas para las alergias.",
              "title_1": "¿Cuándo aparece la alergia?",
              "text_1": "<p>Los niños pueden desarrollar alergia igual que cualquier persona adulta. De hecho, aunque puede aparecer en cualquier momento de la vida, las alergias son más frecuentes en niños y adolescentes.<br/>Existe una predisposición hereditaria a las alergias, lo que significa que un niño cuyos padres son alérgicos probablemente desarrolle algún tipo de sensibilización, aunque no tiene por qué ser la misma que la de sus padres. Por ejemplo, si el padre es alérgico al polen, tiene más probabilidades de desarrollar una alergia, pero no necesariamente al polen, sino que puede ser a otros alérgenos ambientales o incluso a alimentos como el huevo.<br/>La rinitis alérgica puede confundirse con un simple resfriado debido a que los síntomas son muy parecidos. En ambos casos puede aparecer dolor de cabeza, estornudos, mucosidad y lagrimeo de ojos, entre otros síntomas, pero hay algunas diferencias que puedes identificar tú mismo.</p>",
              "title_2": "¿La alergia es hereditaria?",
              "text_2": "<p>Existe una predisposición hereditaria a las alergias, lo que significa que un niño cuyos padres son alérgicos probablemente desarrolle algún tipo de sensibilización, aunque no tiene por qué ser la misma que la de sus padres. Por ejemplo, si el padre es alérgico al polen, tiene más probabilidades de desarrollar una alergia, pero no necesariamente al polen, sino que puede ser a otros alérgenos ambientales o incluso a alimentos como el huevo.</p>",
              "title_3": "¿Cómo sé si mi hijo tiene alergia?",
              "text_3": "<p>La puede confundirse con un simple resfriado debido a que los síntomas son muy parecidos. En ambos casos puede aparecer dolor de cabeza, estornudos, mucosidad y lagrimeo de ojos, entre otros síntomas, pero hay algunas diferencias que puedes identificar tú mismo.</p>",
              "title_4": "¿Quieres saber si tu hijo o tú tenéis alergia?",
              "text_4": "¡Descúbrelo con el siguiente prueba!",
              "table": {
                "heading": [
                  "Rinitis Alérgica",
                  "Resfriado Común"
                ],
                "row_1": [
                  "<p><b>Los síntomas aparecen</b> de repente ante la exposición al alérgeno</p>",
                  "<p><b>Los síntomas aparecen</b> de forma progresiva</p>",
                ],
                "row_2": [
                  "<p><b>La duración de los episódios</b> es corta pero periódico. A veces unas horas, dependiendo del tiempo de exposición al alérgeno</p>",
                  "<p><b>La duración de los episódios</b> es geralmente de 7 a 10 dias</p>",
                ],
                "row_3": [
                  "<p><b>Fiebre:</b> no, nunca</p>",
                  "<p><b>Fiebre:</b> si, a veces</p>",
                ],
                "row_4": [
                  "<p><b>Mucosidad:</b> acuosa y transparente</p>",
                  "<p><b>Mucosidad:</b> espessa y amarila</p>",
                ],
                "row_5": [
                  "<p><b>Picor o prurito:</b> picor nasal y/o ocular</p>",
                  "<p><b>Picor o prurito:</b> raramente</p>",
                ],
                "row_6": [
                  "<p><b>En los ojos:</b> lagrimeo</p>",
                  "<p><b>En los ojos:</b> congestión</p>",
                ],
                "row_7": [
                  "<p><b>Estornudos:</b> si, frecuentemente y más de 5 seguidos</p>",
                  "<p><b>Estornudos:</b> si, a veces e menos de 5 seguidos</p>",
                ],
                "row_8": [
                  "<p><b>Nariz taponada:</b> si, alternando los dois lados</p>",
                  "<p><b>Nariz taponada:</b> taponada en un lado</p>",
                ],
                "row_9": [
                  "<p><b>Tos:</b> a veces</p>",
                  "<p><b>Tos:</b> frecuentemente</p>",
                ],
                "row_10": [
                  "<p><b>Ronquera:</b> a veces</p>",
                  "<p><b>Ronquera:</b> frecuentemente</p>",
                ],
                "row_11": [
                  "<p><b>Malestar general:</b> no</p>",
                  "<p><b>Malestar general:</b> si, pero leve</p>",
                ]
              },
              "title_5": "¿Cómo explicarle qué es la alergia?",
              "text_5": "<p>Las personas podemos protegernos de animales o factores que podrían hacernos daño o molestarnos. Por ejemplo: si de repente nos encontramos una araña frente a nosotros, saldremos corriendo. Pero ¿qué pasa cuando lo que nos puede hacer daño es tan pequeño que no lo vemos? Nuestro cuerpo también está preparado para luchar contra estas pequeñas sustancias extrañas. Un ejemplo son los virus o bacterias que entran en nuestro cuerpo causándonos una infección.</p><p>Para luchar contra estas sustancias externas, nuestro cuerpo tiene un ejército de defensa que se llama <b>sistema inmune</b>. El sistema inmune los ataca para destruirlos y expulsarlos del cuerpo. Así cuando un virus entra a través de nuestra nariz al respirar, el sistema inmune se activa y nos hace estornudar para que podamos expulsarlo fuera del cuerpo.</p><p>Pero <b>en algunas personas el sistema inmune se equivoca</b> y responde de manera exagerada frente a estas sustancias extrañas. Estas sustancias son inocuas para la mayoría de las personas, pero no para las personas alérgicas, las cuales, en contacto con ellas, desarrollan una reacción alérgica, pudiendo experimentar diferentes síntomas como picor, hinchazón y/o enrojecimiento de la piel, dificultad para respirar, problemas digestivos… o incluso problemas más graves.</p>",
              "title_6": "Tratamiento con inmunoterapia",
              "text_6": "<p>Además de los tratamientos médicos utilizados para aliviar los síntomas, la alergia se puede combatir con tratamientos de inmunoterapia, también conocidos como vacunas antialérgicas. Estos tratamientos consisten en administrar pequeñas dosis del agente causante de la alergia, en repetidas ocasiones. De este modo el cuerpo se acostumbra al alérgeno progresivamente, hasta que deja de reaccionar de forma exagerada.</p><p>Para obtener más información sobre el tratamiento con inmunoterapia, consulte a su médico y visite nuestra página <a onClick={() => handleClickLink('tratamiento')} target='_blank'>Tratamiento.</p>",
            },
          },
          "general": {
            "newsletter_button": "QUERES SABER COMO SUPERAR A ALERGIA?",
            "share": "Compartilhar",
            "required": "Este campo é obrigatório",
            "download_pdf": "Descarregar PDF",
            "button": {
              "more": "Saber mais"
            },
            "name": "Nome",
            "slug": "Slug",
            "image": "Imagem",
            "principal content": "Conteúdo Principal",
            "related content": "Conteúdo Relacionado",
            "symptoms": "Sintomas e causas",
            "prevention": "Prevenção",
            "diagnostic": "Diagnóstico e tratamento",
            "allergy_know_more": "Saiba mais sobre prevenção e tratamento de alergias",
            "allergies": "Alergias",
            "articles": "Artigos",
            "manifestations": "Manifestações"
          },
          "allergies": {
            "body_1": "Chamamos de alergia à <b>reação que algumas pessoas apresentam a determinadas substâncias</b> que para a maioria não é problemática, mas, porém, <b>em pessoas hipersensíveis a reação é excessiva</b>.",
            "body_2": "A substância que causa a reação é conhecida como alergénio, que, dependendo de sua natureza, causará um ou outro tipo de alergia.",
            "pollen": "PÓLENES",
            "animals": "ANIMAIS",
            "mites": "ÁCAROS DO PÓ",
            "foods": "ALIMENTOS",
            "fungi": "FUNGOS",
            "insects": "INSETOS",
            "medicines": "MEDICAMENTOS",
            "allergy_in_children": "A ALERGIA EM CRIANÇAS",
          },
          "symptoms_and_causes": {
            "title": "Principais sintomas e causas",
            "body_1": "A alergia é uma resposta imunitária anormal do organismo à exposição a determinadas substâncias, conhecidas como alergénios. Estes alergénios podem ir desde o pólen e os ácaros até aos alimentos, medicamentos, animais de estimação, etc. Quando uma pessoa alérgica entra em contacto com o alergénio desencadeante, o sistema imunitário reage de forma exagerada, dando origem a uma série de sintomas desconfortáveis.",
            "body_2": "Os sintomas de alergia podem manifestar-se de formas diferentes em cada indivíduo, mas alguns dos mais comuns incluem:",
            "symptoms_1": "Comichão na pele, olhos, nariz ou garganta.",
            "symptoms_2": "Espirros frequentes.",
            "symptoms_3": "Congestão nasal e corrimento nasal.",
            "symptoms_4": "Olhos vermelhos e com comichão.",
            "symptoms_5": "Tosse seca ou irritação da garganta.",
            "symptoms_6": "Falta de ar ou pieira.",
            "symptoms_7": "Fadiga e sensação de cansaço.",
            "symptoms_8": "Irritabilidade ou alterações de humor.",
            "symptoms_9": "Aparecimento de urticária ou erupções cutâneas.",
            "highlight_1": "É importante notar que os sintomas podem variar em intensidade e duração, e podem ser influenciados pelo tipo de alergénio a que se está exposto. Além disso, é essencial reconhecer as causas subjacentes da alergia, a fim de evitar ou minimizar a exposição aos alergénios que a desencadeiam.",
            "causes_of_allergy": "As causas da alergia são diversas e podem incluir fatores genéticos e ambientais. Algumas das causas comuns de alergia incluem:",
            "causes_1": "Pólen de plantas e árvores.",
            "causes_2": "Ácaros do pó em casa.",
            "causes_3": "Pelo ou caspa de animais domésticos.",
            "causes_4": "Alimentos, como leite, ovos, marisco, frutos secos e trigo.",
            "causes_5": "Medicamentos, como a penicilina ou a aspirina.",
            "causes_6": "Insetos, como abelhas ou vespas.",
            "body_3": "O diagnóstico exato da alergia é feito através de testes específicos, como testes cutâneos ou análises ao sangue, que ajudam a identificar os alergénios responsáveis pelos sintomas. Uma vez efetuado o diagnóstico, o tratamento da alergia pode passar por medidas preventivas, medicamentos e terapêuticas específicas, consoante as necessidades de cada doente.",
            "body_4": "Se tiver algum dos sintomas acima referidos ou suspeitar que pode ter uma alergia, é aconselhável consultar um especialista em alergias para obter um diagnóstico correto e um plano de tratamento personalizado.",
            "highlight_2": "Lembre-se que compreender os sintomas e as causas da alergia é fundamental para tomar medidas preventivas e manter um controlo adequado da doença. Consulte sempre um profissional de saúde para obter orientações específicas sobre a sua situação de alergia.",
          },
          "prevent_allergy": {
            "title": "Como prevenir a alergia",
            "body_1": "Nesta secção, forneceremos informações sobre a prevenção das alergias, essencial para reduzir a exposição aos alergénios e evitar reações alérgicas. A adoção de medidas preventivas adequadas pode ajudar a controlar os sintomas e a melhorar a qualidade de vida das pessoas que sofrem de alergias.",
            "strategies": "Eis algumas estratégias de prevenção que pode implementar:",
            "strategy_1_title": "Identifique os fatores desencadeantes: ",
            "strategy_1_text": "É importante conhecer os alergénios específicos que o afetam. Estes podem ser pólen, ácaros, pelos de animais, fungos ou outros. Consulte um especialista em alergias para determinar os seus fatores desencadeantes e receber orientação personalizada.",
            "strategy_2_title": "Controle o ambiente doméstico:",
            "strategy_2_text": "Limpe a sua casa regularmente para reduzir a presença de alergénios. Aspire frequentemente, utilize coberturas protetoras nos colchões e almofadas, lave a roupa de cama com água quente e mantenha um ambiente com níveis de humidade adequados.",
            "strategy_3_title": "Evite a exposição a alergénios no exterior:",
            "strategy_3_text": "Se é alérgico ao pólen, tente evitar sair à rua em dias com níveis elevados de pólen. Se tiver de sair, use óculos de sol para proteger os olhos e considere a possibilidade de usar uma máscara para filtrar o pólen do ar.",
            "strategy_4_title": "Controle a exposição a alergénios alimentares: ",
            "strategy_4_text": "Se tem alergias alimentares, leia atentamente os rótulos dos produtos e evite alimentos que contenham ingredientes aos quais é alérgico. Considere levar consigo uma identificação médica ou um cartão de alergia em caso de emergência.",
            "strategy_5_title": "Consulte um especialista em alergias: ",
            "strategy_5_text": "Um médico alergologista pode fornecer orientações específicas sobre como prevenir e controlar as suas alergias. Pode recomendar medidas de prevenção adicionais e opções de tratamento adequadas à sua situação.",
            "highlight_1": "Lembre-se de que a prevenção das alergias é uma abordagem individualizada e pode variar consoante a pessoa e o tipo de alergia. Consulte sempre um profissional médico para obter orientações adequadas ao seu caso particular.",
          },
          "our_patients": {
            "title": "O que dizem os nossos pacientes",
            "body_1": "Conheça as vozes de pacientes que provaram que é possível conviver com alergias",
            "more_testimonials": "Mais depoimentos",
          },
          "manifestations": {
            "body_1": "En esta sección, profundizaremos en las <b>principales reacciones alérgicas</b> que pueden experimentar las personas alérgicas, proporcionando el conocimiento esencial para reconocerlas y abordarlas de manera oportuna.",
            "body_2": "Exploraremos <b>manifestaciones alérgicas respiratorias como la rinitis alérgica y el asma</b>, que pueden afectar la respiración y alterar las actividades diarias. ",
            "body_3": "Manifestações alérgicas na pele, como urticária e dermatite atópica, podem causar erupções cutâneas desconfortáveis ​​e com coceira. Abordaremos também as manifestações alérgicas oculares e alimentares.",
          },
          "legal_notices": {
            "title": "Avisos legais",
          },
          "images_videos": {
            "images": "Imagens",
            "videos": "Vídeos",
            "body": "Convidamo-lo a mergulhar neste espaço interativo, aproveitar ao máximo os nossos recursos e sentir-se mais capacitado para lidar com as alergias no seu dia a dia. À medida que explora e aprende, lembre-se de que estamos aqui para fornecer suporte e respostas às suas perguntas. Juntos, avançamos em direção a um futuro onde as alergias serão mais controláveis ​​e menos restritivas. Aproveite a sua jornada de descoberta em nossa Galeria de Alérgenos e Recursos Audiovisuais",
          },
          "testimonials": {
            "title": "Testemunhos",
            "subtitle": "Vea los testimonios de pacientes alérgicos y descubra cómo es vivir con alergias."
          },
          "podcasts": {
            "title": "Ouvir o alergologista",
            "subtitle": "Descubra os podcasts mais recentes com alergologistas sobre tratamento de alergias, diagnóstico e perguntas frequentes."
          },
          "allergic_kit": {
            "title": "O kit do paciente alérgico"
          },
          "privacy_warning": {
            "title": "Aviso de privacidade",
            "subtitle": "Informações básicas sobre a Proteção de Dados no âmbito do tratamento de dados de pessoas que contactam a LETI através da página Web",
            "topic_1": "Responsável",
            "text_1": "LETI Pharma, S.L.U.",
            "topic_2": "Finalidade",
            "text_2": "Atender ao seu pedido.",
            "topic_3": "Fundamento jurídico",
            "text_3": "Consentimento do titular dos dados.",
            "topic_4": "Destinatários",
            "text_4": "Salvo obrigação legal em contrário, não se comunicarão dados a terceiros.",
            "topic_5": "Direitos",
            "text_5": "Direito de acesso, retificação e apagamento dos dados, bem como outros direitos, conforme explicado nas informações suplementares.",
            "topic_6": "Informações suplementares",
            "text_6": "<p className='m-0'>Para consultar informações suplementares e detalhadas sobre a Proteção de Dados, aceda a <a href='https://www.leti.com/protecciondedatos' target='_blank'>www.leti.com/protecciondedatos</a></p>",
          }
        }
      }
    }
  })
