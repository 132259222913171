import { Modal, Form, Input, Upload, Row, Col, Drawer, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios'
import './ModalEditImageVideo.css';
import { useEffect, useState } from 'react';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';

const { useForm } = Form;
const { Dragger } = Upload;

export default function ModalEditImageVideo({ open, close, lang, data }) {

  const [form] = useForm();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState('');

  useEffect(() => {
    if (data.image) {
      setFileList([{ uid: 'not_new', name: data.image }]);
      setFilePreview(data.image);
      data.image = [{ uid: 'not_new', name: data.image }];
    }

    form.setFieldsValue(data);
  }, [data])


  const uploadProps = {
    accept: 'image/*',
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview('');
    },
    beforeUpload: (file) => {
      handlePreview(file);
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: []
  };

  function handleSubmit(values) {
    values.lang = lang;
    let formData = new FormData();

    if (values.type === 'image') {
      fileList.forEach((file) => {
        if (file.uid !== 'not_new') {
          formData.append('file', file);
        } else {
          values.image = fileList[0].name
        }
      });
    }

    formData.append('data', JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.images_videos.editImageVideo}`, {
      method: "POST",
      body: formData
    }).then((res) => {
      setIsButtonLoading(true);
      setTimeout(() => {
        handleClose();
      }, 1500);
    }).catch((error) => {
      console.log(error);
      setIsButtonLoading(false);
    });
  }

  function handleClose() {
    setFileList([]);
    setFilePreview('')
    form.resetFields();
    setIsButtonLoading(false);
    close();
  }

  async function handlePreview(file) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview)
  };


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChangeType(e) {
    if (e === 'video') {
      form.setFieldValue('image', null);
      setFileList([]);
      setFilePreview('')
    } else {
      form.setFieldValue('iframe', null);
    }
  }

  return (
    <Modal
      className='add_podcast'
      open={open}
      onOk={form.submit}
      onCancel={handleClose}
      title="Create Podcast"
      okText={"Guardar"}
      cancelText={"Cancelar"}
      okButtonProps={{ loading: isButtonLoading }}
      cancelButtonProps={{ disabled: isButtonLoading }}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          hidden
          name="id"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Select
            placeholder="Choose your type"
            options={[
              {
                value: 'video',
                label: 'Video'
              },
              {
                value: 'image',
                label: 'Image'
              }
            ]}
            onChange={handleChangeType}
            size='large'
          />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
        >
          {({ getFieldValue }) =>
            getFieldValue('type') === 'image' ? (
              <Form.Item
                name="image"
                label="Image"
                rules={[
                  {
                    required: true,
                    message: 'Este campo é obrigatório'
                  }
                ]}
              >
                <Dragger {...uploadProps} className={`dragger ${filePreview ? 'not_show' : ''}`}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                    banned files.
                  </p>
                  <div
                    className={`preview_file ${filePreview ? '' : 'not_show'}`}
                    style={{ backgroundImage: `url(${(filePreview && filePreview.includes('base64')) ? filePreview : `${config.server_ip}assets/media/${filePreview}`})` }}
                  >
                  </div>
                </Dragger>
              </Form.Item>
            ) :
              getFieldValue('type') === 'video' ? (
                <Form.Item
                  name="iframe"
                  label="Link"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório'
                    }
                  ]}
                >
                  <Input size='large' placeholder='Link...'></Input>
                </Form.Item>)
                :
                null
          }
        </Form.Item>
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input size='large' placeholder='Nome...'></Input>
        </Form.Item>
        <Form.Item
          name="description"
          label="Descrição"
        >
          <Input size='large' placeholder='Descrição...'></Input>
        </Form.Item>
      </Form>
    </Modal>
  )
};