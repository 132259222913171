import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover, Input } from 'antd';
import { ArrowRightOutlined, CopyOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import './Manifestations.css';
import DrawerAddManifestation from '../../../components/dashboard/DrawerAddManifestation/DrawerAddManifestation';
import DrawerEditManifestation from '../../../components/dashboard/DrawerEditManifestation/DrawerEditManifestation';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';

const columns = [
  {
    title: '',
    dataIndex: 'img',
    key: 'img',
    width: '10%'
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Slug',
    dataIndex: 'slug',
    key: 'slug',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];


function Manifestations({ lang }) {

  const [tableData, setTableData] = useState([]);
  const [manifestations, setManifestations] = useState([]);
  const [selectedManifestation, setSelectedManifestation] = useState({});
  const [isOpenModalCreateManifestation, setIsOpenModalCreateManifestation] = useState(false);
  const [isOpenModalEditManifestation, setIsOpenModalEditManifestation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetManifestations();
  }, [lang]);

  function handleGetManifestations() {
    axios.get(endpoints.manifestations.getManifestations, {
      params: { lang }
    }).then((res) => {
      let manifestationsAux = res.data;
      let aux = [];
      for (let i = 0; i < manifestationsAux.length; i++) {
        manifestationsAux[i].related_content = manifestationsAux[i].related_content ? JSON.parse(manifestationsAux[i].related_content) : null
        aux.push(manifestationsAux[i])
      }

      setManifestations(aux);
      handlePrepareTableData(aux);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDeleteManifestation(item) {
    axios.post(endpoints.manifestations.deleteManifestation, {
      data: { id: item.id, lang }
    }).then((res) => {
      handleGetManifestations();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDuplicateToOtherLang(item) {
    axios.post(endpoints.manifestations.duplicateManifestationOtherLang, {
      data: { item, lang: lang === "pt" ? "es" : "pt" }
    }).then((res) => {
      handleGetManifestations();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDuplicate(item) {
    let auxItem = JSON.parse(JSON.stringify(item))
    auxItem.name = `${item.name} Copy`;
    auxItem.slug = `${item.name}-copy`;

    axios.post(endpoints.manifestations.duplicateManifestation, {
      data: { item: auxItem, lang: lang  }
    }).then((res) => {
      handleGetManifestations();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handlePrepareTableData(data) {
    let auxTableData = [];

    for (let i = 0; i < data.length; i++) {

      const content = (
        <div>
          <p>Want to duplicate to {lang === "pt" ? "ES" : "PT"}?</p>
          <Button type="primary" onClick={() => handleDuplicateToOtherLang(data[i])}>Duplicate</Button>
        </div>
      );

      const contentDuplicate = (
        <div>
          <p>Want to duplicate?</p>
          <Button type="primary" onClick={() => handleDuplicate(data[i])}>Duplicate</Button>
        </div>
      );

      const contentDelete = (
        <div>
          <p>Are you sure you want to delete:</p>
          <p>{data[i].name}?</p>
          <div className='buttons_popover'>
            <Button type="primary" onClick={() => handleDeleteManifestation(data[i])}>Delete</Button>
          </div>
        </div>
      );

      let aux = {
        key: i,
        img: <div className='img_table' style={{ backgroundImage: `url(${config.server_ip}assets/media/${data[i].img})` }}></div>,
        name: data[i].name,
        slug: data[i].slug,
        actions: (<div className='icons_table'>
          <EditOutlined className="icon icon_edit mr-10" onClick={() => handleOpenModalEditManifestation(data[i])} />
          <Popover placement="topLeft" title={"Delete"} content={contentDelete} trigger="click">
            <DeleteOutlined className="icon icon_edit mr-10" />
          </Popover>
          <Popover placement="topLeft" title={"Duplicate"} content={contentDuplicate} trigger="click">
            <CopyOutlined className="icon icon_edit mr-10" />
          </Popover>
          <ArrowRightOutlined className="icon icon_edit mr-10" onClick={() => navigate(`/admin/manifestations/${data[i].id}`)} />
          <Popover placement="topLeft" title={"Duplicate"} content={content} trigger="click">
            <EllipsisOutlined className="icon icon_edit mr-10" />
          </Popover>
        </div>)
      }

      auxTableData.push(aux);
    }

    setTableData(auxTableData);
  }

  function handleOpenModalCreateManifestation() {
    setIsOpenModalCreateManifestation(true);
  }

  function handleCloseModalCreateManifestation() {
    setIsOpenModalCreateManifestation(false);
    handleGetManifestations();
  }

  function handleOpenModalEditManifestation(data) {
    setSelectedManifestation(data);
    setIsOpenModalEditManifestation(true);
  }

  function handleCloseModalEditManifestation() {
    setIsOpenModalEditManifestation(false);
    handleGetManifestations();
  }

  function handleSearch(e) {
    let searchString = e.target.value;
    let searchData = manifestations.filter((item) => item.name.includes(searchString));
    handlePrepareTableData(searchData);
  }

  return (
    <div className='dashboard_manifestations'>
      <DrawerAddManifestation open={isOpenModalCreateManifestation} close={handleCloseModalCreateManifestation} lang={lang} />
      <DrawerEditManifestation data={selectedManifestation} open={isOpenModalEditManifestation} close={handleCloseModalEditManifestation} lang={lang} />
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={16}>
              <h1 className="mt-0 mb-0">Manifestation</h1>
            </Col>
            <Col span={8} className="d-flex jc-end ai-center">
              <Input size='large' onChange={handleSearch} className='mr-10' placeholder='Search...' />
              <Button size='large' onClick={handleOpenModalCreateManifestation}>Adicionar</Button>
            </Col>
          </Row>
          <Table dataSource={tableData} columns={columns} />
        </Col>
      </Row>
    </div>
  );
}

export default Manifestations;
