import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import { Helmet } from 'react-helmet';
import './Home.css';
import SearchHome from '../../../components/website/SearchHome/SearchHome';
import AllergiesTypes from '../../../components/website/AllergiesTypes/AllergiesTypes';
import AllergicManifestations from '../../../components/website/AllergicManifestations/AllergicManifestations';
import Seo from '../../../components/website/Seo/Seo';
import imageDiagnostico from '../../../assets/images/thumb-diagnostico.jpg'
import imageAllergy from '../../../assets/images/thumb-tratamiento-alergia.jpg'
import imageSabiasQueOne from '../../../assets/images/thumb-sabias-que-alergia-rendimento-escolar.jpg'
import imageSabiasQueTwo from '../../../assets/images/thumb-sabias-que-insectos-primavera.jpg'
import imageSabiasQueThree from '../../../assets/images/thumb-sabias-que-clima-acaros.jpg'
import imageMap from '../../../assets/icons/img-mapa-polinico-espana.svg'
import imageMapPT from '../../../assets/icons/icon-portugal.svg'
import Search from 'antd/es/input/Search';
import i18next from 'i18next';
import axios from 'axios';
import endpoints from '../../../utils/endpoints';
import config from '../../../utils/config';


function Home({ lang }) {

  const [articles, setArticles] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  const navigate = useNavigate();

  const { allergies } = useOutletContext();

  window.addEventListener('resize', handleWindowSizeChange);

  useEffect(() => {
    handleWindowSizeChange();
    handleGetArticles();
  }, [lang]);

  function handleGetArticles() {
    axios.get(endpoints.articles.getArticles, {
      params: { lang: lang }
    }).then((res) => {
      let aux = [];
      let auxArticles = res.data[0];
      let auxArticlesTags = res.data[1];

      let articlesToShow = (width <= 600) ? 1 : 3;
      for (let i = 0; i < articlesToShow; i++) {
        let auxArticlesTagsName = [];
        let auxTags = JSON.parse(auxArticles[i].tags);
        if (auxTags) {
          for (let y = 0; y < auxTags.length; y++) {
            let tagName = auxArticlesTags.filter((item) => item.id === auxTags[y])[0].name;
            auxArticlesTagsName.push(tagName);
          }
          auxArticles[i].tags = auxArticlesTagsName;
        }

        aux.push(auxArticles[i]);
      }

      setArticles(aux);
    }).catch((err) => {
      console.log(err);
    })
  }

  const [form] = Form.useForm();

  function handleSubscribeNewsletter(e) {
    console.log(e);
  }

  function handleClickMap() {
    if (lang === 'es') {
      navigate(`/es/recursos/mapa-polinico`)
    } else {
      window.open('https://www.rpaerobiologia.com/previsao-polinica/lisboa')
    }
  }

  return (
    <div className="page">
      <Seo
        title="Curatualergia"
        description="Curatualergia new wbsite"
      />
      <SearchHome />
      <AllergiesTypes lang={lang} allergies={allergies} />
      <AllergicManifestations lang={lang} />

      {/* Diagnóstico y tratamiento */}
      <Row gutter={{ xs: 8, sm: 40, md: 40, lg: 60 }} className='d-flex jc-center ai-start container pt-60 pb-40 diagnostic-treatment-mobile'>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <h1 className='f-24 bold m-0 p-mob'>{i18next.t('home.diagnostic_treatment.title')}</h1>
          <p className='f-20 m-0 p-mob'>{i18next.t('home.diagnostic_treatment.subtitle')}</p>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="mb-20-mobile">
          <div
            className='minh-200 bg-center bg-cover d-flex jc-center ai-center hover-cursor'
            style={{ backgroundImage: `url(${imageDiagnostico})` }}
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'diagnostico-y-tratamiento' : 'diagnostico-e-tratamento'}/diagnostico-alergias`)}
          >
            <p className='white bold f-30 text-center'>{i18next.t('home.diagnostic_treatment.squareOne')}</p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div
            className='minh-200 bg-center bg-cover d-flex jc-center ai-center hover-cursor'
            style={{ backgroundImage: `url(${imageAllergy})` }}
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'diagnostico-y-tratamiento' : 'diagnostico-e-tratamento'}/${lang === 'es' ? 'tratamiento-alergias' : 'tratamento-alergias'}`)}
          >
            <p className='white bold f-30 text-center'>{i18next.t('home.diagnostic_treatment.squareTwo')}</p>
          </div>
        </Col>
      </Row>

      {/* ¿Sabías que…? */}
      <Row className='d-flex jc-center ai-start container pt-60 pb-40 articles-home-mobile'>
        <Col span={24}>
          <p className='bold f-24'>{i18next.t('home.articles.title')}</p>
          <Row gutter={[24]}>
            {articles.map((item) => (
              <Col onClick={() => navigate(`/${lang}/recursos/sabias-que/${item.slug}`)} xs={24} sm={24} md={8} lg={8} xl={8} data-aos="fade-right" data-aos-delay="100">
                <div className='bg-center bg-cover d-flex flex-column p-20 hover-cursor' style={{ backgroundColor: 'rgba(68, 138, 223, 0.07)' }}>
                  <p className='bold f-16 green m-0 text-uppercase'>{item.tags[0]}</p>
                  <img className='w-100 mt-10' src={`${config.server_ip}assets/media/${item.img}`} alt={item.img_alt} />
                  <p className='mt-10 mb-10 f-20 bold'>{item.name}</p>
                  <div className='f-16 m-0 description-article-home' dangerouslySetInnerHTML={{ __html: item.content }}></div>
                  <Button type='primary' size='large' className='bg-green w-100 mt-20 border-green'
                    onClick={() => navigate(`/${lang}/recursos/sabias-que/${item.slug}`)}>
                    {i18next.t('home.articles.button')}</Button>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      {/* Newsletter */}
      <Row gutter={[24]} className='full_container bg-light-blue pt-20 pb-20'>
        <Row className='container d-flex jc-sb ai-center'>
          <Col xs={24} sm={24} md={10} lg={10} xl={10} className='d-flex flex-column h-100' data-aos="fade-right" data-aos-delay="100">
            <p className='bold f-24 m-0'>{i18next.t('home.newsletter.title')}</p>
            <p className='white f-16 mt-10 mb-0'>{i18next.t('home.newsletter.subtitle')}</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos="fade-left" data-aos-delay="100">
            {lang === 'es' ?
              <form action="https://newsletter.leti.com/x/plugin/?pName=subscribe&MIDRID=S7Y1NAcAA18&pLang=es&Z=-284961023" method="post">
                <input type="hidden" name="DMDtask" value="subscribe" />
                <div class="row">
                  <label className='white' for="email">Introduce tu email abajo:</label>
                </div>
                <div class="row mt-10 d-flex">
                  <input className='input_newsletter' id="nl-modal-email" type="email" name="email" placeholder="email@ejemplo.com" maxlength="255" required />
                  <input className='button_newsletter' type="submit" value="SUSCRIBIRME" />
                </div>
                <div class="row mt-20">
                  <input type="checkbox" name="lang" value="es" required />
                  <label className='white' for="lang">Accepto la <a className='underline white' href="https://www.leti.com/es/protecciondedatos#gdpr_news" target="_blank" style={{ color: '#7B7B7B !important' }} rel="noopener"><b>política de privacidad</b></a></label>
                </div>
                <div style={{ visibility: 'hidden', fontSize: 0 }}>
                  Please <em>don't</em> insert text in the box below!
                  <input type="text" name="submitCheck" value="" style={{ width: '1px', height: '1px', fontSize: 0 }} />
                </div>
              </form>
              :
              <form action="https://newsletter.leti.com/x/plugin/?pName=subscribe&MIDRID=S7Y1NAcAA18&pLang=pt&Z=-284961023" method="post">
                <input type="hidden" name="DMDtask" value="subscribe" />
                <div class="row">
                  <label className='white' for="email">Introduz o teu e-mail abaixo:</label>
                </div>
                <div class="row mt-10 d-flex">
                  <input className='input_newsletter' id="nl-modal-email" type="email" name="email" placeholder="email@exemplo.com" maxlength="255" required />
                  <input className='button_newsletter' type="submit" value="SUBSCREVER" />
                </div>
                <div class="row mt-20">
                  <input type="checkbox" name="lang" value="pt" required />
                  <label className='white' for="lang">Aceito a <a className='underline white' href="https://www.leti.com/pt/protecciondedatos#gdpr_news" target="_blank" style={{ color: '#7B7B7B !important' }} rel="noopener"><b>política de privacidade</b></a></label>
                </div>
                <div style={{ visibility: 'hidden', fontSize: 0 }}>
                  Please <em>don't</em> insert text in the box below!
                  <input type="text" name="submitCheck" value="" style={{ width: '1px', height: '1px', fontSize: 0 }} />
                </div>
              </form>
            }
          </Col>
        </Row>
      </Row>


      {/* 2 blocks - Recursos / Mapa polínico */}
      <Row gutter={[24]} className='d-flex jc-center ai-start container pt-24 pb-24 resources-map-mobile'>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column h-100 main-col' data-aos="fade-right" data-aos-delay="100">
          <div className='d-flex flex-column bg-green p-20 mb-20-mobile' style={{ minHeight: '270px' }}>
            <p className='bold f-26 white m-0'>{i18next.t('home.resources.title')}</p>
            <p className='white mt-10 mb-0'>{i18next.t('home.resources.subtitle')}</p>
            <Button
              type='primary'
              size='large'
              className='bg-blue mt-20 maxw-150'
              onClick={() => navigate('./recursos')}
            >
              {i18next.t('general.button.more')}
            </Button>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos="fade-left" data-aos-delay="100" className='main-col'>
          <div className='block-mobile d-flex bg-blue jc-sb hover-cursor' style={{ minHeight: '270px' }} onClick={() => handleClickMap}>
            <Col xs={24} sm={24} md={14} lg={14} xl={14} className='flex-column h-100 p-20 p20-mobile'>
              <p className='bold f-26 white m-0'>{i18next.t('home.map.title')}</p>
              <p className='f-18 white mt-10 mb-0'>{i18next.t('home.map.subtitle')}</p>
              <Button
                type='primary'
                size='large'
                className='bg-green mt-20 maxw-150'
                onClick={handleClickMap}
              >
                {i18next.t('general.button.more')}
              </Button>
            </Col>
            <Col onClick={handleClickMap} xs={24} sm={24} md={10} lg={10} xl={10} className=' flex-column jc-center ai-center h-100 p-20'>
              <img className='w-100 maxh-200 map-img' src={lang === 'es' ? imageMap : imageMapPT} />
            </Col>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Home;
