const endpoints = {
  auth: {
    login: 'auth/login',
    register: 'auth/register',
    verifyUser: 'auth/verifyUser',
    verifyToken: 'auth/verifyToken',
    renewToken: 'auth/renewToken',
    resetPasswordEmail: 'auth/resetPasswordEmail',
    resetPassword: 'auth/resetPassword',
  },
  articles: {
    getArticle: 'articles/getArticle',
    getArticles: 'articles/getArticles',
    getArticlesBySlug: 'articles/getArticlesBySlug',
    createArticle: 'articles/createArticle',
    editArticle: 'articles/editArticle',
    deleteArticle: 'articles/deleteArticle',
    duplicateArticle: 'articles/duplicateArticle',
  },
  allergies: {
    getAllergy: 'allergies/getAllergy',
    getAllergies: 'allergies/getAllergies',
    getAllergyDiagnostic: 'allergies/getAllergyDiagnostic',
    getAllergyPrevention: 'allergies/getAllergyPrevention',
    getAllergySymptom: 'allergies/getAllergySymptom',
    getRelatedContentInfo: 'allergies/getRelatedContentInfo',
    createAllergy: 'allergies/createAllergy',
    editAllergy: 'allergies/editAllergy',
    editAllergyRelatedContent: 'allergies/editAllergyRelatedContent',
    editAllergyDetails: 'allergies/editAllergyDetails',
    deleteAllergy: 'allergies/deleteAllergy',
    duplicateAllergy: 'allergies/duplicateAllergy',
    duplicateAllergyOtherLang: 'allergies/duplicateAllergyOtherLang',
    uploadFile: 'allergies/uploadFile',
  },
  manifestations: {
    getManifestations: 'manifestations/getManifestations',
    getManifestation: 'manifestations/getManifestation',
    getManifestationSlug: 'manifestations/getManifestationSlug',
    getRelatedContentInfo: 'manifestations/getRelatedContentInfo',
    createManifestation: 'manifestations/createManifestation',
    editManifestation: 'manifestations/editManifestation',
    editManifestationRelatedContent: 'manifestations/editManifestationRelatedContent',
    editManifestationDetails: 'manifestations/editManifestationDetails',
    duplicateManifestation: 'manifestations/duplicateManifestation',
    duplicateManifestationOtherLang: 'manifestations/duplicateManifestationOtherLang',
    deleteManifestation: 'manifestations/deleteManifestation',
  },
  diagnosticTreatment: {
    getDiagnosticAndTreatment: "diagnostic_treatment/getDiagnosticAndTreatment",
    editDiagnosticAndTreatment: "diagnostic_treatment/editDiagnosticAndTreatment",
    editDiagnosticTreatmentRelatedContent: "diagnostic_treatment/editDiagnosticTreatmentRelatedContent",
    getDiagnostic: "diagnostic_treatment/getDiagnostic",
    getTreatment: "diagnostic_treatment/getTreatment",
    getRelatedContentInfoTreatment: "diagnostic_treatment/getRelatedContentInfoTreatment",
    getRelatedContentInfoDiagnostic: "diagnostic_treatment/getRelatedContentInfoDiagnostic",
  },
  allergicKit: {
    getAllergicKit: "allergic_kit/getAllergicKit",
    getSingleAllergicKit: "allergic_kit/getSingleAllergicKit",
    getRelatedContentInfo: "allergic_kit/getRelatedContentInfo",
    editAllergicKit: "allergic_kit/editAllergicKit",
    editAllergicKitRelatedContent: "allergic_kit/editAllergicKitRelatedContent",
  },
  advices: {
    getAdvices: "advices/getAdvices",
    editAdvice: "advices/editAdvice",
    getAdvice: "advices/getAdvice",
  },
  tags: {
    getTag: 'tags/getTag',
    getTags: 'tags/getTags',
    createTag: 'tags/createTag',
    editTag: 'tags/editTag',
    deleteTag: 'tags/deleteTag',
    duplicateTag: 'tags/duplicateTag'
  },
  glossary: {
    getSingleGlossary: 'glossary/getSingleGlossary',
    getGlossary: 'glossary/getGlossary',
    createGlossary: 'glossary/createGlossary',
    editGlossary: 'glossary/editGlossary',
    deleteGlossary: 'glossary/deleteGlossary',
    duplicateGlossary: 'glossary/duplicateGlossary'
  },
  testimonials: {
    getTestimonial: 'testimonials/getTestimonial',
    getTestimonials: 'testimonials/getTestimonials',
    createTestimonial: 'testimonials/createTestimonial',
    editTestimonial: 'testimonials/editTestimonial',
    deleteTestimonial: 'testimonials/deleteTestimonial',
  },
  podcasts: {
    getPodcast: 'podcasts/getPodcast',
    getPodcastBySlug: 'podcasts/getPodcastBySlug',
    getPodcasts: 'podcasts/getPodcasts',
    getRelatedContentInfo: 'podcasts/getRelatedContentInfo',
    createPodcast: 'podcasts/createPodcast',
    editPodcast: 'podcasts/editPodcast',
    editPodcastDetails: 'podcasts/editPodcastDetails',
    editPodcastDetailsRelatedContent: 'podcasts/editPodcastDetailsRelatedContent',
    deletePodcast: 'podcasts/deletePodcast',
  },
  linksOfInterest: {
    getSingleLink: 'links_of_interest/getSingleLink',
    getLinks: 'links_of_interest/getLinks',
    createLink: 'links_of_interest/createLink',
    editLink: 'links_of_interest/editLink',
    deleteLink: 'links_of_interest/deleteLink',
    duplicateLink: 'links_of_interest/duplicateLink'
  },
  pagesInfo: {
    getPageInfo: 'pages_info/getPageInfo',
    editPageInfo: 'pages_info/editPageInfo',
    getSearch: 'pages_info/getSearch',
  },
  media: {
    getMedia: 'media/getMedia',
    uploadMedia: 'media/uploadMedia',
  },
  dashboard: {
    getInfo: 'dashboard/getInfo'
  },
  images_videos: {
    getImagesVideos: 'images_videos/getImagesVideos',
    getImagesOrVideos: 'images_videos/getImagesOrVideos',
    createImageVideo: 'images_videos/createImageVideo',
    editImageVideo: 'images_videos/editImageVideo',
    deleteImageVideo: 'images_videos/deleteImageVideo',
  },
  newsletter: {
    getNewsletter: 'newsletter/getNewsletter',
    submit: 'newsletter/submit'
  },
  contactForm: {
    getFormContact: 'contact_form/getFormContact',
    submit: 'contact_form/submit'
  },
  users: {
    getUsers: 'users/getUsers',
    getUser: 'users/getUser',
    editUser: 'users/editUser',
    deleteUser: 'users/deleteUser',
  },
}

export default endpoints;