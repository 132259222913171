import { Breadcrumb, Layout, Input, Form, Row, Col, Button, Tabs, Switch, ColorPicker, InputNumber, notification, Spin } from 'antd';
import { useState, useEffect } from 'react';
import {
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Link } from 'react-router-dom';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import pretty from 'pretty';
import Editor from '../../../components/dashboard/Editor/Editor';
import EditorCSS from '../../../components/dashboard/EditorCSS/EditorCSS';
import './AllergicKit.css';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';

const defaultContentObject = {
  full_width_parent: 1,
  full_width: 0,
  background: 0,
  backgroundColor: "#000",
  backgroundImage: null,
  paddingTop: null,
  paddingBottom: null,
  marginTop: null,
  marginBottom: null,
  editor: {
    time: 1687787067474,
    blocks: [
      {
        id: "ci2MsAeULf",
        type: "header",
        data: {
          text: "Some new content",
          level: 1
        }
      },
    ],
    version: "2.27.0"
  }
}

const PageAllergicKit = ({ lang }) => {

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [allergicKit, setAllergicKit] = useState({})

  useEffect(() => {
    handleGetAllergicKit();
  }, [lang]);

  function handleGetAllergicKit() {
    axios.get(endpoints.allergicKit.getAllergicKit, {
      params: { lang }
    }).then((res) => {
      let auxAllergicKit = res.data[0];
      let auxContent = JSON.parse(auxAllergicKit.content);

      for (let i = 0; i < auxContent.length; i++) {
        for (let z = 0; z < auxContent[i].editor.blocks.length; z++) {
          if (auxContent[i].editor.blocks[z].type === "raw") {
            auxContent[i].editor.blocks[z].data.html = pretty(auxContent[i].editor.blocks[z].data.html);
          }
        }
      }

      auxAllergicKit.content = auxContent;

      form.setFieldsValue({
        id: auxAllergicKit.id,
        seo_title: auxAllergicKit.seo_title,
        seo_description: auxAllergicKit.seo_description,
        seo_keywords: auxAllergicKit.seo_keywords,
        content: auxContent
      });

      setAllergicKit(res.data);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleChangeContent(editor, index) {
    let content = form.getFieldValue('content');
    content[index].editor = editor;
    form.setFieldValue('content', content)
  }

  function handleAddSection() {
    let content = form.getFieldValue('content');
    content.push(defaultContentObject);
    form.setFieldValue('content', content);
  }

  function handleChangeColor(e, index) {
    let content = form.getFieldValue('content');
    content[index].backgroundColor = e.toHexString();
    form.setFieldValue('content', content)
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    let auxData = values;

    axios.post(endpoints.allergicKit.editAllergicKit, {
      data: auxData
    }).then((res) => {
      setTimeout(() => {
        setIsButtonLoading(false);
        notification.success({
          description: ' Sucesso',
          message: 'Foi guardado com sucesso!'
        })
      }, 1500)
    }).catch((err) => {
      console.log(err);
      setIsButtonLoading(false);
    })
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item
        hidden
        name="id"
      >
        <Input />
      </Form.Item>
      <Row>
        <Col span={24}>
          <h2>SEO</h2>
          <Form.Item
            name="seo_title"
            label="Title"
            rules={[
              {
                required: true,
                message: 'Este campo é obrigatório'
              }
            ]}
          >
            <Input placeholder='SEO Title' size="large" />
          </Form.Item>
          <Form.Item
            name="seo_description"
            label="Description"
            rules={[
              {
                required: true,
                message: 'Este campo é obrigatório'
              }
            ]}
          >
            <Input.TextArea placeholder='SEO Description' size="large" />
          </Form.Item>
          <Form.Item
            name="seo_keywords"
            label="Keywords"
            rules={[
              {
                required: true,
                message: 'Este campo é obrigatório'
              }
            ]}
          >
            <Input placeholder='SEO Keywords' size="large" />
          </Form.Item>
        </Col>
        <Form.List name="content">
          {(fields, { add, remove }) => (
            <>
              <Row gutter={[24]}>
                <Col span={24}>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Row
                      key={key}
                      style={{
                        display: 'flex',
                        marginBottom: 8,
                      }}
                    >
                      <Col span={20}>
                        <Form.Item
                          {...restField}
                          name={[name, 'editor']}
                        >

                          <Editor className={"editor_content"} index={name} onChange={handleChangeContent} />
                        </Form.Item>
                      </Col>
                      <Col span={4} className='options_column'>
                        <Row className='mb-20 d-flex jc-center'>
                          <h3 className='m-0'>Options</h3>
                        </Row>
                        <Row className='mb-20 d-flex jc-center'>
                          <Button size='large' onClick={() => remove(name)}>
                            Delete Section <DeleteOutlined />
                          </Button>
                        </Row>
                        <Row>
                          <Form.Item
                            hidden
                            {...restField}
                            name={[name, 'full_width_parent']}
                            label="Parent full width"
                            valuePropName='checked'
                          >
                            <Switch size='large' />
                          </Form.Item>
                        </Row>
                        <Row>
                          <Form.Item
                            {...restField}
                            name={[name, 'full_width']}
                            label="Full width"
                            valuePropName='checked'
                          >
                            <Switch />
                          </Form.Item>
                        </Row>
                        <Row>
                          <Form.Item
                            {...restField}
                            name={[name, 'background']}
                            label="Background"
                            valuePropName='checked'
                          >
                            <Switch size='large' />
                          </Form.Item>
                        </Row>
                        <Row>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.content[name] !== currentValues.content[name]}
                          >
                            {({ getFieldValue }) =>
                              getFieldValue('content')[name].background ? (
                                <Form.Item
                                  {...restField}
                                  name={[name, 'backgroundColor']}
                                  label="Background Color"
                                >
                                  <ColorPicker size='large' onChange={(e) => handleChangeColor(e, name)} />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Row>
                        <label>Padding</label>
                        <Row gutter={[24]}>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'paddingTop']}
                              label="Top"
                            >
                              <InputNumber size='large' />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'paddingBottom']}
                              label="Bottom"
                            >
                              <InputNumber size='large' />
                            </Form.Item>
                          </Col>
                        </Row>
                        <label>Margin</label>
                        <Row gutter={[24]}>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'marginTop']}
                              label="Top"
                            >
                              <InputNumber size='large' />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'marginBottom']}
                              label="Bottom"
                            >
                              <InputNumber size='large' />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
              <Row className='d-flex jc-end'>
                <Col span={4} className='d-flex jc-center mt-20'>
                  <Button size="large" onClick={() => handleAddSection()}>
                    <PlusOutlined /> Add Section
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Row>
      <Row className='mt-20'>
        <Col span={24} className='d-flex jc-center ai-center'>
          <Button loading={isButtonLoading} disabled={isButtonLoading} className='submit_button' size="large" onClick={form.submit}>Save</Button>
        </Col>
      </Row>
    </Form>
  )
};
export default PageAllergicKit;