import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin, Image } from 'antd';
import axios from 'axios';
import { ArrowRightOutlined } from '@ant-design/icons';
import ImageMapper from 'react-img-mapper'
import { Collapse } from 'antd';

import Seo from '../../../components/website/Seo/Seo';
import config from '../../../utils/config';

import imageBanner from '../../../assets/images/mapa-polinico-diente-de-leon.jpg'
import imageMapa from '../../../assets/icons/icon-mapa-polinico.svg'

import './Map.css';
import i18next from 'i18next';
import endpoints from '../../../utils/endpoints';


function Map({ lang }) {

  const [allergies, setAllergies] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const URL = `${config.server_ip}assets/media/mapa-polinico-default.svg`;
  const URL_CANARIAS = `${config.server_ip}assets/media/mapa-polinico-canarias.svg`;
  const URL_ZONA_NORTE = `${config.server_ip}assets/media/mapa-polinico-norte.svg`;
  const URL_ZONA_CENTRO = `${config.server_ip}assets/media/mapa-polinico-centro.svg`;
  const URL_AREA_MEDITERRANEA = `${config.server_ip}assets/media/mapa-polinico-mediterranea.svg`;
  const MAP = {
    name: "my-map",
    areas: [
      { name: "norte", shape: "circle", fillColor: 'red', strokeColor: '#000', coords: [250, 49, 15] },
      { name: "centro", shape: "circle", fillColor: 'red', coords: [272, 158, 15] },
      { name: "mediterranea", shape: "circle", fillColor: 'red', coords: [225, 249, 15] },
      { name: "canarias", shape: "circle", fillColor: '#000', coords: [115, 319, 15] },
    ]
  }
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  const navigate = useNavigate();
  window.addEventListener('resize', handleWindowSizeChange);

  useEffect(() => {
    handleWindowSizeChange();
    handleGetAllergies();
  }, []);

  function handleGetAllergies() {
    axios.get(endpoints.allergies.getAllergies).then((res) => {
      setAllergies(res.data);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e)
    })
  }

  function handleClickArea(area) {
    hideAllCollapses();
    switch (area) {
      case 'norte':
        document.querySelector('.circle-norte').classList.add('selected');
        document.querySelector('.circle-centro').classList.remove('selected');
        document.querySelector('.circle-mediterranea').classList.remove('selected');
        document.querySelector('.circle-canarias').classList.remove('selected');
        break;
      case 'centro':
        document.querySelector('.circle-norte').classList.remove('selected');
        document.querySelector('.circle-centro').classList.add('selected');
        document.querySelector('.circle-mediterranea').classList.remove('selected');
        document.querySelector('.circle-canarias').classList.remove('selected');
        break;
      case 'mediterranea':
        document.querySelector('.circle-norte').classList.remove('selected');
        document.querySelector('.circle-centro').classList.remove('selected');
        document.querySelector('.circle-mediterranea').classList.add('selected');
        document.querySelector('.circle-canarias').classList.remove('selected');
        break;
      case 'canarias':
        document.querySelector('.circle-norte').classList.remove('selected');
        document.querySelector('.circle-centro').classList.remove('selected');
        document.querySelector('.circle-mediterranea').classList.remove('selected');
        document.querySelector('.circle-canarias').classList.add('selected');
        break;
      default:
        break;
    }

    document.querySelector('.circle-' + area).classList.add('selected');
    document.querySelector('.' + area).classList.remove('hidden');
    document.querySelector('.img-mapper-img').src = `${config.server_ip}assets/media/mapa-polinico-` + area + `.svg`;
  }

  function hideAllCollapses() {
    document.querySelectorAll('.region').forEach(function (el) {
      el.classList.add('hidden');
    });
  }

  function clickedOutside(evt) {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    hideAllCollapses();
  }

  const northItems = [
    {
      key: '1',
      label: <><i>Alnus</i> (Aliso)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/alnus.png`} />
        <p><b>Betulaceae (<i>Betula, Corylus</i>),</b> <i>Fagus, Castanea, Quercus, Populus y Salix</i>.</p>
      </>,
    },
    {
      key: '2',
      label: <><i>Artemisa</i> (Artemisa)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/artemisa.png`} />
        <p><i>Betula, Olea, </i>Graminae, <b>Ambrosía</b></p>
      </>,
    },
    {
      key: '3',
      label: <><i>Betula</i> (Abedul)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/betula.png`} />
        <p><b>Betulaceae (<i>Alnus, Corylus</i>),</b> <i>Fagus, Castanea, Quercus, Populus y Salix, Fraxinus, Olea, </i>Gramineae, <i>Artemisia</i>.</p>
      </>,
    },
    {
      key: '4',
      label: <><i>Castanea</i> (Castaño)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/castanea.png`} />
      </>,
    },
    {
      key: '5',
      label: <>Chenopodiaceae (<i>Salsola</i>, Barrilla); Amarantaceae (Queno-amarantáceas)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/CHENOPODIACEAE.png`} />
        <p><i>Chenopodium, Salsola, Amaranthus.</i></p>
      </>,
    },
    {
      key: '6',
      label: 'Cupresaceae (Cupresácea)',
      children: <>
        <Image src={`${config.server_ip}assets/media/CUPRESSACEAE.png`} />
        <p>Cupressaceae, Gramineae.</p>
      </>,
    },
    {
      key: '7',
      label: <><i>Corylus</i> (Avellano)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/corylus.png`} />
        <p><b>Betulaceae (<i>Betula, Corylus</i>),</b> <i>Fagus, Castanea, Quercus, Populus y Salix</i>.</p>
      </>,
    },
    {
      key: '8',
      label: <>Euphorbiaceae - <i>Mercurialis</i> (Mercurial)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/EUPHORBIACEAE.png`} />
      </>,
    },
    {
      key: '9',
      label: 'Gramineae (Gramíneas)',
      children: <>
        <Image src={`${config.server_ip}assets/media/GRAMINEAE.png`} />
        <p><b>Gramineae (<i>Phleum, Dactylis, Lolium, Festuca, Poa, Trisetum, Anthoraxanthum, Holcus, Agrostis y Alopecurus</i>)</b>, <i>Betula, Olea, Artemisia, Plantago y Rumex</i>.</p>
      </>,
    },
    {
      key: '10',
      label: <><i>Morus</i> (Morera)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/MORUS.png`} />
      </>,
    },
    {
      key: '11',
      label: <>Oleaceae - <i>Olea</i> (Olivo)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/OLEACEAE-OLEA.png`} />
        <p><b>Oleaceae (<i>Fraxinus, Ligustrum</i>)</b>, <i>Betula,</i> Gramineae<i>, Artemisia, Ambrosia, Helianthus</i>.</p>
      </>,
    },
    {
      key: '12',
      label: 'Palmaceae - Phoenix (Palmácea - Palmera)',
      children: <>
        <Image src={`${config.server_ip}assets/media/PALMACEAE-PHOENIX.png`} />
      </>,
    },
    {
      key: '13',
      label: <><i>Pinus</i> (Pino)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/PINUS.png`} />
      </>,
    },
    {
      key: '14',
      label: <><i>Plantago</i> (Llantén)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/PLANTAGO.png`} />
      </>,
    },
    {
      key: '15',
      label: <><i>Platanus</i> (Plátano)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/PLATANUS.png`} />
        <p>Gramineae.</p>
      </>,
    },
    {
      key: '16',
      label: <><i>Pupulus</i> (Álamo, Chopo)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/POPULUS.png`} />
        <p>Salix.</p>
      </>,
    },
    {
      key: '17',
      label: <><i>Quercus</i> (Encina, Roble)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/QUERCUS.png`} />
        <p><b>Fagaceae (<i>Fagus, Castanea</i>), Betulaceae (<i>Betula, Alnus y Corylus</i>).</b></p>
      </>,
    },
    {
      key: '18',
      label: <><i>Rumex</i> (Acedera)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/RUMEX.png`} />
      </>,
    },
    {
      key: '19',
      label: <><i>Ulmus</i> (Olmo)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/ULMUS.png`} />
      </>,
    },
    {
      key: '20',
      label: <>Urticaceae - <i>Parietaria</i> (Urticácea - Parietaria)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/URTICACEAE-PARIETARIA.png`} />
        <p><i>Parietaria</i> sp.</p>
      </>,
    },
  ];

  const centerItems = [
    {
      key: '1',
      label: <><i>Alnus</i> (Aliso)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/alnus-centro.png`} />
        <p><b>Betulaceae (<i>Betula, Corylus</i>),</b> <i>Fagus, Castanea, Quercus, Populus y Salix</i>.</p>
      </>,
    },
    {
      key: '2',
      label: <><i>Artemisa</i> (Artemisa)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/artemisa-centro.png`} />
        <p><i>Betula, Olea, </i>Graminae, <b>Ambrosía</b></p>
      </>,
    },
    {
      key: '3',
      label: <><i>Betula</i> (Abedul)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/betula-centro.png`} />
        <p><b>Betulaceae (<i>Alnus, Corylus</i>),</b> <i>Fagus, Castanea, Quercus, Populus y Salix, Fraxinus, Olea, </i>Gramineae, <i>Artemisia</i>.</p>
      </>,
    },
    {
      key: '4',
      label: <><i>Castanea</i> (Castaño)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/castanea-centro.png`} />
      </>,
    },
    {
      key: '5',
      label: <>Chenopodiaceae (<i>Salsola</i>, Barrilla); Amarantaceae (Queno-amarantáceas)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/CHENOPODIACEAE-centro.png`} />
        <p><i>Chenopodium, Salsola, Amaranthus.</i></p>
      </>,
    },
    {
      key: '6',
      label: 'Cupresaceae (Cupresácea)',
      children: <>
        <Image src={`${config.server_ip}assets/media/CUPRESSACEAE-centro.png`} />
        <p>Cupressaceae, Gramineae.</p>
      </>,
    },
    {
      key: '7',
      label: <><i>Corylus</i> (Avellano)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/corylus-centro.png`} />
        <p><b>Betulaceae (<i>Betula, Corylus</i>),</b> <i>Fagus, Castanea, Quercus, Populus y Salix</i>.</p>
      </>,
    },
    {
      key: '8',
      label: <>Euphorbiaceae - <i>Mercurialis</i> (Mercurial)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/EUPHORBIACEAE-centro.png`} />
      </>,
    },
    {
      key: '9',
      label: 'Gramineae (Gramíneas)',
      children: <>
        <Image src={`${config.server_ip}assets/media/GRAMINEAE-centro.png`} />
        <p><b>Gramineae (<i>Phleum, Dactylis, Lolium, Festuca, Poa, Trisetum, Anthoraxanthum, Holcus, Agrostis y Alopecurus</i>)</b>, <i>Betula, Olea, Artemisia, Plantago y Rumex</i>.</p>
      </>,
    },
    {
      key: '10',
      label: <><i>Morus</i> (Morera)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/MORUS-centro.png`} />
      </>,
    },
    {
      key: '11',
      label: <>Oleaceae - <i>Olea</i> (Olivo)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/OLEACEAE-centro.png`} />
        <p><b>Oleaceae (<i>Fraxinus, Ligustrum</i>)</b>, <i>Betula,</i> Gramineae<i>, Artemisia, Ambrosia, Helianthus</i>.</p>
      </>,
    },
    {
      key: '12',
      label: 'Palmaceae - Phoenix (Palmácea - Palmera)',
      children: <>
        <Image src={`${config.server_ip}assets/media/PALMACEAE-centro.png`} />
      </>,
    },
    {
      key: '13',
      label: <><i>Pinus</i> (Pino)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/PINUS-centro.png`} />
      </>,
    },
    {
      key: '14',
      label: <><i>Plantago</i> (Llantén)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/PLANTAGO-centro.png`} />
      </>,
    },
    {
      key: '15',
      label: <><i>Platanus</i> (Plátano)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/PLATANUS-centro.png`} />
        <p>Gramineae.</p>
      </>,
    },
    {
      key: '16',
      label: <><i>Pupulus</i> (Álamo, Chopo)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/POPULUS-centro.png`} />
        <p>Salix.</p>
      </>,
    },
    {
      key: '17',
      label: <><i>Quercus</i> (Encina, Roble)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/QUERCUS-centro.png`} />
        <p><b>Fagaceae (<i>Fagus, Castanea</i>), Betulaceae (<i>Betula, Alnus y Corylus</i>).</b></p>
      </>,
    },
    {
      key: '18',
      label: <><i>Rumex</i> (Acedera)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/RUMEX-centro.png`} />
      </>,
    },
    {
      key: '19',
      label: <><i>Ulmus</i> (Olmo)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/ULMUS-centro.png`} />
      </>,
    },
    {
      key: '20',
      label: <>Urticaceae - <i>Parietaria</i> (Urticácea - Parietaria)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/URTICACEAE-PARIETARIA-centro.png`} />
        <p><i>Parietaria</i> sp.</p>
      </>,
    },
  ];

  const canariasItems = [
    {
      key: '1',
      label: <>Apiaceae - <i>Umbelifera</i> (Apiácea - Umbrelifera)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/APIACEAE-canarias.png`} />
      </>,
    },
    {
      key: '2',
      label: 'Artemisa (Artemisa)',
      children: <>
        <Image src={`${config.server_ip}assets/media/ARTEMISA-canarias.png`} />
        <p><i>Betula, Olea</i>, Graminae, <b>Ambrosía</b>.</p>
      </>,
    },
    {
      key: '3',
      label: <><i>Castanea</i> (Castaño)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/CASTANEA-canarias.png`} />
      </>,
    },
    {
      key: '4',
      label: <>Chenopodiaceae (<i>Salsola</i>, Barrilla); Amarantaceae (Queno-amarantáceas)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/CHENOPODIACEAE-canarias.png`} />
        <p><i>Chenopodium, Salsola, Amaranthus</i>.</p>
      </>,
    },
    {
      key: '5',
      label: 'Cupresaceae (Cupresácea)',
      children: <>
        <Image src={`${config.server_ip}assets/media/CUPRESSACEAE-canarias.png`} />
        <p><b>Cupressaceae</b>, Gramineae.</p>
      </>,
    },
    {
      key: '6',
      label: <>Euphorbiaceae - <i>Mercurialis</i> (Mercurial)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/EUPHORBIACEAE-canarias.png`} />
      </>,
    },
    {
      key: '7',
      label: <>Euphorbiaceae - <i>Ricinus</i> (Ricino)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/EUPHORBIACEAE-canarias2.png`} />
      </>,
    },
    {
      key: '8',
      label: 'Gramineae (Gramíneas)',
      children: <>
        <Image src={`${config.server_ip}assets/media/GRAMINEAE-canarias.png`} />
        <p><b>Gramineae (<i>Phleum, Dactylis, Lolium, Festuca, Poa, Trisetum, Anthoraxanthum, Holcus, Agrostis y Alopecurus</i>)</b><i>, Betula, Olea, Artemisia, Plantago y Rumex.</i></p>
      </>,
    },
    {
      key: '9',
      label: <><i>Morus</i> (Morera)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/MORUS-canarias.png`} />
      </>,
    },
    {
      key: '10',
      label: <>Oleaceae - <i>Olea</i> (Olivo)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/OLEACEAE-canarias.png`} />
        <p><b>Oleaceae (<i>Fraxinus, Ligustrum</i>)</b><i>, Betula, </i>Gramineae <i>, Artemisia, Ambrosia, Helianthus.</i></p>
      </>,
    },
    {
      key: '11',
      label: 'Palmaceae - Phoenix (Palmácea - Palmera)',
      children: <>
        <Image src={`${config.server_ip}assets/media/PALMACEAE-canarias.png`} />
      </>,
    },
    {
      key: '12',
      label: <><i>Pinus</i> (Pino)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/PINUS-canarias.png`} />
      </>,
    },
    {
      key: '13',
      label: <><i>Plantago</i> (Llantén)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/PLANTAGO-canarias.png`} />
      </>,
    },
    {
      key: '14',
      label: <><i>Quercus</i> (Encina, Roble)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/QUERCUS-canarias.png`} />
        <p><b>Fagaceae (<i>Fagus, Castanea</i>), Betulaceae (<i>Betula, Alnus y Corylus</i>).</b></p>
      </>,
    },
    {
      key: '15',
      label: <><i>Rumex</i> (Acedera)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/RUMEX-canarias.png`} />
      </>,
    },
    {
      key: '16',
      label: <><i>Schinus</i> (Pimentero)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/GSCHINUS-canarias.png`} />
      </>,
    },
    {
      key: '17',
      label: <>Urticaceae - <i>Parietaria</i> (Urticácea - Parietaria)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/URTICACEAE-canarias.png`} />
        <p><i>Parietaria</i> sp.</p>
      </>,
    },
  ];

  const mediterraneanItems = [
    {
      key: '1',
      label: <><i>Alnus</i> (Aliso)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/alnus-mediterraneo.png`} />
        <p><b>Betulaceae (<i>Betula, Corylus</i>),</b> <i>Fagus, Castanea, Quercus, Populus y Salix</i>.</p>
      </>,
    },
    {
      key: '2',
      label: <><i>Artemisa</i> (Artemisa)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/artemisa-mediterraneo.png`} />
        <p><i>Betula, Olea, </i>Graminae, <b>Ambrosía</b></p>
      </>,
    },
    {
      key: '3',
      label: <><i>Betula</i> (Abedul)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/betula-mediterraneo.png`} />
        <p><b>Betulaceae (<i>Alnus, Corylus</i>),</b> <i>Fagus, Castanea, Quercus, Populus y Salix, Fraxinus, Olea, </i>Gramineae, <i>Artemisia</i>.</p>
      </>,
    },
    {
      key: '4',
      label: <><i>Castanea</i> (Castaño)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/castanea-mediterraneo.png`} />
      </>,
    },
    {
      key: '5',
      label: <>Chenopodiaceae (<i>Salsola</i>, Barrilla); Amarantaceae (Queno-amarantáceas)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/CHENOPODIACEAE-mediterraneo.png`} />
        <p><i>Chenopodium, Salsola, Amaranthus.</i></p>
      </>,
    },
    {
      key: '6',
      label: 'Cupresaceae (Cupresácea)',
      children: <>
        <Image src={`${config.server_ip}assets/media/CUPRESSACEAE-mediterraneo.png`} />
        <p>Cupressaceae, Gramineae.</p>
      </>,
    },
    {
      key: '7',
      label: <><i>Corylus</i> (Avellano)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/corylus-mediterraneo.png`} />
        <p><b>Betulaceae (<i>Betula, Corylus</i>),</b> <i>Fagus, Castanea, Quercus, Populus y Salix</i>.</p>
      </>,
    },
    {
      key: '8',
      label: <>Euphorbiaceae - <i>Mercurialis</i> (Mercurial)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/EUPHORBIACEAE-mediterraneo.png`} />
      </>,
    },
    {
      key: '9',
      label: 'Gramineae (Gramíneas)',
      children: <>
        <Image src={`${config.server_ip}assets/media/GRAMINEAE-mediterraneo.png`} />
        <p><b>Gramineae (<i>Phleum, Dactylis, Lolium, Festuca, Poa, Trisetum, Anthoraxanthum, Holcus, Agrostis y Alopecurus</i>)</b>, <i>Betula, Olea, Artemisia, Plantago y Rumex</i>.</p>
      </>,
    },
    {
      key: '10',
      label: <><i>Morus</i> (Morera)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/MORUS-mediterraneo.png`} />
      </>,
    },
    {
      key: '11',
      label: <>Oleaceae - <i>Olea</i> (Olivo)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/OLEACEAE-mediterraneo.png`} />
        <p><b>Oleaceae (<i>Fraxinus, Ligustrum</i>)</b>, <i>Betula,</i> Gramineae<i>, Artemisia, Ambrosia, Helianthus</i>.</p>
      </>,
    },
    {
      key: '12',
      label: 'Palmaceae - Phoenix (Palmácea - Palmera)',
      children: <>
        <Image src={`${config.server_ip}assets/media/PALMACEAE-mediterraneo.png`} />
      </>,
    },
    {
      key: '13',
      label: <><i>Pinus</i> (Pino)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/PINUS-mediterraneo.png`} />
      </>,
    },
    {
      key: '14',
      label: <><i>Plantago</i> (Llantén)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/PLANTAGO-mediterraneo.png`} />
      </>,
    },
    {
      key: '15',
      label: <><i>Platanus</i> (Plátano)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/PLATANUS-mediterraneo.png`} />
        <p>Gramineae.</p>
      </>,
    },
    {
      key: '16',
      label: <><i>Pupulus</i> (Álamo, Chopo)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/POPULUS-mediterraneo.png`} />
        <p>Salix.</p>
      </>,
    },
    {
      key: '17',
      label: <><i>Quercus</i> (Encina, Roble)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/QUERCUS-mediterraneo.png`} />
        <p><b>Fagaceae (<i>Fagus, Castanea</i>), Betulaceae (<i>Betula, Alnus y Corylus</i>).</b></p>
      </>,
    },
    {
      key: '18',
      label: <><i>Rumex</i> (Acedera)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/RUMEX-mediterraneo.png`} />
      </>,
    },
    {
      key: '19',
      label: <><i>Ulmus</i> (Olmo)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/ULMUS-mediterraneo.png`} />
      </>,
    },
    {
      key: '20',
      label: <>Urticaceae - <i>Parietaria</i> (Urticácea - Parietaria)</>,
      children: <>
        <Image src={`${config.server_ip}assets/media/URTICACEAE-PARIETARIA-mediterraneo.png`} />
        <p><i>Parietaria</i> sp.</p>
      </>,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <div className="allergies page">
        <Seo title={'Allergies'} description={'Allergies'} />
        <Row className='full_container allergies_banner'>
          <div style={{ backgroundImage: `url(${imageBanner})` }}></div>
        </Row>
        <Row gutter={[24]} className='container d-flex block-mobile'>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <h1 className='allergies_title mb-10'>{i18next.t('map.title')}</h1>
            <hr className='title_divider' />
            <div className='mt-30' dangerouslySetInnerHTML={{ __html: i18next.t('map.text') }}></div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10} className='d-flex jc-center ai-center'>
            <div className='polinic_map'>
              <img src={`${config.server_ip}assets/media/mapa-polinico-default.svg`} className='w-100 img-mapper-img' />
              <div className='circle-norte' onClick={() => handleClickArea('norte')}>+</div>
              <div className='circle-centro' onClick={() => handleClickArea('centro')}>+</div>
              <div className='circle-mediterranea' onClick={() => handleClickArea('mediterranea')}>+</div>
              <div className='circle-canarias' onClick={() => handleClickArea('canarias')}>+</div>
            </div>
          </Col>
        </Row>
        <Row gutter={[24]} className='container collapse-map region norte hidden mb-10'>
          <Col xs={24} sm={18}>
            <h3>{i18next.t('home.map.north')}</h3>
            <p className="mb-30">{i18next.t('home.map.north_description')}</p>
            <a className="download-advices-btn" target="_blank" href={`${config.server_ip}assets/media/ZONA-NORTE.pdf`} download>{i18next.t('general.download_pdf')}</a>
            <Collapse className="collapse-map mt-50" items={northItems} expandIconPosition={'end'} />
          </Col>
        </Row>
        <Row gutter={[24]} className='container collapse-map region centro hidden mb-10'>
          <Col xs={24} sm={18}>
            <h3>{i18next.t('home.map.center')}</h3>
            <p className="mb-30">{i18next.t('home.map.center_description')}</p>
            <a className="download-advices-btn" target="_blank" href={`${config.server_ip}assets/media/ZONA-CENTRO.pdf`} download>{i18next.t('general.download_pdf')}</a>
            <Collapse className="collapse-map mt-50" items={centerItems} expandIconPosition={'end'} />
          </Col>
        </Row>
        <Row gutter={[24]} className='container collapse-map region canarias hidden mb-10'>
          <Col xs={24} sm={18}>
            <h3>{i18next.t('home.map.canarias')}</h3>
            <p className="mb-30">{i18next.t('home.map.canarias_description')}</p>
            <a className="download-advices-btn" target="_blank" href={`${config.server_ip}assets/media/AREA-MEDITERRANEA.pdf`} download>{i18next.t('general.download_pdf')}</a>
            <Collapse className="collapse-map mt-50" items={canariasItems} expandIconPosition={'end'} />
          </Col>
        </Row>
        <Row gutter={[24]} className='container collapse-map region mediterranea hidden mb-10'>
          <Col xs={24} sm={18}>
            <h3>{i18next.t('home.map.mediterranean')}</h3>
            <p className="mb-30">{i18next.t('home.map.mediterranean_description')}</p>
            <a className="download-advices-btn" target="_blank" href={`${config.server_ip}assets/media/CANARIAS.pdf`} download>{i18next.t('general.download_pdf')}</a>
            <Collapse className="collapse-map mt-50" items={mediterraneanItems} expandIconPosition={'end'} />
          </Col>
        </Row>
      </div>

    </Spin>

  );
}

export default Map;
