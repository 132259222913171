import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin, Typography, Collapse, Select, Form, Input, Checkbox } from 'antd';
import { ArrowRightOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import i18next from 'i18next';
import config from '../../../utils/config';
import Seo from '../../../components/website/Seo/Seo';
import endpoints from '../../../utils/endpoints';
import bannerImage from '../../../assets/images/manifestaciones-alergicas-hombre-estornudando.jpg'

import './Glossary.css';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;


function Glossary({ lang }) {

  const [alphabetDefault, setAlphabetDefault] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'])
  const [alphabet, setAlphabet] = useState([])
  const [glossary, setGlossary] = useState([])

  useEffect(() => {
    handleGetGlossary();
  }, []);

  function scrollToWord(e) {
    let word = e.target.getAttribute('data-word');
    if(word === null) return;
    var scrollWord = document.getElementById("word-" + word)?.offsetTop;
    if(scrollWord === 'undefined') {
      return;
    }
    window.scrollTo({ top: scrollWord, behavior: 'smooth'});
  }

  function scrollToLetter(e) {
    let letter = e.target.getAttribute('data-letter');
    if(letter === null) return;
    var scrollLetter = document.getElementById("letter-" + letter.toLowerCase())?.offsetTop;
    window.scrollTo({ top: scrollLetter, behavior: 'smooth'});
  }


  function handleGetGlossary() {
    axios.get(endpoints.glossary.getGlossary, {
      params: { lang }
    }).then((res) => {

      let auxAlphabet = JSON.parse(JSON.stringify(alphabetDefault))
      let auxGlossary = res.data;
      let newAlphabet = [];
      let newGlossary = [];

      for (let i = 0; i < auxAlphabet.length; i++) {
        let filterLetters = auxGlossary.filter((item) => item.letter === auxAlphabet[i]);
        if (filterLetters.length > 0) {
          filterLetters.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          newAlphabet.push({ letter: auxAlphabet[i], disabled: false })
          newGlossary.push({ letter: auxAlphabet[i], items: filterLetters });
        } else {
          newAlphabet.push({ letter: auxAlphabet[i], disabled: true })
        }
      }

      setAlphabet(newAlphabet);
      setGlossary(newGlossary);
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <div className="allergies_details page">
      <Seo title={i18next.t('glossary.title')} description={i18next.t('glossary.title')} />
      <Row className='container d-flex flex-column'>
        <h1 className='allergies_title mb-10'>{i18next.t('glossary.title')}</h1>
        <hr className='title_divider' />
      </Row>
      <Row className='container'>
        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
          <div className='alphabet_links d-flex'>
            {alphabet.map((item) => (
              <div className={`letter_of_alphabet ${item.disabled ? 'grey' : ''} `} data-letter={item.letter} onClick={(e) => scrollToLetter(e)}>{item.letter}</div>
            ))}
          </div>
          <div className='alphabet_items d-flex flex-column'>
            {glossary.map((item) => (
              <div id={`letter-${item.letter.toLowerCase()}`} className={`items_glossary ${item.disabled ? 'grey' : ''}`}>
                <p className='f-30 bold'>{item.letter}</p>
                {item.items.map((letter) => (
                  <p className='item_glossary' id={`word-${letter.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^A-Z0-9]/ig, "-")}`}><b>{letter.name}</b>: <span onClick={(e) => scrollToWord(e)} dangerouslySetInnerHTML={{ __html: letter.description }}></span></p>
                ))}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Glossary;
