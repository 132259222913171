import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Input } from 'antd';
import './SearchHome.css';
import i18next from 'i18next';

import iconLibro from '../../../assets/icons/icon-libro-alergia.svg'

const { Search } = Input

function SearchHome() {

  return (
    <div className="container search_home">
      <Row gutter={[24]}>
        <Col span={24} className='d-flex flex-column jc-center ai-center mt-30'>
          <img src={iconLibro} alt="lib" className='maxw-60 mb-10' />
          <p className='f-24 mt-0'>{i18next.t('home.title')}</p>
        </Col>
      </Row>
    </div>
  );
}

export default SearchHome;
