import { Modal, Form, Input, Upload, Row, Col, Drawer, Button } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios'
import './ModalEditUser.css';
import { useEffect, useState } from 'react';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';
import dayjs from 'dayjs'

const { useForm } = Form;

export default function ModalEditUser({ data, open, close, lang }) {

  const [form] = useForm();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      delete data.password;
      data.birth_date = dayjs(data.birth_date);
      form.setFieldsValue(data);
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios.post(endpoints.users.editUser, {
      data: values
    }).then((res) => {
      setTimeout(() => {
        setIsButtonLoading(false);
        close();
        form.setFieldsValue({});
      }, 1500);
    }).catch((error) => {
      console.log(error);
    });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  function handleGeneratePassword() {
    let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = 8;
    let password = "";
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }

    console.log(password)
    form.setFieldValue('password', password)
  }

  return (
    <Modal
      className='edit_user'
      open={open}
      onOk={form.submit}
      onCancel={handleClose}
      title="Create Tag"
      okText={"Guardar"}
      cancelText={"Cancelar"}
      okButtonProps={{ loading: isButtonLoading }}
      cancelButtonProps={{ disabled: isButtonLoading }}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          hidden
          name="id"
        >
          <Input />
        </Form.Item>
        <Form.Item
          hidden
          name="type"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input size='large' placeholder='Nome...'></Input>
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input size='large' placeholder='E-mail...' />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
        >
          <Input.Password addonAfter={<Button className="minh-38 maxh-38" type="primary" onClick={handleGeneratePassword}>Generate Password</Button>} placeholder="Password" size="large" />
        </Form.Item>
      </Form>
    </Modal>
  )
};