import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Spin, Tabs } from 'antd';
import axios from 'axios';
import './AllergiesDetails.css';
import AllergiesDetailsSymptoms from '../../../components/dashboard/AllergiesDetailsSymptoms/AllergiesDetailsSymptoms';
import AllergiesDetailsPrevention from '../../../components/dashboard/AllergiesDetailsPrevention/AllergiesDetailsPrevention';
import AllergiesDetailsDiagnostic from '../../../components/dashboard/AllergiesDetailsDiagnostic/AllergiesDetailsDiagnostic';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';


function AllergiesDetails({ lang }) {

  const [isLoading, setIsLoading] = useState(true);
  const [allergy, setAllergy] = useState({});
  const [allergySymptoms, setAllergySymptoms] = useState({});
  const [allergyPrevention, setAllergyPrevention] = useState({});
  const [allergyDiagnostic, setAllergyDiagnostic] = useState({});

  let { id } = useParams();

  useEffect(() => {
    handleGetAllergy();
  }, [lang]);

  function handleGetAllergy() {
    axios.get(endpoints.allergies.getAllergy, {
      params: { id, lang }
    }).then((res) => {
      setAllergy(res.data[0][0]);
      setAllergySymptoms(res.data[1][0]);
      setAllergyPrevention(res.data[2][0]);
      setAllergyDiagnostic(res.data[3][0]);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <div className='dashboard_allergies'>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={24} className='dashboard_card'>
            <Row className='title_row mb-20'>
              <Col span={12}>
                <h1 className="mt-0 mb-0">{allergy.name}</h1>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Tabs
                  type="card"
                  items={[
                    {
                      key: 1,
                      label: `Síntomas y Causas`,
                      children: <AllergiesDetailsSymptoms data={allergySymptoms} />,
                    },
                    {
                      key: 2,
                      label: `Prevención`,
                      children: <AllergiesDetailsPrevention data={allergyPrevention} />,
                    },
                    {
                      key: 3,
                      label: `Diagnostico y Tratamiento`,
                      children: <AllergiesDetailsDiagnostic data={allergyDiagnostic} />,
                    }
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default AllergiesDetails;
