import { Modal, Form, Input, Upload, Row, Col, Tabs, Drawer, Button, Select, Switch } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import axios from 'axios'
import './DrawerEditManifestation.css';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';

const { useForm } = Form;
const { Dragger } = Upload;


export default function RelatedContent({ data, uploadProps, filePreview, lang }) {

  const [articles, setArticles] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [tags, setTags] = useState([]);
  const [manifestations, setManifestations] = useState([]);

  useEffect(() => {
    axios.get(endpoints.manifestations.getRelatedContentInfo, {
      params: { lang }
    }).then((res) => {
      setAllergies(res.data[0]);
      setArticles(res.data[1]);
      setTags(res.data[2]);
      setManifestations(res.data[3]);
    }).catch((err) => {
      console.log(err);
    })
  }, [filePreview])

  return (
    <>
      <Form.Item
        hidden
        name={'id'}
        label="Id"
      >
        <Input />
      </Form.Item>
      <Form.List
        name="related_content"
      >
        {(fields, { add, remove }) => {
          return (<>
            <Row gutter={[24]}>
              {fields.map(({ key, name, ...restField }, index) => {
                return (<Col span={8}>
                  <Form.Item
                    {...restField}
                    name={[name, 'is_active']}
                    label="Active"
                    valuePropName='checked'
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'type']}
                    label="Type"
                    rules={[
                      {
                        required: true,
                        message: 'Este campo é obrigatório'
                      }
                    ]}
                  >
                    <Select
                      options={[
                        { value: 'dynamic', label: 'Dynamic Content' },
                        { value: 'static', label: 'Static Content' },
                      ]}
                      size='large'
                    />
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      (prevValues.related_content[key].type !== currentValues.related_content[key].type)
                    }
                  >
                    {({ getFieldValue }) => {
                      let value = getFieldValue(`related_content`);
                      return (value[name].type === 'dynamic') ?
                        <Form.Item
                          {...restField}
                          name={[name, 'content_type']}
                          label="Content Type"
                          rules={[
                            {
                              required: true,
                              message: 'Este campo é obrigatório'
                            }
                          ]}
                        >
                          <Select
                            placeholder="Choose one option..."
                            options={[
                              { value: 'allergies', label: 'Allergies' },
                              { value: 'articles', label: 'Sabías que?' },
                              { value: 'manifestations', label: 'Manifestationes' },
                              { value: 'other', label: 'Other' },
                            ]}
                            size='large'
                          />
                        </Form.Item>
                        :
                        <Form.Item
                          {...restField}
                          name={[name, 'content_type']}
                          label="Content Type"
                          rules={[
                            {
                              required: true,
                              message: 'Este campo é obrigatório'
                            }
                          ]}
                        >
                          <Select
                            placeholder="Choose one option..."
                            options={[
                              { value: 'allergies', label: 'Allergies' },
                              { value: 'articles', label: 'Sabías que?' },
                              { value: 'manifestations', label: 'Manifestationes' },
                              { value: 'other', label: 'Other' },
                            ]}
                            size='large'
                          />
                        </Form.Item>

                    }}
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      (prevValues.related_content[key].type !== currentValues.related_content[key].type) ||
                      (prevValues.related_content[key].content_type !== currentValues.related_content[key].content_type)
                    }
                  >
                    {({ getFieldValue }) => {
                      let value = getFieldValue(`related_content`);
                      return (value[name].type === 'static' && value[name].content_type === 'other') ?
                        <>
                          <Form.Item
                            {...restField}
                            name={[name, 'title']}
                            label="Title"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'link']}
                            label="URL"
                            rules={[
                              {
                                required: true,
                                message: 'Este campo é obrigatório'
                              }
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            name={[name, 'img']}
                            valuePropName={name === 0 ? 'fileListRelatedContentOne' : (name === 1 ? 'fileListRelatedContentTwo' : 'fileListRelatedContentThree')}
                            label="Image"
                            rules={[
                              {
                                required: true,
                                message: 'Este campo é obrigatório'
                              }
                            ]}
                          >
                            <Dragger {...uploadProps[name]} className={`dragger ${filePreview[name] ? 'not_show' : ''}`}>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">Click or drag file to this area to upload</p>
                              <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                banned files.
                              </p>
                              <div
                                className={`preview_file ${data.related_content[name].img ? '' : (filePreview[name] ? '' : 'not_show')}`}
                                style={{
                                  backgroundImage: `url(${(filePreview[name] && filePreview[name].includes('base64')) ?
                                    filePreview[name] :
                                    `${config.server_ip}assets/media/${filePreview[name]}`})`
                                }}
                              >
                              </div>
                            </Dragger>
                          </Form.Item>
                        </>
                        :
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            (prevValues.related_content[key].type !== currentValues.related_content[key].type) ||
                            (prevValues.related_content[key].content_type !== currentValues.related_content[key].content_type)
                          }
                        >
                          {({ getFieldValue }) => {
                            let value = getFieldValue(`related_content`);
                            if (value[name].type === 'static' && value[name].content_type === 'allergies') {
                              return (
                                <Form.Item
                                  {...restField}
                                  name={[name, 'id_allergy']}
                                  label="Content Type"
                                >
                                  <Select
                                    placeholder="Choose one option..."
                                    options={allergies.map(item => {
                                      return { value: item.id, label: item.name }
                                    })}
                                    size='large'
                                  />
                                </Form.Item>
                              )
                            } else if (value[name].type === 'static' && value[name].content_type === 'articles') {
                              return (<Form.Item
                                {...restField}
                                name={[name, 'id_article']}
                                label="Content Type"
                              >
                                <Select
                                  placeholder="Choose one option..."
                                  options={articles.map(item => {
                                    return { value: item.id, label: item.name }
                                  })}
                                  size='large'
                                />
                              </Form.Item>)
                            } else if (value[name].type === 'dynamic' && value[name].content_type === 'articles') {
                              return (<Form.Item
                                {...restField}
                                name={[name, 'tags']}
                                label="Tags"
                              >
                                <Select
                                  mode="tags"
                                  placeholder="Choose one or more option(s)..."
                                  options={tags.map(item => {
                                    return { value: item.id, label: item.name }
                                  })}
                                  size='large'
                                />
                              </Form.Item>);
                            } else if (value[name].type === 'static' && value[name].content_type === 'manifestations') {
                              return (<Form.Item
                                {...restField}
                                name={[name, 'id_manifestation']}
                                label="Content Type"
                              >
                                <Select
                                  placeholder="Choose one option..."
                                  options={manifestations.map(item => {
                                    return { value: item.id, label: item.name }
                                  })}
                                  size='large'
                                />
                              </Form.Item>)
                            } else {
                              return null;
                            }
                          }}
                        </Form.Item>
                    }}
                  </Form.Item >
                </Col>
                )
              })}
            </Row>
          </>
          )
        }}
      </Form.List>

      <Col span={24} className='d-flex jc-center w-100'>
        <Form.Item wrapperCol={{ span: 24 }} className='d-flex jc-center ai-center'>
          <Button type="primary" htmlType="submit" size='large'>
            Save
          </Button>
        </Form.Item>
      </Col>
    </>
  )
}