import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    let slugOfArticle = pathname.split('/')[pathname.split('/').length - 1];
    if(pathname.includes('/recursos/sabias-que/') && slugOfArticle !== 'sabias-que'){
      //console.log('article details')
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [pathname]);

  return null;
}