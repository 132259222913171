import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover, Tabs } from 'antd';
import { ArrowRightOutlined, CopyOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import Kids from './Kids';
import School from './School';
import Play from './Play';

import './Advices.css';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';

function DiagnosticAndTretment({ lang }) {

  const [kidsData, setKidsData] = useState({});
  const [schoolData, setSchoolData] = useState({});
  const [playData, setPlayData] = useState({});

  useEffect(() => {
    handleGetDiagnosticAndTreatment();
  }, [lang]);

  function handleGetDiagnosticAndTreatment() {
    axios.get(endpoints.advices.getAdvices, {
      params: { lang }
    }).then((res) => {
      let kidsAux = res.data.filter((item) => item.type === "kids")[0];
      let schoolAux = res.data.filter((item) => item.type === "school")[0];
      let playAux = res.data.filter((item) => item.type === "play")[0];


      setKidsData(kidsAux);
      setSchoolData(schoolAux);
      setPlayData(playAux);
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <div className='dashboard_manifestations'>
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={12}>
              <h1 className="mt-0 mb-0">Advices</h1>
            </Col>
          </Row>
          <Tabs
            type="card"
            items={[
              {
                key: 1,
                label: `Kids`,
                children: <Kids lang={lang} kidsData={kidsData} />,
              },
              {
                key: 2,
                label: `School`,
                children: <School lang={lang} schoolData={schoolData} />,
              },
              {
                key: 3,
                label: `Play`,
                children: <Play lang={lang} playData={playData} />,
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
}

export default DiagnosticAndTretment;
