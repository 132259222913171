import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin, Typography, Collapse, Select, Form, Input, Checkbox } from 'antd';
import { ArrowRightOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import i18next from 'i18next';
import config from '../../../utils/config';
import Seo from '../../../components/website/Seo/Seo';
import endpoints from '../../../utils/endpoints';
import bannerImage from '../../../assets/images/header-kit-del-alergico.jpg'
import logoImage from '../../../assets/images/logo-leti-cores.svg'

import './AllergicKit.css';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;


function AllergicKit({ lang }) {

  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [readMore, setReadMore] = useState(false);

  // Boilerplate stuff
  const htmlParser = new Parser(React);
  const processNodeDefinitions = new ProcessNodeDefinitions(React);
  function isValidNode() {
    return true;
  }

  // Custom instructions for processing nodes
  const processingInstructions = [
    // Create instruction for custom elements
    {
      shouldProcessNode: (node) => {
        // Process the node if it matches a custom element
        return (node.name && (node.name === 'collapse' || node.name === 'panel' || node.name === 'img' || node.name === 'span'));
      },
      processNode: function (node, children, index) {
        if (node.name === 'collapse') {
          return <Collapse defaultactivekey={node.attribs.defaultactivekey}>{children}</Collapse>
        } else if (node.name === 'panel') {
          return <Panel header={node.attribs.header} key={node.attribs.key}>{children}</Panel>
        } else if (node.name === 'img') {
          return <img className={node.attribs.classname} src={`${config.server_ip}${node.attribs.src}`} alt={node.attribs.alt} title={node.attribs.title} />
        } else if (node.name === 'span') {
          return <span id={node.attribs.id} className={`${node.attribs.classname}`} onClick={handleReadMore}>{children}</span>
        }
      }
    },
    // Default processing
    {
      shouldProcessNode: () => true,
      processNode: processNodeDefinitions.processDefaultNode
    }
  ];

  useEffect(() => {
    handleGetAllergicKit();
  }, []);

  function handleGetAllergicKit() {
    axios.get(endpoints.allergicKit.getSingleAllergicKit, {
      params: { lang }
    }).then((res) => {
      handleRenderToHTML(res.data[0]);
    }).catch((err) => {
      console.log(err);
    })
  }
  function handleRenderToHTML(pageData) {
    let auxData = JSON.parse(pageData.content);
    let html = [];

    for (let y = 0; y < auxData.length; y++) {

      let htmlItems = auxData[y].editor.blocks.map((item) => {
        return (<>
          {item.type === "header" && <Title level={item.data.level}>{item.data.text}</Title>}
          {item.type === "paragraph" && <p dangerouslySetInnerHTML={{ __html: item.data.text }}></p>}
          {item.type === "list" && item.data.style === "unordered" &&
            <ul>
              {item.data.items.map(e => (
                <li dangerouslySetInnerHTML={{ __html: e }}></li>
              ))}
            </ul>
          }
          {item.type === "list" && item.data.style !== "unordered" &&
            <ol>
              {item.data.items.map(e => (
                <li dangerouslySetInnerHTML={{ __html: e }}></li>
              ))}
            </ol>
          }
          {item.type === "image" &&
            <img src={`${config.server_ip}assets/media/${item.name}`} />
          }
          {item.type === "raw" &&
            stringToHTML(item.data.html)
          }
        </>)
      })

      html.push(<div
        className='full_container'
        style={{
          backgroundColor: auxData[y].background ? auxData[y].backgroundColor : 'unset',
          paddingTop: auxData[y].paddingTop ? `${auxData[y].paddingTop}px` : 'unset',
          paddingBottom: auxData[y].paddingBottom ? `${auxData[y].paddingBottom}px` : 'unset',
          marginTop: auxData[y].marginTop ? `${auxData[y].marginTop}px` : 'unset',
          marginBottom: auxData[y].marginBottom ? `${auxData[y].marginBottom}px` : 'unset'
        }}
      ><div className='container'>{htmlItems}</div></div>)

    }

    let auxDataRelatedContent = pageData.related_content.filter(item => item.is_active === 1)
    pageData.related_content = auxDataRelatedContent;
    pageData.content = html;

    setData(pageData);
    setIsLoading(false);
  }

  const stringToHTML = (html) => {
    let htmlWithoutSpaces = html.replace(/\s+/g, ' ').trim()
    htmlWithoutSpaces = htmlWithoutSpaces.replace(/>\s+</g, '><');
    let reactComponent = htmlParser.parseWithInstructions(htmlWithoutSpaces,
      isValidNode, processingInstructions);
    return reactComponent;
  }

  function handleReadMore() {
    let readClickMoreDiv = document.getElementById('click-read-more');
    let readMoreDiv = document.getElementById('read-more');
    readMoreDiv.classList.add('open-read-more');
    readClickMoreDiv.classList.add('hidden');
  }

  return (
    <Spin spinning={isLoading}>
      <div className="allergies_details page">
        <Seo title={data.seo_title} description={data.seo_description} img={data.img} style={data.style} />
        <Row className='full_container allergies_banner'>
          <div style={{ backgroundImage: `url(${bannerImage})` }}></div>
        </Row>
        <Row className='container d-flex flex-column'>
          <h1 className='allergies_title mb-10'>{i18next.t('allergic_kit.title')}</h1>
          <hr className='title_divider' />
        </Row>
        {data.content}
        {data.related_content ?
          <Row className='container pb-40'>
            <Col span={24}>
              <h2 className='mb-10'><b>{i18next.t('related_content.title')}</b></h2>
              <p className='m-0'>{i18next.t('related_content.subtitle')}</p>
              <Row gutter={[24]} className='contenido_relacionado d-flex jc-sb'>
                {data.related_content.map((item) => {
                  return (
                    <Col
                      xs={24}
                      sm={24}
                      md={(24 / (data.related_content.length))}
                      lg={(24 / (data.related_content.length))}
                      xl={(24 / (data.related_content.length))}
                    >
                      {item.content_type === "allergies" &&
                        <a
                          onClick={() => navigate(`/${lang}/tipos-de-alergia/${item.slug}/sintomas-causas`)}
                          className='related_content_div'
                          style={{ backgroundImage: `url('${config.server_ip}assets/media/${item.img}')` }}
                        >
                          <p className='title'>{item.name}</p>
                        </a>
                      }
                      {item.content_type === "articles" &&
                        <a
                          onClick={() => navigate(`/${lang}/recursos/sabias-que/${item.slug}`)}
                          className='related_content_div'
                          style={{ backgroundImage: `url('${config.server_ip}assets/media/${item.img}')` }}
                        >
                          <p className='title'>{item.name}</p>
                        </a>
                      }
                      {item.content_type === "manifestations" &&
                        <a
                          onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${item.slug}`)}
                          className='related_content_div'
                          style={{ backgroundImage: `url('${config.server_ip}assets/media/${item.img}')` }}
                        >
                          <p className='title text-center'>{item.name}</p>
                        </a>
                      }
                      {item.content_type === "other" &&
                        <a
                          onClick={() => navigate(`/${item.slug}`)}
                          className='related_content_div'
                          style={{ backgroundImage: `url('${config.server_ip}assets/media/${item.img}')` }}
                        >
                          <p className='title'>{item.title}</p>
                        </a>
                      }
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>
          :
          null
        }
      </div>
    </Spin>
  );
}

export default AllergicKit;
