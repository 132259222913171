import { Modal, Form, Input, Upload, Row, Col, Drawer, Button, notification } from 'antd';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios'
import './DrawerEditLinkOfInterest.css';
import config from '../../../utils/config';
import { useEffect, useState } from 'react';
import endpoints from '../../../utils/endpoints';

const { useForm } = Form;
const { Dragger } = Upload;

const defaultFormList = { links: [{}] }

export default function DrawerEditLinkOfInterest({ data, open, close, lang }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = useForm();

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      data.links = JSON.parse(data.links);
      form.setFieldsValue(data);
    }
  }, [open]);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    values.links = JSON.stringify(values.links);

    axios.post(endpoints.linksOfInterest.editLink, {
      data: { values, lang }
    }).then((res) => {
      setTimeout(() => {
        close();
        form.setFieldsValue({});
        notification.success({
          message: 'Well done!',
          description:
            'Link of interest was edited with success!'
        })
        setIsButtonLoading(false);
      }, 1500);
    }).catch((error) => {
      console.log(error);
      setIsButtonLoading(false);
      notification.error({
        message: 'Oh no!',
        description:
          'Some error happened, please try again later!'
      })
    });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  return (
    <Drawer
      className='add_link_of_interest'
      open={open}
      onClose={handleClose}
      width={1000}
      title="Edit Link Of Interest"
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={defaultFormList}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Name">
          <Input size='large' />
        </Form.Item>
        <Form.List name="links">
          {(fields, { add, remove }) => (
            <Row>
              <Col span={24}>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Row>
                    <Col span={24} className='p-20' style={{ border: '1px dashed #ccc', marginBottom: 20 }}>
                      <Form.Item
                        label="Title"
                        name={[name, 'title']}
                      >
                        <Input
                          size='large'
                        />
                      </Form.Item>
                      <Form.Item
                        label="Link"
                        name={[name, 'link']}
                      >
                        <Input
                          size='large'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                <Button
                  type='dashed'
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  size='large'
                >
                  Adicionar
                </Button>
              </Col>
            </Row>
          )}
        </Form.List>
        <Form.Item wrapperCol={{ span: 24 }} className='d-flex jc-center ai-center'>
          <Button type="primary" htmlType="submit" size='large' loading={isButtonLoading} disabled={isButtonLoading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer >
  )
};