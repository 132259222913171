import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover, Input } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import DrawerAddPodcast from '../../../components/dashboard/DrawerAddPodcast/DrawerAddPodcast';
import DrawerEditPodcast from '../../../components/dashboard/DrawerEditPodcast/DrawerEditPodcast';
import config from '../../../utils/config'
import endpoints from '../../../utils/endpoints';
import './Podcasts.css';

const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Video(s)',
    dataIndex: 'links',
    key: 'links',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];


function Podcasts({ lang }) {

  const [tableData, setTableData] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [selectedPodcast, setSelectedPodcast] = useState({});
  const [isOpenModalCreatePodcast, setIsOpenModalCreatePodcast] = useState(false);
  const [isOpenModalEditPodcast, setIsOpenModalEditPodcast] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetPodcasts();
  }, [lang]);

  function handleGetPodcasts() {
    axios.get(endpoints.podcasts.getPodcasts, {
      params: { lang }
    }).then((res) => {
      setPodcasts(res.data);
      handlePrepareTableData(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDeletePodcast(item) {
    axios.post(endpoints.podcasts.deletePodcast, {
      data: { item }
    }).then((res) => {
      handleGetPodcasts();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handlePrepareTableData(data) {
    let auxTableData = [];

    for (let i = 0; i < data.length; i++) {

      const contentDelete = (
        <div>
          <p>Want to delete {data[i].name}?</p>
          <Button type="primary" onClick={() => handleDeletePodcast(data[i])}>Delete</Button>
        </div>
      );

      let auxInfo = JSON.parse(data[i].info);
      let liksString = "";
      auxInfo.map((item, index) => {
        liksString += item.link
        if (auxInfo[index + 1]) {
          liksString += "<br/>"
        }
      })

      let aux = {
        key: i,
        name: data[i].name,
        description: <div dangerouslySetInnerHTML={{ __html: data[i].description }}></div>,
        links: <div dangerouslySetInnerHTML={{ __html: liksString }}></div>,
        actions: (<div className='icons_table'>
          <EditOutlined className="icon icon_edit mr-10" onClick={() => handleOpenModalEditPodcast(data[i])} />
          <ArrowRightOutlined className="icon icon_edit mr-10" onClick={() => navigate(`/admin/podcasts/${data[i].id}`, { state: { name: data[i].name } })} />
          <Popover placement="topLeft" title={"Delete"} content={contentDelete} trigger="click">
            <DeleteOutlined className="icon icon_edit mr-10" />
          </Popover>
        </div>)
      }

      auxTableData.push(aux);
    }

    setTableData(auxTableData);
  }

  function handleOpenModalCreatePodcast() {
    setIsOpenModalCreatePodcast(true);
  }

  function handleCloseModalCreatePodcast() {
    setIsOpenModalCreatePodcast(false);
    handleGetPodcasts();
  }

  function handleOpenModalEditPodcast(data) {
    setSelectedPodcast(data)
    setIsOpenModalEditPodcast(true);
  }

  function handleCloseModalEditPodcast() {
    setIsOpenModalEditPodcast(false);
    handleGetPodcasts();
  }

  function handleSearch(e) {
    let searchString = e.target.value;
    let searchData = podcasts.filter((item) => item.name.includes(searchString));
    handlePrepareTableData(searchData);
  }

  return (
    <div className='dashboard_allergies'>
      <DrawerAddPodcast open={isOpenModalCreatePodcast} close={handleCloseModalCreatePodcast} lang={lang} />
      <DrawerEditPodcast data={selectedPodcast} open={isOpenModalEditPodcast} close={handleCloseModalEditPodcast} lang={lang} />
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={16}>
              <h1 className="mt-0 mb-0">Podcasts</h1>
            </Col>
            <Col span={8} className="d-flex jc-end ai-center">
              <Input size='large' onChange={handleSearch} className='mr-10' placeholder='Search...' />
              <Button size='large'  onClick={handleOpenModalCreatePodcast}>Adicionar</Button>
            </Col>
          </Row>
          <Table dataSource={tableData} columns={columns} />
        </Col>
      </Row>
    </div>
  );
}

export default Podcasts;
