import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row } from 'antd';
import { Helmet } from 'react-helmet';
import curaLogo from '../../../assets/images/logo-curatualergia.svg'
import './Loading.css';
import { LoadingOutlined } from '@ant-design/icons';


function Loading() {
  return (
    <div className="loading_page">
      <Helmet>
        <title>Curatualergia</title>
        <meta name="description" content="Curatualergia new wbsite" />

      </Helmet>
      <img src={curaLogo} />
      <LoadingOutlined />
    </div>
  );
}

export default Loading;
