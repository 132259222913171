import { Breadcrumb, Layout, Input, Form, Row, Col, Button, Tabs, Switch, ColorPicker, InputNumber, notification, Spin, Select } from 'antd';
import { useState, useEffect } from 'react';
import {
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Link } from 'react-router-dom';
import { PlusOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import pretty from 'pretty';
import Editor from '../../../components/dashboard/Editor/Editor';
import EditorCSS from '../../../components/dashboard/EditorCSS/EditorCSS';
import './AllergicKit.css';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';
import Dragger from 'antd/es/upload/Dragger';

const defaultContentObject = {
  full_width_parent: 1,
  full_width: 0,
  background: 0,
  backgroundColor: "#000",
  backgroundImage: null,
  paddingTop: null,
  paddingBottom: null,
  marginTop: null,
  marginBottom: null,
  editor: {
    time: 1687787067474,
    blocks: [
      {
        id: "ci2MsAeULf",
        type: "header",
        data: {
          text: "Some new content",
          level: 1
        }
      },
    ],
    version: "2.27.0"
  }
}

const RelatedContentAllergicKit = ({ lang }) => {

  const [fileListRelatedContentOne, setFileListRelatedContentOne] = useState([]);
  const [fileListRelatedContentTwo, setFileListRelatedContentTwo] = useState([]);
  const [fileListRelatedContentThree, setFileListRelatedContentThree] = useState([]);
  const [filePreview, setFilePreview] = useState(['', '', '']);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [data, setData] = useState({});
  const [articles, setArticles] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [tags, setTags] = useState([]);
  const [manifestations, setManifestations] = useState([]);

  const [form] = Form.useForm();

  const uploadProps = [
    {
      key: "related_content_1_file_img",
      accept: 'image/*',
      multiple: false,
      maxCount: 1,
      onRemove: (file) => {
        const index = fileListRelatedContentOne.indexOf(file);
        const newFileList = fileListRelatedContentOne.slice();
        newFileList.splice(index, 1);
        setFileListRelatedContentOne(newFileList);
        let filePreviewAux = JSON.parse(JSON.stringify(filePreview))
        filePreviewAux[0] = "";
        setFilePreview(filePreviewAux);
      },
      beforeUpload: async (file) => {
        setFileListRelatedContentOne([file]);
        let filePreviewAux = JSON.parse(JSON.stringify(filePreview))
        filePreviewAux[0] = await handlePreviewRelatedContent(file);
        setFilePreview(filePreviewAux);
        return false;
      },
      fileList: fileListRelatedContentOne,
      defaultFileList: []
    },
    {
      key: "related_content_2_file_img",
      accept: 'image/*',
      multiple: false,
      maxCount: 1,
      onRemove: (file) => {
        const index = fileListRelatedContentTwo.indexOf(file);
        const newFileList = fileListRelatedContentTwo.slice();
        newFileList.splice(index, 1);
        setFileListRelatedContentTwo(newFileList);
        let filePreviewAux = JSON.parse(JSON.stringify(filePreview))
        filePreviewAux[1] = "";
        setFilePreview(filePreviewAux);
      },
      beforeUpload: async (file) => {
        setFileListRelatedContentTwo([file]);
        let filePreviewAux = JSON.parse(JSON.stringify(filePreview))
        filePreviewAux[1] = await handlePreviewRelatedContent(file);
        setFilePreview(filePreviewAux);
        return false;
      },
      fileList: fileListRelatedContentTwo,
      defaultFileList: []
    },
    {
      key: "related_content_3_file_img",
      accept: 'image/*',
      multiple: false,
      maxCount: 1,
      onRemove: (file) => {
        const index = fileListRelatedContentThree.indexOf(file);
        const newFileList = fileListRelatedContentThree.slice();
        newFileList.splice(index, 1);
        setFileListRelatedContentThree(newFileList);
        let filePreviewAux = JSON.parse(JSON.stringify(filePreview))
        filePreviewAux[2] = "";
        setFilePreview(filePreviewAux)
      },
      beforeUpload: async (file) => {
        setFileListRelatedContentThree([file]);
        let filePreviewAux = JSON.parse(JSON.stringify(filePreview))
        filePreviewAux[2] = await handlePreviewRelatedContent(file);
        setFilePreview(filePreviewAux);
        return false;
      },
      fileList: fileListRelatedContentThree,
      defaultFileList: []
    }
  ];

  useEffect(() => {
    axios.get(endpoints.allergicKit.getRelatedContentInfo, {
      params: { lang }
    }).then((res) => {
      let auxData = res.data[0][0];
      if(auxData.related_content){
        auxData.related_content = JSON.parse(auxData.related_content)
      }

      setData(auxData);
      setAllergies(res.data[1]);
      setArticles(res.data[2]);
      setTags(res.data[3]);
      setManifestations(res.data[4]);

      form.setFieldsValue(auxData);
    }).catch((err) => {
      console.log(err);
    })
  }, [filePreview])

  async function handlePreviewRelatedContent(file) {
    return new Promise(async (resolve, reject) => {
      let filePreview = await getBase64(file);
      resolve(filePreview);
    })
  }


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleSubmit(values) {

    let formData = new FormData();

    fileListRelatedContentOne.forEach((file) => {
      if (file.uid !== 'not_new') {
        formData.append('file_1', file);
      }
    });

    fileListRelatedContentTwo.forEach((file) => {
      if (file.uid !== 'not_new') {
        formData.append('file_2', file);
      }
    });

    fileListRelatedContentThree.forEach((file) => {
      if (file.uid !== 'not_new') {
        formData.append('file_3', file);
      }
    });

    formData.append('data', JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.allergicKit.editAllergicKitRelatedContent}`, {
      method: "POST",
      body: formData
    }).then(res => res.json())
      .then((resData) => {
        notification.success({
          message: 'Well done!',
          description:
            'Related content edited!'
        })
      }).catch((error) => {
        console.log(error);
        notification.error({
          message: 'Error!',
          description:
            'Something wrong happened'
        })
      });
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{ related_content: [{ type: 'dynamic' }, { type: 'dynamic' }, { type: 'dynamic' }] }}
    >
    <Form.Item
      hidden
      name="id"
    >
      <Input />
    </Form.Item>
      <Form.List
        name="related_content"
      >
        {(fields, { add, remove }) => {
          return (<>
            <Row gutter={[24]}>
              {fields.map(({ key, name, ...restField }, index) => {
                return (<Col span={8}>
                  <Form.Item
                    {...restField}
                    name={[name, 'is_active']}
                    label="Active"
                    valuePropName='checked'
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'type']}
                    label="Type"
                    rules={[
                      {
                        required: true,
                        message: 'Este campo é obrigatório'
                      }
                    ]}
                  >
                    <Select
                      options={[
                        { value: 'dynamic', label: 'Dynamic Content' },
                        { value: 'static', label: 'Static Content' },
                      ]}
                      size='large'
                    />
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      (prevValues.related_content[key].type !== currentValues.related_content[key].type)
                    }
                  >
                    {({ getFieldValue }) => {
                      let value = getFieldValue(`related_content`);
                      return (value[name].type === 'dynamic') ?
                        <Form.Item
                          {...restField}
                          name={[name, 'content_type']}
                          label="Content Type"
                          rules={[
                            {
                              required: true,
                              message: 'Este campo é obrigatório'
                            }
                          ]}
                        >
                          <Select
                            placeholder="Choose one option..."
                            options={[
                              { value: 'allergies', label: 'Allergies' },
                              { value: 'articles', label: 'Sabías que?' },
                              { value: 'manifestations', label: 'Manifestationes' },
                              { value: 'other', label: 'Other' },
                            ]}
                            size='large'
                          />
                        </Form.Item>
                        :
                        <Form.Item
                          {...restField}
                          name={[name, 'content_type']}
                          label="Content Type"
                          rules={[
                            {
                              required: true,
                              message: 'Este campo é obrigatório'
                            }
                          ]}
                        >
                          <Select
                            placeholder="Choose one option..."
                            options={[
                              { value: 'allergies', label: 'Allergies' },
                              { value: 'articles', label: 'Sabías que?' },
                              { value: 'manifestations', label: 'Manifestationes' },
                              { value: 'other', label: 'Other' },
                            ]}
                            size='large'
                          />
                        </Form.Item>

                    }}
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      (prevValues.related_content[key].type !== currentValues.related_content[key].type) ||
                      (prevValues.related_content[key].content_type !== currentValues.related_content[key].content_type)
                    }
                  >
                    {({ getFieldValue }) => {
                      let value = getFieldValue(`related_content`);
                      return (value[name].type === 'static' && value[name].content_type === 'other') ?
                        <>
                          <Form.Item
                            {...restField}
                            name={[name, 'title']}
                            label="Title"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'link']}
                            label="URL"
                            rules={[
                              {
                                required: true,
                                message: 'Este campo é obrigatório'
                              }
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            name={[name, 'img']}
                            valuePropName={name === 0 ? 'fileListRelatedContentOne' : (name === 1 ? 'fileListRelatedContentTwo' : 'fileListRelatedContentThree')}
                            label="Image"
                            rules={[
                              {
                                required: true,
                                message: 'Este campo é obrigatório'
                              }
                            ]}
                          >
                            <Dragger {...uploadProps[name]} className={`dragger ${filePreview[name] ? 'not_show' : ''}`}>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">Click or drag file to this area to upload</p>
                              <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                banned files.
                              </p>
                              <div
                                className={`preview_file ${data.related_content[name].img ? '' : (filePreview[name] ? '' : 'not_show')}`}
                                style={{
                                  backgroundImage: `url(${(filePreview[name] && filePreview[name].includes('base64')) ?
                                    filePreview[name] :
                                    `${config.server_ip}assets/media/${filePreview[name]}`})`
                                }}
                              >
                              </div>
                            </Dragger>
                          </Form.Item>
                        </>
                        :
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            (prevValues.related_content[key].type !== currentValues.related_content[key].type) ||
                            (prevValues.related_content[key].content_type !== currentValues.related_content[key].content_type)
                          }
                        >
                          {({ getFieldValue }) => {
                            let value = getFieldValue(`related_content`);
                            if (value[name].type === 'static' && value[name].content_type === 'allergies') {
                              return (
                                <Form.Item
                                  {...restField}
                                  name={[name, 'id_allergy']}
                                  label="Content Type"
                                >
                                  <Select
                                    placeholder="Choose one option..."
                                    options={allergies.map(item => {
                                      return { value: item.id, label: item.name }
                                    })}
                                    size='large'
                                  />
                                </Form.Item>
                              )
                            } else if (value[name].type === 'static' && value[name].content_type === 'articles') {
                              return (<Form.Item
                                {...restField}
                                name={[name, 'id_article']}
                                label="Content Type"
                              >
                                <Select
                                  placeholder="Choose one option..."
                                  options={articles.map(item => {
                                    return { value: item.id, label: item.name }
                                  })}
                                  size='large'
                                />
                              </Form.Item>)
                            } else if (value[name].type === 'dynamic' && value[name].content_type === 'articles') {
                              return (<Form.Item
                                {...restField}
                                name={[name, 'tags']}
                                label="Tags"
                              >
                                <Select
                                  mode="tags"
                                  placeholder="Choose one or more option(s)..."
                                  options={tags.map(item => {
                                    return { value: item.id, label: item.name }
                                  })}
                                  size='large'
                                />
                              </Form.Item>);
                            } else if (value[name].type === 'static' && value[name].content_type === 'manifestations') {
                              return (<Form.Item
                                {...restField}
                                name={[name, 'id_manifestation']}
                                label="Content Type"
                              >
                                <Select
                                  placeholder="Choose one option..."
                                  options={manifestations.map(item => {
                                    return { value: item.id, label: item.name }
                                  })}
                                  size='large'
                                />
                              </Form.Item>)
                            } else {
                              return null;
                            }
                          }}
                        </Form.Item>
                    }}
                  </Form.Item >
                </Col>
                )
              })}
            </Row>
          </>
          )
        }}
      </Form.List>
      <Row className='mt-20'>
        <Col span={24} className='d-flex jc-center ai-center'>
          <Button loading={isButtonLoading} disabled={isButtonLoading} className='submit_button' size="large" onClick={form.submit}>Save</Button>
        </Col>
      </Row>
    </Form>
  )
};
export default RelatedContentAllergicKit;