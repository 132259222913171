import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin } from 'antd';
import axios from 'axios';
import { ArrowRightOutlined } from '@ant-design/icons';

import Seo from '../../../components/website/Seo/Seo';
import config from '../../../utils/config';

import imageBanner from '../../../assets/images/mujer-estornudo-alergia-sintomas-causas-prevencion.jpg'
import imageAcaro from '../../../assets/icons/icon-acaro.svg'
import imageFlower from '../../../assets/icons/icon-flower.svg'
import imageDog from '../../../assets/icons/icon-dog.svg'
import imageAlimentos from '../../../assets/icons/icon-alergia-alimentos.svg'
import imageHongos from '../../../assets/icons/icon-alergia-hongos.svg'
import imageInsectos from '../../../assets/icons/icon-alergia-insectos.svg'
import imageMedicamentos from '../../../assets/icons/icon-alergia-medicamentos.svg'
import imageNinos from '../../../assets/icons/icon-alergia-ninos.svg'
import imageIdea from '../../../assets/icons/icon-bombilla-idea.svg'
import imageParticles from '../../../assets/icons/icon-alergia-particulas-azul.svg'
import imageCasa from '../../../assets/icons/icon-alergia-casa-hogar.svg'
import imageCesped from '../../../assets/icons/icon-alergia-cesped.svg'
import imageAlimentosAzul from '../../../assets/icons/icon-alergia-alimentos-azul.svg'
import imageEspecialista from '../../../assets/icons/icon-alergia-especialista.svg'
import imageTestimonial from '../../../assets/images/testimonial-montse-jandula-alergia-acaros-polenes.jpg'
import imageConsejos from '../../../assets/images/thumb-consejos-padres-ninos-alergicos.jpg'
import imageEscucha from '../../../assets/images/thumb-escucha-alergologo-podcasts.jpg'


import './Allergies.css';
import i18next from 'i18next';
import endpoints from '../../../utils/endpoints';

function Allergies({ lang }) {

  const [allergies, setAllergies] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetAllergies();
  }, []);

  function handleClickAllergy(type) {
    switch (type) {
      case 'pollen':
        navigate(`/${lang}/tipos-de-alergia/alergia-polen/sintomas-causas`);
        break;
      case 'mites':
        navigate(`/${lang}/tipos-de-alergia/${lang === 'es' ? 'alergia-acaros-polvo' : 'alergia-acaros-po'}/sintomas-causas`);
        break;
      case 'animals':
        navigate(`/${lang}/tipos-de-alergia/${lang === 'es' ? 'alergia-animales' : 'alergia-animais'}/sintomas-causas`);
        break;
      case 'foods':
        navigate(`/${lang}/tipos-de-alergia/${lang === 'es' ? 'alergia-alimentaria' : 'alergia-alimentar'}/sintomas-causas`);
        break;
      case 'fungi':
        navigate(`/${lang}/tipos-de-alergia/${lang === 'es' ? 'alergia-hongos' : 'alergia-fungos'}/sintomas-causas`);
        break;
      case 'insects':
        navigate(`/${lang}/tipos-de-alergia/'alergia-insetos' : 'alergia-insetos'}/sintomas-causas`);
        break;
      case 'medicines':
        navigate(`/${lang}/tipos-de-alergia/alergia-medicamentos/sintomas-causas`);
        break;
      case 'allergy_in_children':
        navigate(`/${lang}/tipos-de-alergia/${lang === 'es' ? 'alergia-en-ninos' : 'alergia-em-criancas'}/sintomas-causas`);
        break;
      case 'other':
        navigate(`/${lang}/tipos-de-alergia`);
        break;
      default:
        navigate(`/${lang}/tipos-de-alergia`);
        break;
    }
  }

  function handleGetAllergies() {
    axios.get(endpoints.allergies.getAllergies).then((res) => {
      setAllergies(res.data);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e)
    })
  }

  return (
    <Spin spinning={isLoading}>
      <div className="allergies page">
        <Seo title={i18next.t('home.allergies.title')} description={i18next.t('home.allergies.title')} />
        <Row className='full_container allergies_banner'>
          <div className='bg-img-mobile' style={{ backgroundImage: `url(${config.server_ip}assets/media/mujer-estornudo-alergia-sintomas-causas-prevencion.jpg)` }}></div>
        </Row>
        <Row gutter={[24]} className='container d-flex flex-column'>
          <Col span={24}>
            <h1 className='allergies_title mb-10 mb-'>{i18next.t('home.allergies.title')}</h1>
            <hr className='title_divider' />
            <p className='mt-30' dangerouslySetInnerHTML={{ __html: i18next.t('allergies.body_1') }}></p>
            <p>{i18next.t('allergies.body_2')}</p>
          </Col>
        </Row>
        <Row gutter={[24]} className='container p-5-mobile'>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('pollen')}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="100">
              <img className="maxh-70" src={imageFlower} />
              <p className='white  text-uppercase mt-10 mb-10 text-center'>{i18next.t('allergies.pollen')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('mites')}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="200">
              <img className="maxh-70" src={imageAcaro} />
              <p className='white  text-uppercase mt-10 mb-10 text-center'>{i18next.t('allergies.mites')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('animals')}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="300">
              <img className="maxh-70" src={imageDog} />
              <p className='white  text-uppercase mt-10 mb-10 text-center'>{i18next.t('allergies.animals')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('foods')}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="400">
              <img className="maxh-70" src={imageAlimentos} />
              <p className='white  text-uppercase mt-10 mb-10 text-center'>{i18next.t('allergies.foods')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('fungi')}>
            <div className='allergies_card' data-aos="fade-left" data-aos-delay="400">
              <img className="maxh-70" src={imageHongos} />
              <p className='white  text-uppercase mt-10 mb-10 text-center'>{i18next.t('allergies.fungi')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('insects')}>
            <div className='allergies_card' data-aos="fade-left" data-aos-delay="300">
              <img className="maxh-70" src={imageInsectos} />
              <p className='white  text-uppercase mt-10 mb-10 text-center'>{i18next.t('allergies.insects')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('medicines')}>
            <div className='allergies_card' data-aos="fade-left" data-aos-delay="200">
              <img className="maxh-70" src={imageMedicamentos} />
              <p className='white  text-uppercase mt-10 mb-10 text-center'>{i18next.t('allergies.medicines')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('allergy_in_children')}>
            <div className='allergies_card' data-aos="fade-left" data-aos-delay="100">
              <img className="maxh-70" src={imageNinos} />
              <p className='white  text-uppercase mt-10 mb-10 text-center'>{i18next.t('allergies.allergy_in_children')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
        </Row>
        <Row gutter={[24]} className='container'>
          <Col span={24}>
            <h2>{i18next.t('symptoms_and_causes.title')}</h2>
            <p>{i18next.t('symptoms_and_causes.body_1')}</p>
            <p>{i18next.t('symptoms_and_causes.body_2')}</p>
            <ul className='pl-20'>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.symptoms_1')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.symptoms_2')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.symptoms_3')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.symptoms_4')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.symptoms_5')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.symptoms_6')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.symptoms_7')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.symptoms_8')}</li>
            </ul>
          </Col>
        </Row>

        <Row gutter={[24]} className='container'>
          <Col span={24} className='bg-blue'>
            <Row gutter={[24]} className='pl-20 pr-20 pt-30 pb-30'>
              <Col span={2} className='d-flex jc-center ai-center'>
                <img className='maxh-70' src={imageIdea} />
              </Col>
              <Col span={22} className='d-flex jc-center ai-center'>
                <p className='white bold m-0'>{i18next.t('symptoms_and_causes.highlight_1')}</p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[24]} className='container'>
          <Col span={24}>
            <p>{i18next.t('symptoms_and_causes.causes_of_allergy')}</p>
            <ul className='pl-20'>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.causes_1')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.causes_2')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.causes_3')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.causes_4')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.causes_5')}</li>
              <li className='mb-10 bold'>{i18next.t('symptoms_and_causes.causes_6')}</li>
            </ul>
            <p>{i18next.t('symptoms_and_causes.body_3')}</p>
            <p>{i18next.t('symptoms_and_causes.body_4')}</p>
          </Col>
        </Row>

        <Row gutter={[24]} className='container'>
          <Col span={24} className='bg-blue'>
            <Row gutter={[24]} className='pl-20 pr-20 pt-30 pb-30 pt-10-mobile'>
              <Col span={2} className='d-flex jc-center ai-center'>
                <img className='maxh-70' src={imageIdea} />
              </Col>
              <Col span={22} className='d-flex jc-center ai-center'>
                <p className='white bold m-0'>{i18next.t('symptoms_and_causes.highlight_2')}</p>
              </Col>
            </Row>
          </Col>
        </Row>



        <Row gutter={[24]} className='container strategies_div'>
          <Col span={24}>
            <h2>{i18next.t('prevent_allergy.title')}</h2>
            <p>{i18next.t('prevent_allergy.body_1')}</p>
            <p className="mb-30">{i18next.t('prevent_allergy.strategies')}</p>
            <Row>
              <Col xs={24} sm={12} className='pr-20'>
                <div className='block-mobile d-flex jc-start ai-center mb-30'>
                  <div className='d-flex jc-start ai-center mr-40'>
                    <img className='minw-80 maxw-80 w-100' src={imageParticles} />
                  </div>
                  <div className=' d-flex flex-column jc-start ai-start'>
                    <p className='bold m-0'>{i18next.t('prevent_allergy.strategy_1_title')}</p>
                    <p className='m-0'>{i18next.t('prevent_allergy.strategy_1_text')}</p>
                  </div>
                </div>
                <div className='block-mobile d-flex jc-start ai-center mb-30'>
                  <div className='d-flex jc-start ai-center mr-40'>
                    <img className='minw-80 maxw-80 w-100' src={imageCasa} />
                  </div>
                  <div className='d-flex flex-column jc-start ai-start'>
                    <p className='bold m-0'>{i18next.t('prevent_allergy.strategy_2_title')}</p>
                    <p className='m-0'>{i18next.t('prevent_allergy.strategy_2_text')}</p>
                  </div>
                </div>
                <div className='block-mobile d-flex jc-start ai-center mb-30'>
                  <div className='d-flex jc-start ai-center mr-40'>
                    <img className='minw-80 maxw-80 w-100' src={imageCesped} />
                  </div>
                  <div className='d-flex flex-column jc-start ai-start'>
                    <p className='bold m-0'>{i18next.t('prevent_allergy.strategy_3_title')}</p>
                    <p className='m-0'>{i18next.t('prevent_allergy.strategy_3_text')}</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} className='pl-20 pl-0-mobile'>
                <div className=' block-mobile d-flex jc-start ai-center mb-30'>
                  <div className='d-flex jc-start ai-center mr-40'>
                    <img className='minw-80 maxw-80 w-100' src={imageAlimentosAzul} />
                  </div>
                  <div className='d-flex flex-column jc-start ai-start'>
                    <p className='bold m-0'>{i18next.t('prevent_allergy.strategy_4_title')}</p>
                    <p className='m-0'>{i18next.t('prevent_allergy.strategy_4_text')}</p>
                  </div>
                </div>
                <div className='block-mobile d-flex jc-start ai-center mb-30'>
                  <div className='d-flex jc-start ai-center mr-40'>
                    <img className='minw-80 maxw-80 w-100' src={imageEspecialista} />
                  </div>
                  <div className='d-flex flex-column jc-start ai-start'>
                    <p className='bold m-0'>{i18next.t('prevent_allergy.strategy_5_title')}</p>
                    <p className='m-0'>{i18next.t('prevent_allergy.strategy_5_text')}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>



        <Row gutter={[24]} className='container'>
          <Col span={24} className='bg-blue'>
            <Row gutter={[24]} className='pl-20 pr-20 pt-30 pb-30 pt-10-mobile'>
              <Col span={2} className='d-flex jc-center ai-center'>
                <img className='maxh-70' src={imageIdea} />
              </Col>
              <Col span={22} className='d-flex jc-center ai-center'>
                <p className='white bold m-0'>{i18next.t('prevent_allergy.highlight_1')}</p>
              </Col>
            </Row>
          </Col>
        </Row>


        <Row gutter={[24]} className='container'>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className='mb-30'>
            <div className='d-flex flex-column'
              onClick={() => navigate(`/${lang}/recursos/${lang === 'es' ? 'testimoniales' : 'testemunhos'}`)}>
              <h2 className='m-0'>{i18next.t('our_patients.title')}</h2>
              <p className='w-75 mobile-w-100'>{i18next.t('our_patients.body_1')}</p>
              <img className="hover-cursor w-100" src={imageTestimonial} />
              <Button
                onClick={() => navigate(`/${lang}/recursos/${lang === 'es' ? 'testimoniales' : 'testemunhos'}`)}
                className='mt-0 bg-blue white w-100' size='large'>{i18next.t('our_patients.more_testimonials')}</Button>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <h2 className='m-0'><b>{i18next.t('related_content.title')}</b></h2>
            <p className='w-75 mobile-w-100'>{i18next.t('related_content.subtitle')}</p>
            <div className='d-flex jc-center ai-center p-40 hover-cursor bg-center bg-cover' style={{ backgroundImage: `url(${imageConsejos})` }}
              onClick={() => navigate(`/${lang}/recursos/${lang === 'es' ? 'consejos-para-padres' : 'conselhos-para-pais'}/${lang === 'es' ? 'la-alergia-ninos' : 'alergia-criancas'}`)}>
              <p className='f-24 white text-center bold'>{i18next.t('related_content.fathers')}</p>
            </div>
            <div className='d-flex jc-center ai-center p-40 hover-cursor mt-20 bg-center bg-cover' style={{ backgroundImage: `url(${imageEscucha})` }}
              onClick={() => navigate(`/${lang}/recursos/podcasts`)}>
              <p className='f-24 white text-center bold'>{i18next.t('related_content.allergist')}</p>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default Allergies;
