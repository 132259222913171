import { Menu, Row, Col, Button, Drawer, Layout, Icon, List, Input } from 'antd';
import {
  Outlet,
} from "react-router-dom";
import './DrawerMenu.css';
import { useEffect, useState } from 'react';
import i18next, { t } from 'i18next';
import axios from 'axios';
import endpoints from '../../../utils/endpoints';
import iconSearch from '../../../assets/icons/icon-lupa-busqueda.svg';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';

export default function DrawerMenuManifestations({ lang, open, close, items, onClick, handleSearch }) {

  const [current, setCurrent] = useState('');

  useEffect(() => {
    //console.log(open)
  }, [open])

  const navigate = useNavigate();

  return (
    <Drawer
      width={'100%'}
      className='mobile-drawer-menu'
      open={open}
      onClose={close}
      closable={false}
    >
      <div className='header-mobile-menu d-flex jc-sb ai-center'>
        <div className='d-flex jc-center ai-center'>
          <Search
            enterButton={<img src={iconSearch} alt="languages" className='h-100 maxh-20 hover-cursor' />}
            size='large'
            placeholder={i18next.t('home.search.placeholder')}
            onSearch={handleSearch}
          />
        </div>
        <div className='d-flex jc-center ai-center'>
          <CloseOutlined className='f-20' onClick={close} />
        </div>
      </div>
      <div className='p-20'>
        <div className='menu-selected d-flex jc-center ai-center position-relative'>
          <div className='arrow-left-menu-mobile'>
            <ArrowLeftOutlined className='white' onClick={close} />
          </div>
          <div>
            <p className='white bold'>{t('menu.manifestations')}</p>
          </div>
        </div>
      </div>
      <div className='mobile-menu'>
        <Menu
          key="allergies_sub_menu"
          theme="dark"
          className='w-100 allergies_sub_menu'
          mode="inline"
          onClick={onClick}
          selectedKeys={[current]}
          items={items}
        />
      </div>
    </Drawer>
  )
};