import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Input } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons'
import imageAcaro from '../../../assets/icons/icon-acaro.svg'
import imageFlower from '../../../assets/icons/icon-flower.svg'
import imageDog from '../../../assets/icons/icon-dog.svg'
import imageAllergies from '../../../assets/icons/icon-alergias.svg'
import './AllergiesTypes.css';
import i18next from 'i18next';

const { Search } = Input

export default function AllergiesTypes({ lang, allergies }) {

  const navigate = useNavigate();

  function handleClickAllergy(type) {
    switch (type) {
      case 'pollen':
        navigate(`/${lang}/tipos-de-alergia/${allergies.filter((item) => item.slug === 'alergia-polen')[0].slug}/sintomas-causas`);
        break;
      case 'mites':
        navigate(`/${lang}/tipos-de-alergia/${allergies.filter((item) => item.slug === 'alergia-acaros-polvo' || item.slug === 'alergia-acaros-po')[0].slug}/sintomas-causas`);
        break;
      case 'animals':
        navigate(`/${lang}/tipos-de-alergia/${allergies.filter((item) => item.slug === 'alergia-animales' || item.slug === 'alergia-animais')[0].slug}/sintomas-causas`);
        break;
      case 'other':
        navigate(`/${lang}/tipos-de-alergia`);
        break;
      default:
        navigate(`/${lang}/tipos-de-alergia`);
        break;
    }
  }

  return (
    <div className="container allergies_types">
      <Row gutter={[24]}>
        <Col span={24} className='d-flex flex-column jc-start ai-start'>
          <h1 className="mb-0">{i18next.t('home.allergies.title')}</h1>
          <p className="mt-0 f-26">{i18next.t('home.allergies.subtitle')}</p>
        </Col>
      </Row>
      <Row gutter={[24]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('pollen')}>
          <div className='allergies_card' data-aos="fade-right" data-aos-delay="100">
            <img src={imageFlower} />
            <p className='title'>{i18next.t('home.allergies.pollen')}</p>
            <ArrowRightOutlined />
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('mites')}>
          <div className='allergies_card' data-aos="fade-right" data-aos-delay="200">
            <img src={imageAcaro} />
            <p className='title'>{i18next.t('home.allergies.mites')}</p>
            <ArrowRightOutlined />
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('animals')}>
          <div className='allergies_card' data-aos="fade-right" data-aos-delay="300">
            <img src={imageDog} />
            <p className='title'>{i18next.t('home.allergies.animals')}</p>
            <ArrowRightOutlined />
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => handleClickAllergy('other')}>
          <div className='allergies_card' data-aos="fade-right" data-aos-delay="400">
            <img src={imageAllergies} />
            <p className='title'>{i18next.t('home.allergies.other')}</p>
            <ArrowRightOutlined />
          </div>
        </Col>
      </Row>
    </div>
  );
}
