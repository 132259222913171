import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin } from 'antd';
import axios from 'axios';
import { ArrowRightOutlined } from '@ant-design/icons';

import Seo from '../../../components/website/Seo/Seo';
import config from '../../../utils/config';

import imageBanner from '../../../assets/images/manifestaciones-alergicas-hombre-estornudando.jpg'
import imageRinitis from '../../../assets/icons/icon-manifestaciones-rinitis.svg'
import imageConjuntivitis from '../../../assets/icons/icon-conjuntivitis-alergica.svg'
import imageAsma from '../../../assets/icons/icon-manifestaciones-asma.svg'
import imagePiel from '../../../assets/icons/icon-manifestaciones-piel.svg'
import imageAnafilaxia from '../../../assets/icons/icon-anafilaxia.svg'
import imageNinos from '../../../assets/icons/icon-manifestaciones-alergicas-ninos.svg'
import imageTestimonial from '../../../assets/images/testimonial-montse-jandula-alergia-acaros-polenes.jpg'
import imageConsejos from '../../../assets/images/thumb-consejos-padres-ninos-alergicos.jpg'
import imageEscucha from '../../../assets/images/thumb-escucha-alergologo-podcasts.jpg'

import './Manifestations.css';
import i18next from 'i18next';
import endpoints from '../../../utils/endpoints';


function Manifestations({lang}) {

  const [allergies, setAllergies] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetAllergies();
  }, []);

  function handleGetAllergies() {
    axios.get(endpoints.allergies.getAllergies).then((res) => {
      setAllergies(res.data);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e)
    })
  }

  return (
    <Spin spinning={isLoading}>
      <div className="allergies page">
        <Seo title={i18next.t('home.manifestations.title')} description={i18next.t('home.manifestations.title')} />
        <Row className='full_container allergies_banner'>
          <div style={{ backgroundImage: `url(${imageBanner})` }}></div>
        </Row>
        <Row gutter={[24]} className='container d-flex flex-column'>
          <Col xs={24} sm={18}>
            <h1 className='allergies_title mb-10'>{i18next.t('home.manifestations.title')}</h1>
            <hr className='title_divider' />
            <p className='mt-30'dangerouslySetInnerHTML={{ __html: i18next.t('allergies.body_1') }}></p>
            <p dangerouslySetInnerHTML={{ __html: i18next.t('allergies.body_2') }}></p>
            <p>{i18next.t('manifestations.body_3')}</p>
          </Col>
        </Row>
        <Row gutter={[24]} className='container p-5-mobile'>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${lang === 'es' ? 'rinitis-alergica-sintomas-causas-diagnostico-tratamiento' : 'rinitis-alergica-sintomas-causas-diagnostico-tratamiento'}`)}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="100" data-aos-offset="0">
              <img className="maxh-70" src={imageRinitis} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('home.manifestations.rhinitis')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${lang === 'es' ? 'conjuntivitis-alergica-sintomas-causas-diagnostico-tratamiento' : 'conjuntivite-alergica-sintomas-causas-diagnostico-tratamiento'}`)}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="200" data-aos-offset="0">
              <img className="maxh-70" src={imageConjuntivitis} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('home.manifestations.conjunctivitis')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} 
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${lang === 'es' ? 'asma-alergica-sintomas-causas-diagnostico-tratamiento/' : 'asma-alergica-sintomas-causas-diagnostico-tratamento'}`)}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="300" data-aos-offset="0">
              <img className="maxh-70" src={imageAsma} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('home.manifestations.asthma')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} className='manifestations-hidden'>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${lang === 'es' ? 'manifestaciones-alergicas-en-la-piel' : 'manifestacoes-alergicas-na-pele'}`)}>
            <div className='allergies_card' data-aos="fade-left" data-aos-delay="400">
              <img className="maxh-70" src={imagePiel} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('home.manifestations.skin_allergy')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${lang === 'es' ? 'anafilaxia-sintomas-causas-diagnostico-tratamiento' : 'anafilaxia-sintomas-causas-diagnostico-tratamento'}`)}>
            <div className='allergies_card' data-aos="fade-left" data-aos-delay="300">
              <img className="maxh-70" src={imageAnafilaxia} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('home.manifestations.anaphylaxis')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${lang === 'es' ? 'alergia-ninos-sintomas' : 'alergia-criancas-sintomas'}`)}>
            <div className='allergies_card' data-aos="fade-left" data-aos-delay="200">
              <img className="maxh-70" src={imageNinos} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('home.manifestations.children_manifestations')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}>
          </Col>
        </Row>


        <Row gutter={[24]} className='container p-20 related_content_mobile'>
          <Col xs={24} sm={12} className='mb-20-mobile'>
            <div className='d-flex flex-column block-mobile'>
              <h2 className='m-0 articles-home-mobile'>{i18next.t('our_patients.title')}</h2>
              <p className='w-75 mobile-w-100 mt-0-mobile articles-home-mobile'>{i18next.t('our_patients.body_1')}</p>
              <img 
              onClick={() => navigate(`/${lang}/recursos/${lang === 'es' ? 'testimoniales' : 'testemunhos'}`)}
              className="hover-cursor w-100" src={imageTestimonial} />
              <Button 
              onClick={() => navigate(`/${lang}/recursos/${lang === 'es' ? 'testimoniales' : 'testemunhos'}`)}
              className='mt-0 bg-blue white w-100' size='large'>{i18next.t('our_patients.more_testimonials')}</Button>
            </div>
          </Col>
          <Col xs={24} sm={12}>
              <h2 className='m-0 articles-home-mobile'><b>{i18next.t('related_content.title')}</b></h2>
              <p className='mt-0-mobile articles-home-mobile'>{i18next.t('related_content.subtitle')}</p>
            <div className='d-flex jc-center ai-center p-40 hover-cursor bg-center bg-cover' style={{ backgroundImage: `url(${imageConsejos})` }}
              onClick={() => navigate(`/${lang}/recursos/${lang === 'es' ? 'consejos-para-padres' : 'conselhos-para-pais'}/${lang === 'es' ? 'la-alergia-ninos' : 'alergia-criancas'}`)}>
              <p className='f-24 white text-center bold'>{i18next.t('related_content.fathers')}</p>
            </div>
            <div className='d-flex jc-center ai-center p-40 hover-cursor mt-20 bg-center bg-cover' style={{ backgroundImage: `url(${imageEscucha})` }}
              onClick={() => navigate(`/${lang}/recursos/podcasts`)}>
              <p className='f-24 white text-center bold'>{i18next.t('related_content.allergist')}</p>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default Manifestations;
