import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin, message } from 'antd';
import axios from 'axios';
import Masonry from '@mui/lab/Masonry';

import Seo from '../../../components/website/Seo/Seo';
import config from '../../../utils/config';

import imageBanner from '../../../assets/images/manifestaciones-alergicas-hombre-estornudando.jpg'

import './Articles.css';
import { Box } from '@mui/material';
import endpoints from '../../../utils/endpoints';
import { ShareAltOutlined } from '@ant-design/icons';
import i18next from 'i18next';


function ArticlesDetails({ lang }) {

  const [articles, setArticles] = useState([]);
  const [articlesTags, setArticlesTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [itemsPerLoad, setItemsPerLoad] = useState(6);
  const [seo, setSeo] = useState({})

  const { state } = useLocation();

  const location = useLocation();

  let { slug } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    handleGetArticles();
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, []);

  useEffect(() => {
    if (!isLoading) {
      let observers = [];
      // scroll change url
      const elements = document.querySelectorAll(".article-details-item");

      let auxThreshold = [];

      for (let i = 0; i <= 1.0; i += 0.01) {
        auxThreshold.push(i);
      }

      let observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: auxThreshold,
      };

      for (let i = 0; i < elements.length; i++) {
        observers[i] = new IntersectionObserver(intersectionCallback, observerOptions);
        observers[i].observe(elements[i]);
      }
    }
  }, [isLoading]);

  function intersectionCallback(entries) {
    console.log('entrou aqui');

    entries.forEach((entry) => {
      let box = entry.target;
      let visiblePct = Math.floor(entry.intersectionRatio * 100);

      if (visiblePct > 75) {
        let articleSlug = box.className.split(' ')[1];
        let filterArticle = articles.filter((item) => item.slug === articleSlug)[0];
        setSeo({
          title: filterArticle.name,
          description: filterArticle.seo_description
        })
        navigate(`/${lang}/recursos/sabias-que/${filterArticle.slug}`, { replace: true })
      }
    });
  };

  function handleGetArticles() {
    axios.get(endpoints.articles.getArticlesBySlug, {
      params: { lang, slug }
    }).then((res) => {
      let aux = res.data[0];
      let otherArticles = res.data[1];
      let auxTags = res.data[2];
      let auxAllArticles = [...aux, ...otherArticles];

      setSeo({
        title: auxAllArticles[0].name,
        description: auxAllArticles[0].seo_description
      });

      setArticles(auxAllArticles);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e)
    })
  }

  function handleCopyClipboard(item) {
    navigator.clipboard.writeText(`https://curatualergia.phormuladev.com/${lang}/recursos/sabias-que/${item.slug}`);
    message.success({ content: 'Link is on clipboard' })
  }

  return (
    <Spin spinning={isLoading}>
      <div className="allergies page">
        <Seo title={seo.title} description={seo.description} />
        <Row className='full_container allergies_banner'>
          <div style={{ backgroundImage: `url(${imageBanner})` }}></div>
        </Row>
        <Row gutter={[24]} className='container d-flex flex-column'>
          <Col span={18}>
            <h1 className='allergies_title mb-10'>{i18next.t('home.articles.title')}</h1>
            <hr className='title_divider' />
          </Col>
        </Row>
        <Row gutter={[24]} className='container d-flex'>
          <Col xs={24} sm={20} md={20} lg={20} xl={18}>
            {articles.map((item) => {
              return (
                <div className={`article-details-item ${item.slug}`}>
                  <img className='w-100' src={`${config.server_ip}assets/media/${item.img}`} alt={item.img_alt} style={{ maxWidth: 760 }} />
                  <h2>{item.name}</h2>
                  <div className='content' dangerouslySetInnerHTML={{ __html: item.content }}></div>
                  <div className='share'>
                    <Button className='share_button' onClick={() => handleCopyClipboard(item)}>
                      <ShareAltOutlined /> {i18next.t('general.share')}
                    </Button>
                  </div>
                </div>
              )
            })}
          </Col>
        </Row>
      </div>
    </Spin >
  );
}

export default ArticlesDetails;
