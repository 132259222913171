import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin, Typography, Collapse, Select, Form, Input, Checkbox } from 'antd';
import { ArrowRightOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import i18next from 'i18next';
import config from '../../../utils/config';
import Seo from '../../../components/website/Seo/Seo';
import endpoints from '../../../utils/endpoints';
import bannerImage from '../../../assets/images/manifestaciones-alergicas-hombre-estornudando.jpg'

import './CookiesPolicy.css';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;


// Boilerplate stuff
const htmlParser = new Parser(React);
const processNodeDefinitions = new ProcessNodeDefinitions(React);
function isValidNode() {
  return true;
}

// Custom instructions for processing nodes
const processingInstructions = [
  // Create instruction for custom elements
  {
    shouldProcessNode: (node) => {
      // Process the node if it matches a custom element
      return (node.name && (node.name === 'collapse' || node.name === 'panel' || node.name === 'img'));
    },
    processNode: function (node, children, index) {
      if (node.name === 'collapse') {
        return <Collapse defaultactivekey={node.attribs.defaultactivekey}>{children}</Collapse>
      } else if (node.name === 'panel') {
        return <Panel header={node.attribs.header} key={node.attribs.key}>{children}</Panel>
      } else if (node.name === 'img') {
        return <img className={node.attribs.classname} src={`${config.server_ip}${node.attribs.src}`} alt={node.attribs.alt} title={node.attribs.title} />
      }
    }
  },
  // Default processing
  {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode
  }
];

function CookiesPolicy({ lang }) {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    axios.get(endpoints.pagesInfo.getPageInfo, {
      params: { lang, name: 'cookies' }
    }).then((res) => {
      handleRenderToHTML(res.data[0]);
    }).catch((err) => {
      console.log(err);
    })
  }, []);

  function handleRenderToHTML(pageData) {
    let auxData = JSON.parse(pageData.content);
    let html = [];
    let htmlItems = auxData.blocks.map((item) => {
      return (<>
        {item.type === "header" && <Title level={item.data.level}>{item.data.text}</Title>}
        {item.type === "paragraph" && <p dangerouslySetInnerHTML={{ __html: item.data.text }}></p>}
        {item.type === "list" && item.data.style === "unordered" &&
          <ul>
            {item.data.items.map(e => (
              <li dangerouslySetInnerHTML={{ __html: e }}></li>
            ))}
          </ul>
        }
        {item.type === "list" && item.data.style !== "unordered" &&
          <ol>
            {item.data.items.map(e => (
              <li dangerouslySetInnerHTML={{ __html: e }}></li>
            ))}
          </ol>
        }
        {item.type === "image" &&
          <img src={`${config.server_ip}assets/media/${item.name}`} />
        }
        {item.type === "raw" &&
          stringToHTML(item.data.html)
        }
      </>)
    })

    html.push(<div
      className='full_container'
    ><div className='container'>{htmlItems}</div></div>)

    pageData.content = html;

    setData(pageData);
    setIsLoading(false);
  }

  const stringToHTML = (html) => {
    let htmlWithoutSpaces = html.replace(/\s+/g, ' ').trim()
    htmlWithoutSpaces = htmlWithoutSpaces.replace(/>\s+</g, '><');
    let reactComponent = htmlParser.parseWithInstructions(htmlWithoutSpaces,
      isValidNode, processingInstructions);
    return reactComponent;
  }

  return (
    <div className="allergies_details page">
      <Seo title={i18next.t('cookies.title')} description={i18next.t('cookies.title')} />
      <Row className='container d-flex flex-column'>
        <h1 className='allergies_title mb-10'>{i18next.t('cookies.title')}</h1>
        <hr className='title_divider' />
      </Row>
      {data.content}
    </div>
  );
}

export default CookiesPolicy;
