import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import config from '../../../utils/config'
import endpoints from '../../../utils/endpoints';
import './LinksOfInterest.css';
import DrawerAddLinkOfInterest from '../../../components/dashboard/DrawerAddLinkOfInterest/DrawerAddLinkOfInterest';
import DrawerEditLinkOfInterest from '../../../components/dashboard/DrawerEditLinkOfInterest/DrawerEditLinkOfInterest';

const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];


function LinksOfInterest({ lang }) {

  const [tableData, setTableData] = useState([]);
  const [links, setLinks] = useState([]);
  const [selectedLink, setSelectedLink] = useState({});
  const [isOpenModalCreateLink, setIsOpenModalCreateLink] = useState(false);
  const [isOpenModalEditLink, setIsOpenModalEditLink] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetLinks();
  }, [lang]);

  function handleGetLinks() {
    axios.get(endpoints.linksOfInterest.getLinks, {
      params: { lang }
    }).then((res) => {
      setLinks(res.data);
      handlePrepareTableData(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDeleteLink(id) {
    axios.post(endpoints.linksOfInterest.deleteLink, {
      data: { id, lang }
    }).then((res) => {
      handleGetLinks();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDeleteLink(item) {
    axios.post(endpoints.linksOfInterest.deleteLink, {
      data: { item }
    }).then((res) => {
      handleGetLinks();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handlePrepareTableData(data) {
    let auxTableData = [];

    for (let i = 0; i < data.length; i++) {

      const contentDelete = (
        <div>
          <p>Want to delete {data[i].name}?</p>
          <Button type="primary" onClick={() => handleDeleteLink(data[i])}>Delete</Button>
        </div>
      );

      let aux = {
        key: i,
        name: data[i].name,
        actions: (<div className='icons_table'>
          <EditOutlined className="icon icon_edit mr-10" onClick={() => handleOpenModalEditLink(data[i])} />
          <Popover placement="topLeft" title={"Delete"} content={contentDelete} trigger="click">
            <DeleteOutlined className="icon icon_edit mr-10" />
          </Popover>
        </div>)
      }

      auxTableData.push(aux);
    }

    setTableData(auxTableData);
  }

  function handleOpenModalCreateLink() {
    setIsOpenModalCreateLink(true);
  }

  function handleCloseModalCreateLink() {
    setIsOpenModalCreateLink(false);
    handleGetLinks();
  }

  function handleOpenModalEditLink(data) {
    setSelectedLink(data)
    setIsOpenModalEditLink(true);
  }

  function handleCloseModalEditLink() {
    setSelectedLink({})
    setIsOpenModalEditLink(false);
    handleGetLinks();
  }

  return (
    <div className='dashboard_allergies'>
      <DrawerAddLinkOfInterest open={isOpenModalCreateLink} close={handleCloseModalCreateLink} lang={lang} />
      <DrawerEditLinkOfInterest data={selectedLink} open={isOpenModalEditLink} close={handleCloseModalEditLink} lang={lang} />
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={12}>
              <h1 className="mt-0 mb-0">Links Of Interest</h1>
            </Col>
            <Col span={12} className="d-flex jc-end ai-center">
              <Button onClick={handleOpenModalCreateLink}>Adicionar</Button>
            </Col>
          </Row>
          <Table dataSource={tableData} columns={columns} />
        </Col>
      </Row>
    </div>
  );
}

export default LinksOfInterest;
