import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin } from 'antd';
import axios from 'axios';
import { ArrowRightOutlined } from '@ant-design/icons';

import Seo from '../../../components/website/Seo/Seo';
import config from '../../../utils/config';

import imageBanner from '../../../assets/images/imagenes-videos-mano-tecnologia.jpg'
import imageDiagnostico from '../../../assets/images/thumb-diagnostico.jpg'

import './Images.css';
import i18next from 'i18next';
import endpoints from '../../../utils/endpoints';


function Images({ lang }) {

  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [itemsPerLoad, setItemsPerLoad] = useState(6);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetImages();
  }, []);

  function handleGetImages() {
    axios.get(endpoints.images_videos.getImagesVideos, {
      params: { lang }
    }).then((res) => {
      let imagesFilter = res.data.filter(item => item.type === 'image');
      setImages(imagesFilter);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleLoadMore() {
    setIsButtonLoading(true);
    setIsLoading(true);
    setTimeout(() => {
      setItemsPerLoad(itemsPerLoad + 6)
    }, 500)
    setTimeout(() => {
      setIsButtonLoading(false);
      setIsLoading(false);
    }, 1000)
  }
  
  return (
    <Spin spinning={isLoading}>
      <div className="allergies page">
        <Seo title={i18next.t('images_videos.images')} description={(lang === 'es' ? 'Imágenes y vídeos sobre alergia' : 'Imagens e vídeos sobre alergia')} />
        <Row className='full_container allergies_banner'>
          <div title={(lang === 'es' ? 'Imágenes y vídeos sobre alergia' : 'Imagens e vídeos sobre alergia')} style={{ backgroundImage: `url(${imageBanner})` }}></div>
        </Row>
        <Row gutter={[24]} className='container d-flex flex-column'>
          <Col span={18}>
            <h1 className='allergies_title mb-10'>{i18next.t('images_videos.images')}</h1>
            <hr className='title_divider' />
          </Col>
        </Row>
        <Row gutter={[35,35]} className='container'>
          {images.slice(0, itemsPerLoad).map((item, index) => {
            return (
              <>
                <Col xs={24} xl={8}>
                  <img src={`${config.server_ip}assets/media/${item.image}`} className="img-list"/>
                  <p className='img-title'>{item.name}</p>
                  <p className='m-0'><i>{item.description}</i></p>
                </Col>
              </>
            )})
          }  
        </Row>
        
        {itemsPerLoad < images.length ?
          <Row gutter={[24]} className='mb-40 mt-20'>
            <Col span={24}>
              <Button size='large' type='primary' loading={isButtonLoading} onClick={handleLoadMore}>{i18next.t('home.articles.load_more')}</Button>
            </Col>
          </Row>
          :
          null
        }</div>
    </Spin>
  );
}

export default Images;
