import { Button, Checkbox, Col, Drawer, Form, Input, Layout, List, Modal, Row } from 'antd';
import {
  Outlet,
} from "react-router-dom";
import './ModalNewsletter.css';
import { Children, useState } from 'react';
import imageNewsletter from '../../../assets/images/img-newsletter.png'
import i18next from 'i18next';
import axios from 'axios';

const { Content } = Layout;

export default function ModalNewsletter({ lang, open, close }) {

  const [form] = Form.useForm();

  function handleSubmit(values) {
    let urlPT = 'https://newsletter.leti.com/x/plugin/?pName=subscribe&MIDRID=S7Y1NAcAA18&pLang=pt&Z=-284961023'
    let urlES = 'https://newsletter.leti.com/x/plugin/?pName=subscribe&MIDRID=S7Y1NAcAA18&pLang=es&Z=-284961023'
    let url = lang === 'pt' ? urlPT : urlES;
    values.lang = lang;

    let formData = new FormData();
    formData.append('data', JSON.stringify(values));

    fetch(url, {
      method: "POST",
      body: values,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      console.log(res);
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <Modal
      open={open}
      onCancel={close}
      className='modal_newsletter'
      footer={[]}
    >
      <Row gutter={[24]} className='mr-0 ml-0'>
        <Col xs={24} xl={12} className='img_newsletter' style={{ backgroundImage: `url(${imageNewsletter})` }}></Col>
        <Col xs={24} xl={12} className='pl-20 pr-20 pt-50 pb-50 mobile-padding'>
          <h2 className='mt-0 mobile-margin'>{i18next.t('home.newsletter.title')}</h2>
          <p className='mobile-margin'>{i18next.t('home.newsletter.subtitle')}</p>
          {lang === 'es' ?
            <form action="https://newsletter.leti.com/x/plugin/?pName=subscribe&MIDRID=S7Y1NAcAA18&pLang=es&Z=-284961023" method="post">
              <input type="hidden" name="DMDtask" value="subscribe" />
              <div class="d-flex">
                <label for="email">Introduce tu email abajo:</label>
              </div>
              <div class="mt-10 d-flex">
                <input className='input_newsletter' id="nl-modal-email" type="email" name="email" placeholder="email@ejemplo.com" maxlength="255" required />
              </div>
              <div class="d-flex mt-20">
                <input type="checkbox" name="lang" value="es" required />
                <label for="lang">Accepto la <a href="https://www.leti.com/es/protecciondedatos#gdpr_news" target="_blank" style={{ color: '#7B7B7B !important' }} rel="noopener"><b>política de privacidad</b></a></label>
              </div>
              <div class="d-flex mt-20">
                <input className='button_newsletter' type="submit" value="SUSCRIBIRME" />
              </div>
              <div style={{ visibility: 'hidden', fontSize: 0 }}>
                Please <em>don't</em> insert text in the box below!
                <input type="text" name="submitCheck" value="" style={{ width: '1px', height: '1px', fontSize: 0 }} />
              </div>
            </form>
            :
            <form action="https://newsletter.leti.com/x/plugin/?pName=subscribe&MIDRID=S7Y1NAcAA18&pLang=pt&Z=-284961023" method="post">
              <input type="hidden" name="DMDtask" value="subscribe" />
              <div class="d-flex">
                <label for="email">Introduz o teu e-mail abaixo:</label>
              </div>
              <div class="d-flex mt-10">
                <input className='input_newsletter' id="nl-modal-email" type="email" name="email" placeholder="email@exemplo.com" maxlength="255" required />
              </div>
              <div class="d-flex mt-20">
                <input type="checkbox" name="lang" value="pt" required />
                <label for="lang">Aceito a <a href="https://www.leti.com/pt/protecciondedatos#gdpr_news" target="_blank" style={{ color: '#7B7B7B !important' }} rel="noopener"><b>política de privacidade</b></a></label>
              </div>
              <div class="d-flex mt-20">
                <input className='button_newsletter' type="submit" value="SUBSCREVER" />
              </div>
              <div style={{ visibility: 'hidden', fontSize: 0 }}>
                Please <em>don't</em> insert text in the box below!
                <input type="text" name="submitCheck" value="" style={{ width: '1px', height: '1px', fontSize: 0 }} />
              </div>
            </form>
          }
        </Col>
      </Row>
    </Modal>
  )
};