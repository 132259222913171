import { Breadcrumb, Layout, Menu, theme, Row, Col, Tooltip, Popover } from 'antd';
import axios from 'axios';
import {
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import './Main.css';
import { useEffect, useState } from 'react';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import logoCura from '../../../assets/images/logo-curatualergia.svg'
import iconGlobe from '../../../assets/icons/icon-globe.svg';
import iconSearch from '../../../assets/icons/icon-lupa-busqueda.svg';
import seaicLogo from '../../../assets/images/logo-seaic.svg'
import spaicLogo from '../../../assets/images/logo-SPAIC.svg'
import seicaapLogo from '../../../assets/images/logo-seicaap.svg'
import letipLogo from '../../../assets/images/logo-leti.svg'
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';
import DrawerMenu from '../DrawerMenu/DrawerMenu';
import './Main.css';
import './Header.css';
import './Footer.css';
import ModalNewsletter from '../ModalNewsletter/ModalNewsletter';
import i18next from 'i18next';
import Search from 'antd/es/input/Search';

const { Header, Content, Footer } = Layout;

const lngs = {
  es: { nativeName: 'es' },
  pt: { nativeName: 'pt' }
}

export default function Main({ lang, handleChangeLanguage }) {

  const [allergies, setAllergies] = useState([]);
  const [items, setItems] = useState([]);
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalNewsletter, setIsOpenModalNewsletter] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);


  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    openModalIfFirstTime();
    axios.get(endpoints.allergies.getAllergies, {
      params: { lang }
    }).then((res) => {
      let allergiesItems = res.data;

      allergiesItems = allergiesItems.sort(function (a, b) {
        return a.menu_position - b.menu_position;
      });

      axios.get(endpoints.manifestations.getManifestations, {
        params: { lang }
      }).then((resManifestations) => {
        let manifestationsItems = resManifestations.data;

        manifestationsItems = manifestationsItems.sort(function (a, b) {
          return a.menu_position - b.menu_position;
        });

        let auxItems = [
          {
            popupClassName: 'principal_menu_sub',
            label: <span onClick={() => navigate(`/${lang}/tipos-de-alergia`)}>
              {t('menu.allergies')}
            </span>,
            key: `${lang}_allergies`,
            path: {
              es: `/${lang}/tipos-de-alergia`,
              pt: `/${lang}/tipos-de-alergia`
            },
            children: allergiesItems.map((item) => {
              return {
                label: item.menu_name,
                key: `${lang}_${item.name.toLowerCase().replaceAll(' ', '')}_symptoms`,
                path: `/${lang}/tipos-de-alergia/${item.slug}/sintomas-causas`,
                path: {
                  es: `/${lang}/tipos-de-alergia/${item.slug}/sintomas-causas`,
                  pt: `/${lang}/tipos-de-alergia/${item.slug}/sintomas-causas`
                },
              }
            })
          },
          {
            popupClassName: 'principal_menu_sub',
            label: <span onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}`)}>
              {t('menu.manifestations')}
            </span>,
            key: `${lang}_allergic_manifestations`,
            path: {
              es: `/${lang}/manifestaciones`,
              pt: `/${lang}/manifestacoes`
            },
            children: manifestationsItems.map((item) => {
              return {
                label: item.menu_name,
                key: `${lang}_${item.name.toLowerCase().replaceAll(' ', '')}`,
                path: {
                  es: `/${lang}/manifestaciones/${item.slug}/`,
                  pt: `/${lang}/manifestacoes/${item.slug}`
                },
              }
            })
          },
          {
            label: t('menu.diagnostic_treatment'),
            key: `${lang}_diagnostic_treatment`,
            path: {
              es: `/${lang}/diagnostico-y-tratamiento/diagnostico-alergias`,
              pt: `/${lang}/diagnostico-e-tratamento/diagnostico-alergias`
            },
          },
          {
            popupClassName: 'principal_menu_sub',
            label: <span onClick={() => navigate(`/${lang}/recursos`)}>
              {t('menu.resources')}
            </span>,
            key: `${lang}_resources`,
            path: {
              es: `/${lang}/recursos`,
              pt: `/${lang}/recursos`
            },
            children: [
              {
                label: t('menu.map'),
                key: `${lang}_map`,
                path: {
                  es: `/${lang}/recursos/mapa-polinico`,
                  pt: `https://www.rpaerobiologia.com/previsao-polinica/lisboa`,
                },
              },
              {
                label: t('menu.testimonials'),
                key: `${lang}_testimonials`,
                path: {
                  es: `/${lang}/recursos/testimoniales`,
                  pt: `/${lang}/recursos/testemunhos`,
                },
              },
              {
                label: t('menu.articles'),
                key: `${lang}_articles`,
                path: {
                  es: `/${lang}/recursos/sabias-que`,
                  pt: `/${lang}/recursos/sabias-que`,
                },
              },
              {
                label: t('related_content.allergist'),
                key: `${lang}_podcasts`,
                path: {
                  es: `/${lang}/recursos/podcasts`,
                  pt: `/${lang}/recursos/podcasts`,
                },
              },
              {
                label: t('menu.advices'),
                key: `${lang}_advices`,
                path: {
                  es: `/${lang}/recursos/consejos-para-padres/la-alergia-ninos`,
                  pt: `/${lang}/recursos/conselhos-para-pais/alergia-criancas`,
                },
              },
              {
                label: t('images_videos.images'),
                key: `${lang}_images`,
                path: {
                  es: `/${lang}/imagenes`,
                  pt: `/${lang}/imagens`,
                },
              },
              {
                label: t('home.resources.allergic_kit'),
                key: `${lang}_allergic_kit`,
                path: {
                  es: `/${lang}/recursos/kit-del-paciente-alergico`,
                  pt: `/${lang}/recursos/kit-do-paciente-alergico`,
                },
              },
              {
                label: t('images_videos.videos'),
                key: `${lang}_videos`,
                path: {
                  es: `/${lang}/videos`,
                  pt: `/${lang}/videos`,
                },
              }
            ]
          },
        ]
        setAllergies(res.data);
        setItems(auxItems);
      }).catch((err) => {
        console.log(err);
      })
    }).catch((err) => {
      console.log(err);
    })
  }, [lang]);

  useEffect(() => {
    console.log(location);
  }, [location])

  function openModalIfFirstTime() {
    setTimeout(() => {
      if (!sessionStorage.getItem('secondTime')) {
        sessionStorage.setItem('secondTime', true);
        document.querySelector('.newsletter_popup .button_popup').click();
      }
    }, 90000);
  }

  const [current, setCurrent] = useState('');

  const navigate = useNavigate();

  function onClick(e) {
    let menuItems = items;
    if (e.keyPath.length === 2) {
      let filterParent = menuItems.filter((item) => item.key === e.keyPath[1])[0];
      let filter = filterParent.children.filter((item) => item.key === e.key)[0];
      if (filter.key == 'pt_map') {
        window.open(
          filter.path[`${lang}`], "_blank");
        return;
      }
      navigate(`${filter.path[`${lang}`]}`);
      setCurrent(e.key);
    } else {
      let filter = menuItems.filter((item) => item.key === e.key)[0];
      navigate(`${filter.path[`${lang}`]}`);
      setCurrent(e.key);
    }
  }

  function handleOpenDrawerMenu() {
    setIsOpenMenuMobile(true);
  }

  function handleCloseDrawerMenu() {
    setIsOpenMenuMobile(false);
  }

  function handleOpenModalNewsletter() {
    setIsOpenModalNewsletter(true);
  }

  function handleCloseModalNewsletter() {
    setIsOpenModalNewsletter(false);
  }

  function handleClickFooterLink(e, link) {
    if (e.ctrlKey || e.metaKey) {
      window.open(link)
    } else {
      navigate(link)
    }
  }

  function handleClickSearch() {
    setIsOpenSearch(!isOpenSearch);
  }

  function handleSearch(e) {
    navigate(`/${lang}/search?s=${e}`);
    setIsOpenSearch(false);
  }

  return (
    <Layout>
      <Header
        className='header main_header'
      >
        <div className='content_header d-flex jc-sb ai-center'>
          <div className='logo minw-200 d-flex jc-start ai-center'>
            <img src={logoCura} alt="logo" onClick={() => navigate(`/${lang}`)} />
          </div>
          <div className='desktop-display w-100 d-flex jc-center ai-center position-relative h-100'>
            <Menu
              theme="dark"
              className='w-100 d-flex jc-center ai-center principal_menu'
              mode="horizontal"
              onClick={onClick}
              selectedKeys={[current]}
              items={items}
            />
            <div className={`search_header ${isOpenSearch ? 'open' : ''}`}>
              <Search
                enterButton={<img src={iconSearch} alt="languages" className='h-100 maxh-30 hover-cursor' />}
                size='large'
                placeholder={i18n.t('home.search.placeholder')}
                onSearch={handleSearch}
              />
            </div>
            <div className='minw-200 d-flex jc-end ai-center mobile-hidden minh-50 maxh-50 h-100'>
              <div className='search h-100 d-flex minw-60 maxw-60 d-flex jc-center ai-center'>
                <img src={iconSearch} alt="languages" className='h-100 maxh-30 hover-cursor' onClick={handleClickSearch} />
              </div>
              <Popover
                overlayClassName="langs-popover"
                arrow={false}
                content={<div className='languages h-100'>
                  {Object.keys(lngs).map((lng) => (
                    <div className={i18n.resolvedLanguage === lng ? 'lang-item selected' : 'lang-item'} key={lng} onClick={() => handleChangeLanguage(lng, window.location.pathname)}>
                      {lngs[lng].nativeName}
                    </div>
                  ))}
                </div>}
                trigger="click"
              >
                <img src={iconGlobe} alt="languages" className='h-100 maxh-30 hover-cursor' />
              </Popover>
            </div>
          </div>
          <div className='h-100 jc-center ai-center mobile-display'>
            <div className='minw-40 d-flex jc-end'>
              <MenuOutlined onClick={handleOpenDrawerMenu} className='minw-40 minh-40 d-flex jc-end white' />
              <DrawerMenu open={isOpenMenuMobile} lang={lang} close={handleCloseDrawerMenu} handleChangeLanguage={handleChangeLanguage} />
            </div>
          </div>
        </div>
      </Header>
      <Content
        className="site_content"
      >
        <ModalNewsletter lang={lang} open={isOpenModalNewsletter} close={handleCloseModalNewsletter} />
        <Outlet context={{ allergies }} />
        <div className='newsletter_popup'>
          <div className='button_popup text-uppercase' onClick={handleOpenModalNewsletter}>
            {i18next.t('general.newsletter_button')}
          </div>
        </div>
      </Content>
      <Footer className='footer'>
        <div className='full_container'>
          <div className='container'>
            <Row className='first_row'>
              <Col span={16}>
                <Row>
                  <img src={logoCura} alt="logo" />
                </Row>
                <Row>
                  <Col xs={24} sm={7}>
                    <ul className='footer_menu'>
                      <li onClick={(e) => handleClickFooterLink(e, `/${lang}/${lang === 'es' ? 'consultanos' : 'consultenos'}`)}><span>{t('footer.contact')}</span></li>
                      <li onClick={(e) => handleClickFooterLink(e, `/${lang}/${lang === 'es' ? 'quienes-somos' : 'quem-somos'}`)}><span>{t('footer.about')}</span></li>
                      <li onClick={(e) => handleClickFooterLink(e, `/${lang}/faqs`)}><span>{t('footer.faqs')}</span></li>
                      <li onClick={(e) => handleClickFooterLink(e, `/${lang}/${lang === 'es' ? 'glosario' : 'glossario'}`)}><span>{t('footer.glossary')}</span></li>
                    </ul>
                  </Col>
                  <Col xs={24} sm={12}>
                    <ul className='footer_menu'>
                      <li onClick={(e) => handleClickFooterLink(e, `/${lang}/${lang === 'es' ? 'enlaces-de-interes' : 'links-de-interesse'}`)}><span>{t('footer.links_of_interest')}</span></li>
                      <li onClick={(e) => handleClickFooterLink(e, `/${lang}/${lang === 'es' ? 'avisos-legales' : 'avisos-legais'}`)}><span>{t('footer.legal_notices')}</span></li>
                      <li onClick={(e) => handleClickFooterLink(e, `/${lang}/politica-cookies`)}><span>{t('footer.cookies_policy')}</span></li>
                      <li> <a style={{ color: '#fff' }} target="_blank" href="https://www.leti.com/es/protecciondedatos#gdpr_contact"><span>{t('footer.data_protection')}</span></a></li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={8} className='d-flex flex-column jc-end'>
                <Row>
                  <p className="white">{t('footer.with_approval_of')}</p>
                </Row>
                <Row className='d-flex jc-sb mt-20 logos_row'>
                  {
                    (lang == 'es') ?
                      <>
                        <Col span={7} className='d-flex ai-center'>
                          <a href="https://www.seaic.org/" target="_blank"><img src={seaicLogo} /></a>
                        </Col>
                        <Col span={7} className='d-flex ai-center'>
                          <a href="https://seicap.es/" target="_blank"><img src={seicaapLogo} /></a>
                        </Col>
                      </>
                      :
                      <>
                        <Col span={7} className='d-flex ai-center'>
                          <a href="https://www.spaic.pt" target="_blank"><img src={spaicLogo} /></a>
                        </Col>
                      </>
                  }
                  <Col span={7} className='d-flex ai-end jc-end'>
                    <a href={lang === 'es' ? 'https://www.leti.com/es' : 'https://www.leti.com/pt'} target="_blank"><img src={letipLogo} /></a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div className='full_container footer_second_row'>
          <div className='container'>
            <p className='white f-12'>© 2023 LETI Pharma</p>
          </div>
        </div>
      </Footer>
    </Layout>
  )
};