import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover, Form, Input, notification, Spin } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import config from '../../../utils/config'
import endpoints from '../../../utils/endpoints';
import Editor from '../../../components/dashboard/Editor/Editor';
import './CookiesPolicy.css';


function CookiesPolicy({ lang }) {

  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    handleGetLegalNotice();
  }, [lang]);

  function handleGetLegalNotice() {
    setIsLoading(true);
    axios.get(endpoints.pagesInfo.getPageInfo, {
      params: { lang, name: 'cookies' }
    }).then((res) => {
      let auxPageInfo = res.data[0];
      auxPageInfo.content = JSON.parse(auxPageInfo.content)
      form.setFieldsValue(auxPageInfo);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    })
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios.post(endpoints.pagesInfo.editPageInfo, {
      data: values
    }).then((res) => {
      setTimeout(() => {
        setIsButtonLoading(false);
        notification.success({
          description: ' Sucesso',
          message: 'Foi guardado com sucesso!'
        })
      }, 1500)
    }).catch((err) => {
      console.log(err);
      setIsButtonLoading(false);
    })
  }

  return (
    <div className='dashboard_allergies'>
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={12}>
              <h1 className="mt-0 mb-0">Cookies Policy</h1>
            </Col>
          </Row>
          {isLoading ?
            <Spin spinning={isLoading}></Spin>
            :
            <Form form={form} onFinish={handleSubmit}>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="content">
                <Editor />
              </Form.Item>
              <Button className='mt-40' type='primary' size='large' onClick={form.submit}>Guardar</Button>
            </Form>
          }
        </Col>
      </Row>
    </div>
  );
}

export default CookiesPolicy;
