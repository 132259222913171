import { Modal, Form, Input, Upload, Row, Col, Drawer, Button, Divider } from 'antd';
import { InboxOutlined, PlusCircleOutlined } from '@ant-design/icons';
import axios from 'axios'
import './DrawerAddPodcast.css';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';

const { useForm } = Form;
const { Dragger } = Upload;

const defaultFormList = { info: [{ link: null, itervener: null, office: null }] }

export default function DrawerAddPodcast({ open, close, lang }) {

  const [form] = useForm();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState('');


  const uploadProps = {
    accept: 'image/*',
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview('');
    },
    beforeUpload: (file) => {
      handlePreview(file);
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: []
  };

  function handleSubmit(values) {
    values.lang = lang;
    let formData = new FormData();

    fileList.forEach((file) => {
      formData.append('file', file);
    });

    formData.append('data', JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.podcasts.createPodcast}`, {
      method: "POST",
      body: formData
    }).then((res) => {
      setIsButtonLoading(true);
      setTimeout(() => {
        close();
        setIsButtonLoading(false);
        form.setFieldsValue({});
      }, 1500);
    }).catch((error) => {
      console.log(error);
      setIsButtonLoading(false);
    });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  async function handlePreview(file) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview)
  };


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Drawer
      className="drawer-podcast"
      key={`drawer-create-podcast`}
      title="Create podcast"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>Cancel</Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Create
          </Button>
        </>
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={defaultFormList}
      >
        <Form.Item
          name="thumbnail"
          label="Image"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Dragger {...uploadProps} className={`dragger ${filePreview ? 'not_show' : ''}`}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from uploading company data or other
              banned files.
            </p>
            <div
              className={`preview_file ${filePreview ? '' : 'not_show'}`}
              style={{ backgroundImage: `url(${(filePreview && filePreview.includes('base64')) ? filePreview : `${config.server_ip}assets/media/${filePreview}`})` }}
            >
            </div>
          </Dragger>
        </Form.Item>
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input size='large' placeholder='Nome...'></Input>
        </Form.Item>
        <Form.Item
          name="slug"
          label="Slug"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input size='large' placeholder='Slug...'></Input>
        </Form.Item>
        <Form.Item
          name="description"
          label="Descrição"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <ReactQuill size='large' placeholder='Descrição...' />
        </Form.Item>
        <Divider />
        <h3>SEO</h3>
        <Form.Item
          name="seo_title"
          label="Title"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input placeholder='SEO Title' size="large" />
        </Form.Item>
        <Form.Item
          name="seo_description"
          label="Description"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input.TextArea placeholder='SEO Description' size="large" />
        </Form.Item>
        <Form.Item
          name="seo_keywords"
          label="Keywords"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input placeholder='SEO Keywords' size="large" />
        </Form.Item>
        <Divider />
        <Form.List name="info">
          {(fields, { add, remove }) => (
            <Row gutter={[24]}>
              <Col span={24}>
                {fields.map(({ key, name, ...restField }, index) => (
                  <>
                    <Form.Item
                      name={[name, 'intervener']}
                      label="Interveniente"
                      rules={[
                        {
                          required: true,
                          message: 'Este campo é obrigatório'
                        }
                      ]}
                    >
                      <Input size='large' placeholder='Interveniente...'></Input>
                    </Form.Item>
                    <Form.Item
                      name={[name, 'office']}
                      label="Cargo"
                      rules={[
                        {
                          required: true,
                          message: 'Este campo é obrigatório'
                        }
                      ]}
                    >
                      <Input size='large' placeholder='Cargo...'></Input>
                    </Form.Item>
                    <Form.Item
                      name={[name, 'link']}
                      label="Link"
                      rules={[
                        {
                          required: true,
                          message: 'Este campo é obrigatório'
                        }
                      ]}
                    >
                      <Input size='large' placeholder='Link...'></Input>
                    </Form.Item>
                  </>
                ))}
              </Col>

              <Col span={24} className="mb-24 d-flex jc-center ai-center">
                <Button size="large" onClick={() => add()}>
                  <PlusCircleOutlined /> Adicionar link
                </Button>
              </Col>
            </Row>
          )}
        </Form.List>
      </Form>
    </Drawer>
  )
};