import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin, Typography, Collapse, Select, Form, Input, Checkbox, message } from 'antd';
import { ArrowRightOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import i18next from 'i18next';
import config from '../../../utils/config';
import Seo from '../../../components/website/Seo/Seo';
import endpoints from '../../../utils/endpoints';
import bannerImage from '../../../assets/images/img-header-medico-laptop.jpg'

import './ContactUs.css';
import ModalPrivacyWarning from '../../../components/website/ModalPrivacyWarning/ModalPrivacyWarning';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;


function ContactUs({ lang }) {

  const [isOpenPrivacyWarning, setIsOpenPrivacyWarning] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [contactFormSubmitted, setContactFormSubmitted] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);
    values.lang = lang;
    axios.post(endpoints.contactForm.submit, {
      data: values
    }).then((res) => {
      setIsButtonLoading(false);
      setContactFormSubmitted(true);
      form.resetFields();
    }).catch((err) => {
      console.log(err);
      setIsButtonLoading(false);
      message.success({ content: i18next.t('contact_form.error') })
    })
  }

  function handleOpenPrivacyWarning() {
    setIsOpenPrivacyWarning(true)
  }

  return (
    <div className="allergies_details page">
      <ModalPrivacyWarning lang={lang} open={isOpenPrivacyWarning} close={() => setIsOpenPrivacyWarning(false)} />
      <Seo title={i18next.t('footer.contact')} description={i18next.t('footer.contact')} />
      <Row className='full_container allergies_banner'>
        <div style={{ backgroundImage: `url(${bannerImage})` }}></div>
      </Row>
      <Row className='container d-flex flex-column'>
        <h1 className='allergies_title mb-10'>{i18next.t('footer.contact')}</h1>
        <hr className='title_divider' />
      </Row>
      {contactFormSubmitted ?
        <Row className='container'>
          <Col span={16}>
            {lang === 'es' ?
              <div className='p-10 d-flex jc-center ai-center' style={{ border: '1px solid green' }}>
                <p className='m-0'>Gracias por tu mensaje. Ha sido enviado.</p>
              </div>
              :
              <div className='p-10 d-flex jc-center ai-center' style={{ border: '1px solid green' }}>
                <p className='m-0'>A sua mensagem foi enviada, obrigado.</p>
              </div>
            }
          </Col>
        </Row>
        :
        <Row className='container'>
          <Col xs={24} sm={16} md={16} lg={16} xl={16} >
            <Form
              className='contact-form mb-80'
              form={form}
              layout='vertical'
              onFinish={handleSubmit}
            >
              <Form.Item
                name="name"
                label={i18next.t('contact.name')}
                rules={[
                  {
                    required: true,
                    message: i18next.t('general.required')
                  }
                ]}
              >
                <Input size='large' />
              </Form.Item>
              <Form.Item
                name="email"
                label={i18next.t('contact.email')}
                rules={[
                  {
                    required: true,
                    message: i18next.t('general.required')
                  }
                ]}
              >
                <Input size='large' />
              </Form.Item>
              <Form.Item
                name="message"
                label={i18next.t('contact.message')}
                rules={[
                  {
                    required: true,
                    message: i18next.t('general.required')
                  }
                ]}
              >
                <Input.TextArea size='large' />
              </Form.Item>
              <div className='warning-text' dangerouslySetInnerHTML={{ __html: i18next.t('contact.warning') }}></div>
              <Form.Item
                className='mb-0'
                name="privacy"
                valuePropName='checked'
                rules={[
                  {
                    required: true,
                    message: i18next.t('general.required')
                  }
                ]}
              >
                <Checkbox size='large'>
                  {lang === 'es' ?
                    <div>
                      <p>He leído y estoy de acuerdo con el <a onClick={handleOpenPrivacyWarning}>aviso de privacidad</a></p>
                    </div>
                    :
                    <div>
                      <p>Li e estou de acordo com o <a onClick={handleOpenPrivacyWarning}>aviso de privacidade</a></p>
                    </div>
                  }
                </Checkbox>
              </Form.Item>
              <Form.Item noStyle>
                <Button loading={isButtonLoading} htmlType='submit' size='large' type='primary' className='mt-20'>
                  Enviar
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      }
    </div>
  );
}

export default ContactUs;
