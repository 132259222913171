import { Modal, Form, Input, Upload, Row, Col, Drawer } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios'
import './ModalEditTestimonial.css';
import { useEffect, useState } from 'react';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';

const { useForm } = Form;

export default function ModalEditTestimonial({ data, open, close, lang }) {

  const [form] = useForm();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data])

  function handleSubmit(values) {
    axios.post(endpoints.testimonials.editTestimonial, {
      data: { values, lang }
    }).then((res) => {
      setIsButtonLoading(true);
      setTimeout(() => {
        close();
        form.setFieldsValue({});
      }, 1500);
    }).catch((error) => {
      console.log(error);
    });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  return (
    <Modal
      className='edit_testimonial'
      open={open}
      onOk={form.submit}
      onCancel={handleClose}
      title="Edit Testimonial"
      okText={"Guardar"}
      cancelText={"Cancelar"}
      okButtonProps={{ loading: isButtonLoading }}
      cancelButtonProps={{ disabled: isButtonLoading }}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          hidden
          name="id"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input size='large' placeholder='Nome...'></Input>
        </Form.Item>
        <Form.Item
          name="description"
          label="Descrição"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input size='large' placeholder='Descrição...'></Input>
        </Form.Item>
        <Form.Item
          name="iframe"
          label="Iframe"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input size='large' placeholder='Iframe...'></Input>
        </Form.Item>
      </Form>
    </Modal>
  )
};