import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin, Typography, Collapse, Select, Form, Input, Checkbox } from 'antd';
import { ArrowRightOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import i18next from 'i18next';
import config from '../../../utils/config';
import Seo from '../../../components/website/Seo/Seo';
import endpoints from '../../../utils/endpoints';
import bannerImage from '../../../assets/images/img-header-diente-de-leon.jpg'
import logoImage from '../../../assets/images/logo-leti-cores.svg'

import './AboutUs.css';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;


function AboutUs({ lang }) {
  return (
    <div className="allergies_details page">
      <Seo title={i18next.t('about.title')} description={i18next.t('about.title')} />
      <Row className='full_container allergies_banner'>
        <div style={{ backgroundImage: `url(${bannerImage})` }}></div>
      </Row>
      <Row className='container d-flex flex-column'>
        <h1 className='allergies_title mb-10'>{i18next.t('about.title')}</h1>
        <hr className='title_divider' />
      </Row>
      <Row className='container'>
        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
          <div dangerouslySetInnerHTML={{ __html: i18next.t('about.text_1') }}></div>
          <div dangerouslySetInnerHTML={{ __html: i18next.t('about.text_2') }}></div>
          <div dangerouslySetInnerHTML={{ __html: i18next.t('about.text_3') }}></div>
        </Col>
      </Row>
    </div>
  );
}

export default AboutUs;
