import { Modal, Form, Input, Upload, Row, Col, Drawer, Button, Divider, notification, Select, DatePicker } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios'
import './DrawerAddArticle.css';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';

const { useForm } = Form;
const { Dragger } = Upload;

export default function DrawerAddArticles({ tags, open, close, lang }) {

  const [form] = useForm();

  const [fileList, setFileList] = useState([]);
  const [fileListBanner, setFileListBanner] = useState([]);
  const [filePreview, setFilePreview] = useState('');
  const [fileBannerPreview, setFileBannerPreview] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const uploadProps = {
    accept: 'image/*',
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview('');
    },
    beforeUpload: (file) => {
      handlePreview(file, 'banner');
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: []
  };

  function handleSubmit(values) {
    setIsButtonLoading(true);
    values.lang = lang
    let formData = new FormData();

    fileList.forEach((file) => {
      formData.append('file', file);
    });

    values.date = dayjs(values.date).format('YYYY-MM-DD');

    formData.append('data', JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.articles.createArticle}`, {
      method: "POST",
      body: formData
    }).then((res) => {
      setTimeout(() => {
        close();
        setFileList([]);
        setFilePreview('');
        form.setFieldsValue({});
        notification.success({
          message: 'Well done!',
          description:
            'Article was added with success!'
        })
        setIsButtonLoading(false);
      }, 1500);
    }).catch((error) => {
      console.log(error);
      setIsButtonLoading(false);
      notification.error({
        message: 'Oh no!',
        description:
          'Some error happened, please try again later!'
      })
    });
  }

  async function handlePreview(file) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview)
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  return (
    <Drawer
      className='edit_articles'
      open={open}
      onClose={handleClose}
      width={1000}
      title="Create Article"
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Row gutter={[24]}>
          <Col span={8}>
            <Form.Item
              name="file_img"
              label="Image"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <Dragger {...uploadProps} className={`dragger ${filePreview ? 'not_show' : ''}`}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                  banned files.
                </p>
                <div
                  className={`preview_file ${filePreview ? '' : 'not_show'}`}
                  style={{ backgroundImage: `url(${(filePreview && filePreview.includes('base64')) ? filePreview : `${config.server_ip}assets/media/${filePreview}`})` }}
                >
                </div>
              </Dragger>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name="name"
              label="Nome"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <Input size='large' placeholder='Nome'></Input>
            </Form.Item>
            <Form.Item
              name="img_alt"
              label="Image Alt"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <Input size='large' placeholder='Image alt'></Input>
            </Form.Item>
            <Form.Item
              name="slug"
              label="Slug"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <Input size='large' placeholder='Nome'></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="seo_title"
              label="SEO Title"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <Input size='large' placeholder='SEO Title'></Input>
            </Form.Item>
            <Form.Item
              name="seo_description"
              label="SEO Descrição"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <Input size='large' placeholder='SEO Descrição'></Input>
            </Form.Item>
            <Form.Item
              name="seo_keywords"
              label="SEO Keywords"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <Input size='large' placeholder='SEO Keywords'></Input>
            </Form.Item>
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <DatePicker className='w-100' size='large' />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="tags"
              label="Tags"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <Select
                mode='tags'
                placeholder="Choose your tags"
                options={tags.map((item) => {
                  return { value: item.id, label: item.name }
                })}
                size='large'
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="content"
          label="Text"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <ReactQuill placeholder='Your content here...' style={{ minHeight: '300px' }} theme='snow' />
        </Form.Item>

        <Col span={24} className='d-flex jc-center w-100'>
          <Form.Item wrapperCol={{ span: 24 }} className='d-flex jc-center ai-center'>
            <Button type="primary" htmlType="submit" size='large' loading={isButtonLoading} disabled={isButtonLoading}>
              Save
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </Drawer>
  )
};