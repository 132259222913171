import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover, Input } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import './Articles.css';
import DrawerAddArticle from '../../../components/dashboard/DrawerAddArticle/DrawerAddArticle';
import DrawerEditArticle from '../../../components/dashboard/DrawerEditArticle/DrawerEditArticle';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';
import Search from 'antd/es/input/Search';

const columns = [
  {
    title: '',
    dataIndex: 'img',
    key: 'img',
    width: '10%',
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Slug',
    dataIndex: 'slug',
    key: 'slug',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];


function DashboardAllergies({ lang }) {

  const [tableData, setTableData] = useState([]);
  const [articles, setArticles] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState({});
  const [isOpenCreateArticle, setIsOpenCreateArticle] = useState(false);
  const [isOpenEditArticle, setIsOpenEditArticle] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetArticles();
  }, [lang]);

  function handleGetArticles() {
    axios.get(endpoints.articles.getArticles, {
      params: { lang }
    }).then((res) => {
      setArticles(res.data[0]);
      setTags(res.data[1]);
      handlePrepareTableData(res.data[0]);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDeleteArticle(item) {
    axios.post(endpoints.articles.deleteArticle, {
      data: { id: item.id, lang }
    }).then((res) => {
      handleGetArticles();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDuplicateToOtherLang(item) {
    axios.post(endpoints.articles.duplicateArticle, {
      data: { item, lang: lang === "pt" ? "es" : "pt" }
    }).then((res) => {
      handleGetArticles();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handlePrepareTableData(data) {
    let auxTableData = [];

    for (let i = 0; i < data.length; i++) {

      const content = (
        <div>
          <p>Want to duplicate to {lang === "pt" ? "ES" : "PT"}?</p>
          <Button type="primary" onClick={() => handleDuplicateToOtherLang(data[i])}>Duplicate</Button>
        </div>
      );

      const contentDelete = (
        <div>
          <p>Are you sure you want to delete:</p>
          <p>{data[i].name}?</p>
          <Button type="primary" onClick={() => handleDeleteArticle(data[i])}>Delete</Button>
        </div>
      );

      let aux = {
        key: i,
        img: <div className='img_table' style={{ backgroundImage: `url(${config.server_ip}assets/media/${data[i].img})` }}></div>,
        name: data[i].name,
        slug: data[i].slug,
        actions: (<div className='icons_table'>
          <EditOutlined className="icon icon_edit mr-10" onClick={() => handleOpenModalEditArticle(data[i])} />
          <Popover placement="topLeft" title={"Delete"} content={contentDelete} trigger="click">
            <DeleteOutlined className="icon icon_edit mr-10" />
          </Popover>
          <Popover placement="topLeft" title={"Duplicate"} content={content} trigger="click">
            <EllipsisOutlined className="icon icon_edit mr-10" />
          </Popover>
        </div>)
      }

      auxTableData.push(aux);
    }

    setTableData(auxTableData);
  }

  function handleOpenModalCreateArticle() {
    setIsOpenCreateArticle(true)
  }

  function handleCloseModalCreateArticle() {
    setIsOpenCreateArticle(false);
    handleGetArticles();
  }

  function handleOpenModalEditArticle(data) {
    setSelectedArticle(data)
    setIsOpenEditArticle(true);
  }

  function handleCloseModalEditArticle() {
    setIsOpenEditArticle(false);
    setSelectedArticle({})
    handleGetArticles();
  }

  function handleSearch(e) {
    let searchString = e.target.value;
    let searchData = articles.filter((item) => item.name.includes(searchString));
    handlePrepareTableData(searchData);
  }

  return (
    <div className='dashboard_allergies'>
      <DrawerAddArticle tags={tags} open={isOpenCreateArticle} close={handleCloseModalCreateArticle} lang={lang} />
      <DrawerEditArticle data={selectedArticle} tags={tags} open={isOpenEditArticle} close={handleCloseModalEditArticle} lang={lang} />
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={16}>
              <h1 className="mt-0 mb-0">Articles</h1>
            </Col>
            <Col span={8} className="d-flex jc-end ai-center">
              <Input size='large' onChange={handleSearch} className='mr-10' placeholder='Search...' />
              <Button size='large' onClick={handleOpenModalCreateArticle}>Adicionar</Button>
            </Col>
          </Row>
          <Table dataSource={tableData} columns={columns} />
        </Col>
      </Row>
    </div>
  );
}

export default DashboardAllergies;
