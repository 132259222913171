import { Breadcrumb, Layout, Input, Form, Row, Col, Button, Tabs, Switch, ColorPicker, InputNumber, notification, Spin } from 'antd';
import { useState, useEffect } from 'react';
import {
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Link } from 'react-router-dom';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import pretty from 'pretty';
import Editor from '../../../../components/dashboard/Editor/Editor';
import EditorCSS from '../../../../components/dashboard/EditorCSS/EditorCSS';
import '../DiagnosticAndTreatment.css';
import config from '../../../../utils/config';
import endpoints from '../../../../utils/endpoints';
import RelatedContentDashboardTreatment from './RelatedContentDashboardTreatment';
import PageDashboardTreatment from './PageDashboardTreatment';

const { useForm } = Form;

const defaultContentObject = {
  full_width_parent: 1,
  full_width: 0,
  background: 0,
  backgroundColor: "#000",
  backgroundImage: null,
  paddingTop: null,
  paddingBottom: null,
  marginTop: null,
  marginBottom: null,
  editor: {
    time: 1687787067474,
    blocks: [
      {
        id: "ci2MsAeULf",
        type: "header",
        data: {
          text: "Some new content",
          level: 1
        }
      },
    ],
    version: "2.27.0"
  }
}

const DashboardDiagnostic = ({ lang }) => {
  return (
    <Row>
      <Col span={24} className='dashboard_card'>
        <Tabs
          type="card"
          items={[
            {
              key: 1,
              label: `Page`,
              children:
                <PageDashboardTreatment lang={lang} />
            },
            {
              key: 2,
              label: `Related Content`,
              children:
                <RelatedContentDashboardTreatment lang={lang} />
            },
          ]}
        />
      </Col>
    </Row>
  )
};
export default DashboardDiagnostic;