import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Form, Input, notification, Spin } from 'antd';
import axios from 'axios';
import config from '../../../utils/config';
import logoCura from '../../../assets/images/logo-curatualergia.svg';
import './Login.css';
import endpoints from '../../../utils/endpoints';


function Login({ handleLogin }) {

  const navigate = useNavigate();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let tokenData = localStorage.getItem('token');
    if (tokenData) {
      axios.post(endpoints.auth.verifyToken, {
        data: tokenData
      }).then((res) => {
        return navigate('/admin/allergies/');
      }).catch((err) => {
        setTimeout(() => {
          console.log(err);
          setIsLoading(false);
        }, 1000);
      })
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, []);

  const onFinish = (values) => {
    axios.post(endpoints.auth.login, {
      data: { email: values.email, password: values.password }
    }).then((res) => {
      if (res.data.user_exists) {
        if (res.data.user_password) {
          notification.success({
            message: `Bem-vindo ${res.data.data.name}`,
            description: 'O seu login foi feito com sucesso.',
            duration: 1.5
          });
          localStorage.setItem('token', res.data.token);
          handleLogin(res.data.data);
          navigate('/admin');
        } else {
          notification.error({
            message: `Password errada`,
            description: "A password que está associada ao e-mail inserido não " +
              "corresponde àquela que colocou, por favor verifique se está a colocar a password correta",
            duration: 1.5
          });
          setIsButtonLoading(false);
        }
      } else {
        notification.error({
          message: `Utilizador errado`,
          description: "Não existe nenhum utilizador com este e-mail. Verifique se está a colocar o e-mail correto.",
          duration: 1.5
        });
        setIsButtonLoading(false);
      }
    }).catch((err) => {
      console.log(err);
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="login page">
      {isLoading ? 
      <Spin spinning={isLoading}></Spin>
      :
        <Row className='container d-flex jc-center ai-center'>
          <Col span={10} className='login_box'>
            <img src={logoCura} className='img_login' />
            <Row className='w-100'>
              <Col span={24} className='login_form'>
                <Form
                  name="basic"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input size='large' placeholder="master@example.com" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                  >
                    <Input.Password size='large' placeholder='*******' />
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 24 }} className='d-flex jc-center'>
                    <Button loading={isButtonLoading} type="primary" htmlType="submit" size='large'>
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    </div>
  );
}

export default Login;
