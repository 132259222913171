import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover, Tabs } from 'antd';
import { ArrowRightOutlined, CopyOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import Diagnostic from './Diagnostic/DashboardDiagnostic';
import Treatment from './Treatment/DashboardTreatment';

import './DiagnosticAndTreatment.css';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';

function DiagnosticAndTretment({ lang }) {
  return (
    <div className='dashboard_manifestations'>
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={12}>
              <h1 className="mt-0 mb-0">Diagnóstico e Tratamento</h1>
            </Col>
          </Row>
          <Tabs
            type="card"
            items={[
              {
                key: 1,
                label: `Diagnostic`,
                children: <Diagnostic lang={lang} />,
              },
              {
                key: 2,
                label: `Treatment`,
                children: <Treatment lang={lang} />,
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
}

export default DiagnosticAndTretment;
