import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin } from 'antd';
import axios from 'axios';
import { ArrowRightOutlined } from '@ant-design/icons';

import Seo from '../../../components/website/Seo/Seo';
import config from '../../../utils/config';

import imageBanner from '../../../assets/images/img-header-pareja-laptop.jpg'
import imageMapa from '../../../assets/icons/icon-mapa-polinico.svg'
import imageMapaPt from '../../../assets/icons/icon-mapa-polinico-pt.svg'
import imageArticles from '../../../assets/icons/icon-sabias-que.svg'
import imageConsejosIcon from '../../../assets/icons/icon-conejos-padres.svg'
import imageTestimonialIcon from '../../../assets/icons/icon-testimoniales.svg'
import imagePodcasts from '../../../assets/icons/icon-podcasts.svg'
import imageVideos from '../../../assets/icons/icon-videos.svg'
import imageImages from '../../../assets/icons/icon-imagenes.svg'
import imageAllergicKit from '../../../assets/icons/icon-kit-alergico.svg'
import imageEscucha from '../../../assets/images/thumb-escucha-alergologo-podcasts.jpg'
import imageTestimonial from '../../../assets/images/testimonial-montse-jandula-alergia-acaros-polenes.jpg'
import imageConsejos from '../../../assets/images/thumb-consejos-padres-ninos-alergicos.jpg'

import './Resources.css';
import i18next from 'i18next';
import endpoints from '../../../utils/endpoints';


function Resources({ lang }) {

  const [allergies, setAllergies] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetAllergies();
  }, []);

  function handleGetAllergies() {
    axios.get(endpoints.allergies.getAllergies).then((res) => {
      setAllergies(res.data);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e)
    })
  }

  function handleClickMap() {
    if (lang === 'es') {
      navigate(`/es/recursos/mapa-polinico`)
    } else {
      window.open('https://www.rpaerobiologia.com/previsao-polinica/lisboa')
    }
  }

  return (
    <Spin spinning={isLoading}>
      <div className="allergies page">
        <Seo title={i18next.t('home.resources.title')} description={i18next.t('home.resources.title')} />
        <Row className='full_container allergies_banner'>
          <div style={{ backgroundImage: `url(${imageBanner})` }}></div>
        </Row>
        <Row gutter={[24]} className='container d-flex flex-column'>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <h1 className='allergies_title mb-10'>{i18next.t('home.resources.title')}</h1>
            <hr className='title_divider' />
            <p className='mt-30'>{i18next.t('home.resources.subtitle')}</p>
          </Col>
        </Row>
        <Row gutter={[24]} className='container p-5-mobile'>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={handleClickMap}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="100">
              <img className="maxh-70" src={(lang === 'es') ? imageMapa : imageMapaPt} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('menu.map')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => navigate(`/${lang}/recursos/sabias-que`)}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="200" >
              <img className="maxh-70" src={imageArticles} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('menu.articles')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => navigate(`/${lang}/recursos/${lang === "es" ? 'consejos-para-padres' : 'conselhos-para-pais'}/${lang === "es" ? 'la-alergia-ninos' : 'alergia-criancas'}`)}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="300">
              <img className="maxh-70" src={imageConsejosIcon} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('menu.advices')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => navigate(`/${lang}/recursos/${lang === "es" ? 'kit-del-paciente-alergico' : 'kit-do-paciente-alergico'}`)}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="300">
              <img className="maxh-70" src={imageAllergicKit} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('home.resources.allergic_kit')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => navigate(`/${lang}/recursos/${lang === "es" ? 'testimoniales' : 'testemunhos'}`)}>
            <div className='allergies_card' data-aos="fade-left" data-aos-delay="400">
              <img className="maxh-70" src={imageTestimonialIcon} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('menu.testimonials')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => navigate(`/${lang}/recursos/podcasts`)}>
            <div className='allergies_card' data-aos="fade-left" data-aos-delay="300">
              <img className="maxh-70" src={imagePodcasts} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('home.resources.allergist')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => navigate(`/${lang}/${lang === "es" ? 'imagenes' : 'imagens'}`)}>
            <div className='allergies_card' data-aos="fade-left" data-aos-delay="200">
              <img className="maxh-70" src={imageImages} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('images_videos.images')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} onClick={() => navigate(`/${lang}/videos`)}>
            <div className='allergies_card' data-aos="fade-left" data-aos-delay="200">
              <img className="maxh-70" src={imageVideos} />
              <p className='white f-20 text-uppercase mt-10 mb-10 text-center'>{i18next.t('images_videos.videos')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}>
          </Col>
        </Row>


        <Row gutter={[24]} className='container'>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className='mb-30'>
            <div className='d-flex flex-column'
              onClick={() => navigate(`/${lang}/recursos/${lang === 'es' ? 'testimoniales' : 'testemunhos'}`)}>
              <h2 className='m-0'>{i18next.t('our_patients.title')}</h2>
              <p className='w-75 mobile-w-100'>{i18next.t('our_patients.body_1')}</p>
              <img className="hover-cursor w-100" src={imageTestimonial} />
              <Button
                onClick={() => navigate(`/${lang}/recursos/${lang === 'es' ? 'testimoniales' : 'testemunhos'}`)}
                className='mt-0 bg-blue white w-100' size='large'>{i18next.t('our_patients.more_testimonials')}</Button>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <h2 className='m-0'><b>{i18next.t('related_content.title')}</b></h2>
            <p className='w-75 mobile-w-100'>{i18next.t('related_content.subtitle')}</p>
            <div className='d-flex jc-center ai-center p-40 hover-cursor bg-center bg-cover' style={{ backgroundImage: `url(${imageConsejos})` }}
              onClick={() => navigate(`/${lang}/recursos/${lang === 'es' ? 'consejos-para-padres' : 'conselhos-para-pais'}/${lang === 'es' ? 'la-alergia-ninos' : 'alergia-criancas'}`)}>
              <p className='f-24 white text-center bold'>{i18next.t('related_content.fathers')}</p>
            </div>
            <div className='d-flex jc-center ai-center p-40 hover-cursor mt-20 bg-center bg-cover' style={{ backgroundImage: `url(${imageEscucha})` }}
              onClick={() => navigate(`/${lang}/recursos/podcasts`)}>
              <p className='f-24 white text-center bold'>{i18next.t('related_content.allergist')}</p>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default Resources;
