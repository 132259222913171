import { Modal, Form, Input, Upload, Row, Col, Drawer, Button, notification, InputNumber } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios'
import './DrawerAddAllergy.css';
import config from '../../../utils/config';
import { useState } from 'react';
import endpoints from '../../../utils/endpoints';

const { useForm } = Form;
const { Dragger } = Upload;

export default function DrawerAddAllergy({ open, close, lang }) {

  const [form] = useForm();

  const [fileList, setFileList] = useState([]);
  const [fileListBanner, setFileListBanner] = useState([]);
  const [filePreview, setFilePreview] = useState('');
  const [fileBannerPreview, setFileBannerPreview] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const uploadProps = {
    accept: 'image/*',
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview('');
    },
    beforeUpload: (file) => {
      handlePreview(file, 'image');
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: []
  };

  const uploadPropsBanner = {
    accept: 'image/*',
    onRemove: (file) => {
      const index = fileListBanner.indexOf(file);
      const newFileList = fileListBanner.slice();
      newFileList.splice(index, 1);
      setFileListBanner(newFileList);
      setFileBannerPreview('');
    },
    beforeUpload: (file) => {
      handlePreview(file, 'banner');
      setFileListBanner([file]);
      return false;
    },
    fileList: fileListBanner,
    defaultFileList: []
  };

  function handleSubmit(values) {
    setIsButtonLoading(true);
    values.lang = lang;

    let formData = new FormData();

    fileList.forEach((file) => {
      formData.append('file', file);
    });

    fileListBanner.forEach((file) => {
      formData.append('file_banner', file);
    });

    formData.append('data', JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.allergies.createAllergy}`, {
      method: "POST",
      body: formData
    }).then((res) => {
      setTimeout(() => {
        close();
        setFileList([]);
        setFileListBanner([]);
        setFilePreview('');
        setFileBannerPreview('');
        form.setFieldsValue({});
        notification.success({
          message: 'Well done!',
          description:
            'Allergy was added with success!'
        })
        setIsButtonLoading(false);
      }, 1500);
    }).catch((error) => {
      console.log(error);
      setIsButtonLoading(false);
      notification.error({
        message: 'Oh no!',
        description:
          'Some error happened, please try again later!'
      })
    });
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    if (type === "banner") {
      setFileBannerPreview(filePreview)
    } else {
      setFilePreview(filePreview)
    }
  };


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  return (
    <Drawer
      className='edit_allergies'
      open={open}
      onClose={handleClose}
      width={1000}
      title="Create Allergy"
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Row gutter={[24]}>
          <Col span={8}>
            <Form.Item
              name="file_img"
              label="Image"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <Dragger {...uploadProps} className={`dragger ${filePreview ? 'not_show' : ''}`}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                  banned files.
                </p>
                <div
                  className={`preview_file ${filePreview ? '' : 'not_show'}`}
                  style={{ backgroundImage: `url(${(filePreview && filePreview.includes('base64')) ? filePreview : `${config.server_ip}assets/media/${filePreview}`})` }}
                >
                </div>
              </Dragger>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name="name"
              label="Nome"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <Input size='large' placeholder='Nome'></Input>
            </Form.Item>
            <Row gutter={[24]}>
              <Col span={12}>
                <Form.Item
                  name="menu_name"
                  label="Nome menu"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório'
                    }
                  ]}
                >
                  <Input size='large' placeholder='Nome menu'></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="menu_position"
                  label="Posição do menu"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório'
                    }
                  ]}
                >
                  <InputNumber size='large' placeholder='Posição do menu' className='w-100' />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="slug"
              label="Slug"
              rules={[
                {
                  required: true,
                  message: 'Este campo é obrigatório'
                }
              ]}
            >
              <Input size='large' placeholder='Nome'></Input>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="img_alt"
          label="Image Alt"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input size='large' placeholder='Image alt'></Input>
        </Form.Item>
        <Form.Item
          name="file_banner"
          label="Banner"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Dragger {...uploadPropsBanner} className={`dragger ${fileBannerPreview ? 'not_show' : ''}`}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from uploading company data or other
              banned files.
            </p>
            <div
              className={`preview_file ${fileBannerPreview ? '' : 'not_show'}`}
              style={{ backgroundImage: `url(${(fileBannerPreview && fileBannerPreview.includes('base64')) ? fileBannerPreview : `${config.server_ip}assets/media/${fileBannerPreview}`})` }}
            >
            </div>
          </Dragger>
        </Form.Item>
        <Form.Item
          name="banner_alt"
          label="Banner Alt"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input size='large' placeholder='Banner alt'></Input>
        </Form.Item>

        <Col span={24} className='d-flex jc-center w-100'>
          <Form.Item wrapperCol={{ span: 24 }} className='d-flex jc-center ai-center'>
            <Button type="primary" htmlType="submit" size='large' loading={isButtonLoading} disabled={isButtonLoading}>
              Save
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </Drawer>
  )
};