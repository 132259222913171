import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin } from 'antd';
import axios from 'axios';
import { ArrowRightOutlined } from '@ant-design/icons';

import Seo from '../../../components/website/Seo/Seo';
import config from '../../../utils/config';

import imageBanner from '../../../assets/images/imagenes-videos-mano-tecnologia.jpg'
import imageMapa from '../../../assets/icons/icon-mapa-polinico.svg'
import imageArticles from '../../../assets/icons/icon-sabias-que.svg'
import imageConsejosIcon from '../../../assets/icons/icon-conejos-padres.svg'
import imageTestimonialIcon from '../../../assets/icons/icon-testimoniales.svg'
import imagePodcasts from '../../../assets/icons/icon-podcasts.svg'
import imageVideos from '../../../assets/icons/icon-videos.svg'
import imageEscucha from '../../../assets/images/thumb-escucha-alergologo-podcasts.jpg'
import imageTestimonial from '../../../assets/images/testimonial-montse-jandula-alergia-acaros-polenes.jpg'
import imageConsejos from '../../../assets/images/thumb-consejos-padres-ninos-alergicos.jpg'

import './Videos.css';
import i18next from 'i18next';
import endpoints from '../../../utils/endpoints';


function Videos({ lang }) {

  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [itemsPerLoad, setItemsPerLoad] = useState(6);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(endpoints.images_videos.getImagesVideos, {
      params: { lang }
    }).then((res) => {
      let videos = res.data.filter((item) => item.type === 'video');
      setVideos(videos);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    })
  }, []);

  function handleLoadMore() {
    setIsButtonLoading(true);
    setIsLoading(true);
    setTimeout(() => {
      setItemsPerLoad(itemsPerLoad + 6)
    }, 500)
    setTimeout(() => {
      setIsButtonLoading(false);
      setIsLoading(false);
    }, 1000)
  }

  return (
    <Spin spinning={isLoading}>
      <div className="allergies page">
        <Seo title={i18next.t('menu.videos')} description={(lang === 'es' ? 'Imágenes y vídeos sobre alergia' : 'Imagens e vídeos sobre alergia')} />
        <Row className='full_container allergies_banner'>
          <div title={(lang === 'es' ? 'Imágenes y vídeos sobre alergia' : 'Imagens e vídeos sobre alergia')} style={{ backgroundImage: `url(${imageBanner})` }}></div>
        </Row>
        <Row gutter={[24]} className='container d-flex flex-column'>
          <Col span={18}>
            <h1 className='allergies_title mb-10'>{i18next.t('menu.videos')}</h1>
            <hr className='title_divider' />
          </Col>
        </Row>
        <Row gutter={[35, 35]} className='container'>
          {videos.slice(0, itemsPerLoad).map((item, index) => {
            return (
              <>
                <Col xs={24} xl={8}>
                  <iframe height="250" width="100%"
                    src={item.iframe}
                    frameBorder="0"
                  >
                  </iframe>
                  <p className='bold blue f-20 mt-10 mb-5'>{item.name}</p>
                  <p className='blue f-16 m-0'>{item.description}</p>
                </Col>
              </>
            )
          })
          }
        </Row>

        {itemsPerLoad < videos.length ?
          <Row gutter={[24]} className='mb-40 mt-20'>
            <Col span={24}>
              <Button size='large' type='primary' loading={isButtonLoading} onClick={handleLoadMore}>{i18next.t('home.articles.load_more')}</Button>
            </Col>
          </Row>
          :
          null
        }</div>
    </Spin>
  );
}

export default Videos;
