import { Button, Checkbox, Col, Drawer, Form, Input, Layout, List, Modal, Row } from 'antd';
import {
  Outlet,
} from "react-router-dom";
import './ModalPrivacyWarning.css';
import { Children, useState } from 'react';
import imageNewsletter from '../../../assets/images/img-newsletter.png'
import i18next from 'i18next';
import axios from 'axios';

const { Content } = Layout;

export default function ModalPrivacyWarning({ lang, open, close }) {

  return (
    <Modal
      open={open}
      onCancel={close}
      className='modal_newsletter'
      footer={[]}
    >
      <Row gutter={[24]} className='mr-0 ml-0'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='pl-20 pr-20 pt-20 pb-0 mobile-padding'>
          <p className='f-24 bold m-0'>{i18next.t('privacy_warning.title')}</p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='pl-20 pr-20 pt-20 pb-50 mobile-padding'>
          <div className='p-20 bg-blue'>
            <p className='white m-0'>{i18next.t('privacy_warning.subtitle')}</p>
          </div>
          <Row gutter={[24]} className='ml-0 mt-10'>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className='bg-grey pt-10 pb-10 d-flex ai-center'>
              <p className='m-0 blue bold'>{i18next.t('privacy_warning.topic_1')}</p>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className='pt-10 pb-10 mt-10 d-flex ai-center'>
              <p className='m-0'>{i18next.t('privacy_warning.text_1')}</p>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} className='bg-grey pt-10 pb-10 mt-10 d-flex ai-center'>
              <p className='m-0 blue bold'>{i18next.t('privacy_warning.topic_2')}</p>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className='pt-10 pb-10 mt-10 d-flex ai-center'>
              <p className='m-0'>{i18next.t('privacy_warning.text_2')}</p>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} className='bg-grey pt-10 pb-10 mt-10 d-flex ai-center'>
              <p className='m-0 blue bold'>{i18next.t('privacy_warning.topic_3')}</p>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className='pt-10 pb-10 mt-10 d-flex ai-center'>
              <p className='m-0'>{i18next.t('privacy_warning.text_3')}</p>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} className='bg-grey pt-10 pb-10 mt-10 d-flex ai-center'>
              <p className='m-0 blue bold'>{i18next.t('privacy_warning.topic_4')}</p>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className='pt-10 pb-10 mt-10 d-flex ai-center'>
              <p className='m-0'>{i18next.t('privacy_warning.text_4')}</p>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} className='bg-grey pt-10 pb-10 mt-10 d-flex ai-center'>
              <p className='m-0 blue bold'>{i18next.t('privacy_warning.topic_5')}</p>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className='pt-10 pb-10 mt-10 d-flex ai-center'>
              <p className='m-0'>{i18next.t('privacy_warning.text_5')}</p>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} className='bg-grey pt-10 pb-10 mt-10 d-flex ai-center'>
              <p className='m-0 blue bold'>{i18next.t('privacy_warning.topic_6')}</p>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className='pt-10 pb-10 mt-10 d-flex ai-center'>
              <div dangerouslySetInnerHTML={{__html: i18next.t('privacy_warning.text_6') }}></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
};