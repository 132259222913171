import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover, Input } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import './Tags.css';
import ModalAddTag from '../../../components/dashboard/ModalAddTag/ModalAddTag';
import ModalEditTag from '../../../components/dashboard/ModalEditTag/ModalEditTag';
import config from '../../../utils/config'
import endpoints from '../../../utils/endpoints';

const columns = [
  {
    title: '#',
    dataIndex: 'key',
    key: 'key',
    width: '20px'
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];


function Tags({ lang }) {

  const [tableData, setTableData] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState({});
  const [isOpenModalCreateTag, setIsOpenModalCreateTag] = useState(false);
  const [isOpenModalEditTag, setIsOpenModalEditTag] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetTags();
  }, [lang]);

  function handleGetTags() {
    axios.get(endpoints.tags.getTags, {
      params: { lang }
    }).then((res) => {
      setTags(res.data);
      handlePrepareTableData(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDeleteTag(id) {
    axios.post(endpoints.tags.deleteTag, {
      data: { id, lang }
    }).then((res) => {
      handleGetTags();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDuplicateToOtherLang(item) {
    axios.post(endpoints.tags.duplicateTag, {
      data: { item, lang: lang === "pt" ? "es" : "pt" }
    }).then((res) => {
      handleGetTags();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDeleteTag(item) {
    axios.post(endpoints.tags.deleteTag, {
      data: { item }
    }).then((res) => {
      handleGetTags();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handlePrepareTableData(data) {
    let auxTableData = [];

    for (let i = 0; i < data.length; i++) {

      const content = (
        <div>
          <p>Want to duplicate to {lang === "pt" ? "ES" : "PT"}?</p>
          <Button type="primary" onClick={() => handleDuplicateToOtherLang(data[i])}>Duplicate</Button>
        </div>
      );

      const contentDelete = (
        <div>
          <p>Want to delete {data[i].name}?</p>
          <Button type="primary" onClick={() => handleDeleteTag(data[i])}>Delete</Button>
        </div>
      );

      let aux = {
        key: data[i].id,
        name: data[i].name,
        actions: (<div className='icons_table'>
          <EditOutlined className="icon icon_edit mr-10" onClick={() => handleOpenModalEditTag(data[i])} />
          <Popover placement="topLeft" title={"Delete"} content={contentDelete} trigger="click">
            <DeleteOutlined className="icon icon_edit mr-10" />
          </Popover>
          <Popover placement="topLeft" title={"Duplicate"} content={content} trigger="click">
            <EllipsisOutlined className="icon icon_edit mr-10" />
          </Popover>
        </div>)
      }

      auxTableData.push(aux);
    }

    setTableData(auxTableData);
  }

  function handleOpenModalCreateTag() {
    setIsOpenModalCreateTag(true);
  }

  function handleCloseModalCreateTag() {
    setIsOpenModalCreateTag(false);
    handleGetTags();
  }

  function handleOpenModalEditTag(data) {
    setSelectedTag(data)
    setIsOpenModalEditTag(true);
  }

  function handleCloseModalEditTag() {
    setIsOpenModalEditTag(false);
    handleGetTags();
  }

  function handleSearch(e) {
    let searchString = e.target.value;
    let searchData = tags.filter((item) => item.name.includes(searchString));
    handlePrepareTableData(searchData);
  }

  return (
    <div className='dashboard_allergies'>
      <ModalAddTag open={isOpenModalCreateTag} close={handleCloseModalCreateTag} lang={lang} />
      <ModalEditTag data={selectedTag} open={isOpenModalEditTag} close={handleCloseModalEditTag} lang={lang} />
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={16}>
              <h1 className="mt-0 mb-0">Tags</h1>
            </Col>
            <Col span={8} className="d-flex jc-end ai-center">
              <Input size='large' onChange={handleSearch} className='mr-10' placeholder='Search...' />
              <Button size='large' onClick={handleOpenModalCreateTag}>Adicionar</Button>
            </Col>
          </Row>
          <Table dataSource={tableData} columns={columns} />
        </Col>
      </Row>
    </div>
  );
}

export default Tags;
