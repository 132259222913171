import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Row, Col, Image } from 'antd';
import axios from 'axios';
import './Media.css';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';

const { Dragger } = Upload;

function Media() {

  const [media, setMedia] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetMedia();
  }, []);

  function handleGetMedia() {
    axios.get(endpoints.media.getMedia).then((res) => {
      setMedia(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  const props = {
    name: 'file',
    multiple: true,
    action: `${config.server_ip}${endpoints.media.uploadMedia}`,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        handleGetMedia();
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      console.log(file);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <div className="dashboard_media">
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={12}>
              <h1 className="mt-0 mb-0">Media</h1>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                  banned files.
                </p>
              </Dragger>
            </Col>
          </Row>
          <Row className='media_row mt-40' gutter={[24, 24]}>
            <Image.PreviewGroup
              preview={{
                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              {media.map((item) => (
                <Col span={2}>
                  <Image
                    width={'100%'}
                    src={`${config.server_ip}assets/media/${item.name}`}
                  />
                </Col>
              ))}
            </Image.PreviewGroup>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Media;
