import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover, Input } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import ModalAddGlossary from '../../../components/dashboard/ModalAddGlossary/ModalAddGlossary';
import ModalEditGlossary from '../../../components/dashboard/ModalEditGlossary/ModalEditGlossary';
import config from '../../../utils/config'
import endpoints from '../../../utils/endpoints';
import './Glossary.css';

const columns = [
  {
    title: 'Letter',
    dataIndex: 'letter',
    key: 'letter',
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];


function Glossary({ lang }) {

  const [tableData, setTableData] = useState([]);
  const [glossary, setGlossary] = useState([]);
  const [selectedGlossary, setSelectedGlossary] = useState({});
  const [isOpenModalCreateGlossary, setIsOpenModalCreateGlossary] = useState(false);
  const [isOpenModalEditGlossary, setIsOpenModalEditGlossary] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetGlossary();
  }, [lang]);

  function handleGetGlossary() {
    axios.get(endpoints.glossary.getGlossary, {
      params: { lang }
    }).then((res) => {
      setGlossary(res.data);
      handlePrepareTableData(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDeleteGlossary(id) {
    axios.post(endpoints.glossary.deleteGlossary, {
      data: { id, lang }
    }).then((res) => {
      handleGetGlossary();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDuplicateToOtherLang(item) {
    axios.post(endpoints.glossary.duplicateGlossary, {
      data: { item, lang: lang === "pt" ? "es" : "pt" }
    }).then((res) => {
      handleGetGlossary();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDeleteGlossary(item) {
    axios.post(endpoints.glossary.deleteGlossary, {
      data: { item }
    }).then((res) => {
      handleGetGlossary();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handlePrepareTableData(data) {
    let auxTableData = [];

    for (let i = 0; i < data.length; i++) {

      const content = (
        <div>
          <p>Want to duplicate to {lang === "pt" ? "ES" : "PT"}?</p>
          <Button type="primary" onClick={() => handleDuplicateToOtherLang(data[i])}>Duplicate</Button>
        </div>
      );

      const contentDelete = (
        <div>
          <p>Want to delete {data[i].name}?</p>
          <Button type="primary" onClick={() => handleDeleteGlossary(data[i])}>Delete</Button>
        </div>
      );

      let aux = {
        key: i,
        letter: data[i].letter,
        name: data[i].name,
        description: data[i].description,
        actions: (<div className='icons_table'>
          <EditOutlined className="icon icon_edit mr-10" onClick={() => handleOpenModalEditGlossary(data[i])} />
          <Popover placement="topLeft" title={"Delete"} content={contentDelete} trigger="click">
            <DeleteOutlined className="icon icon_edit mr-10" />
          </Popover>
        </div>)
      }

      auxTableData.push(aux);
    }

    setTableData(auxTableData);
  }

  function handleOpenModalCreateGlossary() {
    setIsOpenModalCreateGlossary(true);
  }

  function handleCloseModalCreateGlossary() {
    setIsOpenModalCreateGlossary(false);
    handleGetGlossary();
  }

  function handleOpenModalEditGlossary(data) {
    setSelectedGlossary(data)
    setIsOpenModalEditGlossary(true);
  }

  function handleCloseModalEditGlossary() {
    setIsOpenModalEditGlossary(false);
    handleGetGlossary();
  }

  function handleSearch(e) {
    let searchString = e.target.value;
    let searchData = glossary.filter((item) => item.name.includes(searchString));
    handlePrepareTableData(searchData);
  }

  return (
    <div className='dashboard_allergies'>
      <ModalAddGlossary open={isOpenModalCreateGlossary} close={handleCloseModalCreateGlossary} lang={lang} />
      <ModalEditGlossary data={selectedGlossary} open={isOpenModalEditGlossary} close={handleCloseModalEditGlossary} lang={lang} />
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={16}>
              <h1 className="mt-0 mb-0">Glossary</h1>
            </Col>
            <Col span={8} className="d-flex jc-end ai-center">
              <Input size='large' onChange={handleSearch} className='mr-10' placeholder='Search...' />
              <Button size='large' onClick={handleOpenModalCreateGlossary}>Adicionar</Button>
            </Col>
          </Row>
          <Table dataSource={tableData} columns={columns} />
        </Col>
      </Row>
    </div>
  );
}

export default Glossary;
