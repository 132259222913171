import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { Button, Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import { Helmet } from 'react-helmet';
import Seo from '../../../components/website/Seo/Seo';
import axios from 'axios';
import endpoints from '../../../utils/endpoints';

import './Search.css';
import i18next from 'i18next';


function Search({ lang }) {

  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [totalResults, setTotalResults] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    handleGetArticles();
  }, [searchParams]);

  function handleGetArticles() {
    // ALERGIAS - MANIFESTACOES - DIAGNOSTICO TRATAMENTO - RECURSOS - GLOSSARIO - LINKS
    let searchString = searchParams.get("s");
    axios.get(endpoints.pagesInfo.getSearch, {
      params: { search: searchString, lang: lang }
    }).then((res) => {
      handlePrepareData(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handlePrepareData(object) {
    let columns = Object.keys(object);
    let auxData = {};

    let totalResultsAux = 0;

    for (let i = 0; i < columns.length; i++) {
      if (object[columns[i]].length > 0) {
        totalResultsAux += object[columns[i]].length;
        auxData = { ...auxData, [columns[i]]: object[columns[i]] };
      }
    }

    setData(auxData);
    setTotalResults(totalResultsAux);
  }

  function handleNavigate(column, item) {
    switch (column) {
      case 'allergicKit':
        navigate(`/${lang}/recursos/${lang === 'es' ? 'kit-del-paciente-alergico' : 'kit-do-paciente-alergico'}`);
        break;
      case 'allergies':
        navigate(`/${lang}/tipos-de-alergia/${item.slug}/sintomas-causas`);
        break;
      case 'manifestations':
        navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${item.slug}`);
        break;
      case 'articles':
        navigate(`/${lang}/recursos/sabias-que/${item.slug}`);
        break;
    }
  }

  return (
    <div className="page">
      <Seo
        title="Search"
        description="Search page"
      />
      <Row className='w-100 container'>
        <Col span={24}>
          <p className='text-center f-20 italic mb-10'>Resultados:</p>
          <p className='text-center f-60 bold m-0'>{totalResults}</p>
        </Col>
        <Divider />
        <Col span={24}>
          {Object.keys(data).map((column, index) =>
            <div className='d-flex flex-column'>
              {index === 0 ?
                <p className='f-30 bold'>{i18next.t(`search.${column}`)}</p>
                :
                (i18next.t(`search.${column}`) !== i18next.t(`search.${Object.keys(data)[index - 1]}`) ?
                  <>
                    <hr className='minh-2 maxh-2 bg-green green border-0 w-100 mt-40' />
                    <p className='f-30 bold mt-30'>{i18next.t(`search.${column}`)}</p>
                  </>
                  :
                  null)
              }
              {data[column].map((item, i) => {
                return (<div className='d-flex flex-column' onClick={() => handleNavigate(column, item)}>
                  <p className={`f-20 bold mb-0 ${i === 0 ? 'mt-10' : 'mt-30'}`}>{item.name ? item.name : (item.title ? item.title : item.seo_title)}</p>
                  {column === 'articles' ?
                    <div className='mt-5 description ellipsis' dangerouslySetInnerHTML={{ __html: item.content.split('</p>')[0] }}></div>
                    :
                    column === 'diagnosticTreatment' ?
                      <>
                        <p className='f-20 mt-5 bold mb-0'>{item.type === 'treatment' ? 'Tratamento' : 'Diagnostico'}</p>
                        <p className='mt-5'>{item.seo_description ? item.seo_description : item.description}</p>
                      </>
                      :
                      <p className='mt-5'>{item.seo_description ? item.seo_description : item.description}</p>
                  }
                </div>
                )
              })}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Search;
