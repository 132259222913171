import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin, Typography, Collapse, Select, Form, Input, Checkbox } from 'antd';
import { ArrowRightOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import i18next from 'i18next';
import config from '../../../utils/config';
import Seo from '../../../components/website/Seo/Seo';
import endpoints from '../../../utils/endpoints';
import bannerImage from '../../../assets/images/img-header-pareja-laptop.jpg'

import './LinksOfInterest.css';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;


function LinksOfInterest({ lang }) {

  const [data, setData] = useState([]);

  useEffect(() => {
    handleGetLinks();
  }, []);

  function handleGetLinks() {
    axios.get(endpoints.linksOfInterest.getLinks, {
      params: { lang }
    }).then((res) => {
      let aux = [];
      let auxData = res.data;

      for (let i = 0; i < auxData.length; i++) {
        auxData[i].links = JSON.parse(auxData[i].links);
        aux.push(auxData[i]);
      }

      setData(aux);
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <div className="allergies_details page">
      <Seo title={i18next.t('footer.links_of_interest')} description={i18next.t('footer.links_of_interest')} />
      <Row className='full_container allergies_banner'>
        <div style={{ backgroundImage: `url(${bannerImage})` }}></div>
      </Row>
      <Row className='container d-flex flex-column'>
        <h1 className='allergies_title mb-10'>{i18next.t('footer.links_of_interest')}</h1>
        <hr className='title_divider' />
      </Row>
      <Row className='container'>
        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
          {data.map((item, index) => {
            return (
              <>
                <div className='d-flex flex-column'>
                  <p className='bold'>{item.name}</p>
                  {item.links.map((link) => {
                    return <a className='blue f-16 mb-5' href={link.link} target='_blank'>{link.title}</a>
                  })}
                </div>
                {index < (data.length - 1) &&
                  <Divider className='blue-divider' />
                }
              </>
            )
          })}
        </Col>
      </Row>
    </div>
  );
}

export default LinksOfInterest;
