import { Modal, Form, Input, Upload, Row, Col, Drawer } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios'
import './ModalAddTag.css';
import { useState } from 'react';
import config from '../../../utils/config';
import endpoints from '../../../utils/endpoints';

const { useForm } = Form;

export default function ModalAddTag({ open, close, lang }) {

  const [form] = useForm();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios.post(endpoints.tags.createTag, {
      data: { values, lang }
    }).then((res) => {
      setTimeout(() => {
        close();
        form.resetFields();
        setIsButtonLoading(false);
      }, 1500);
    }).catch((error) => {
      console.log(error);
      setIsButtonLoading(false);
    });
  }

  function handleClose(){
    form.resetFields();
    close();
  }

  return (
    <Modal
      className='edit_allergies'
      open={open}
      onOk={form.submit}
      onCancel={handleClose}
      title="Create Tag"
      okText={"Guardar"}
      cancelText={"Cancelar"}
      okButtonProps={{ loading: isButtonLoading }}
      cancelButtonProps={{ disabled: isButtonLoading }}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório'
            }
          ]}
        >
          <Input size='large' placeholder='Nome...'></Input>
        </Form.Item>
      </Form>
    </Modal>
  )
};