import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin } from 'antd';
import axios from 'axios';
import { ArrowRightOutlined } from '@ant-design/icons';

import Seo from '../../../components/website/Seo/Seo';
import config from '../../../utils/config';

import imageBanner from '../../../assets/images/testimoniales-madre-hija-perro.jpg'
import imageRelatedContentThree from '../../../assets/images/testimonial-montse-jandula-alergia-acaros-polenes.jpg';

import './Testimonials.css';
import i18next from 'i18next';
import endpoints from '../../../utils/endpoints';


function Testimonials({ lang }) {

  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetAllergies();
  }, []);

  function handleGetAllergies() {
    axios.get(endpoints.testimonials.getTestimonials, {
      params: { lang }
    }).then((res) => {
      setTestimonials(res.data);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e)
    })
  }

  return (
    <Spin spinning={isLoading}>
      <div className="allergies page">
        <Seo title={i18next.t('testimonials.title')} description={(lang === 'es' ? 'Dos personas que han comprobado que convivir con la alergia es posible' : 'Duas pessoas que provaram que é possível conviver com alergias')} />
        <Row className='full_container allergies_banner'>
          <div title={(lang === 'es' ? 'Dos personas que han comprobado que convivir con la alergia es posible' : 'Duas pessoas que provaram que é possível conviver com alergias')} style={{ backgroundImage: `url(${imageBanner})` }}></div>
        </Row>
        <Row gutter={[24]} className='container d-flex flex-column'>
          <Col span={18}>
            <h1 className='allergies_title mb-10'>{i18next.t('testimonials.title')}</h1>
            <hr className='title_divider' />
            <p className='mt-30'>{i18next.t('testimonials.subtitle')}</p>
          </Col>
        </Row>
        <Row gutter={[24, 24]} className='container'>
          {testimonials.map((item, index) => (
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <div data-aos="fade-right" data-aos-delay={`${index}00`}>
                <div className='iframe_responsive_container'>
                  <iframe className='responsive_iframe' src={item.iframe} />
                </div>
                <p className='blue f-16 bold mt-10 mb-0'>{item.name}</p>
                <p className='blue f-16 mb-10 mt-0'>{item.description}</p>
              </div>
            </Col>
          ))}
        </Row>

        <Row className='container pb-40'>
          <Col span={24}>
            <h2 className='mb-10'><b>{i18next.t('related_content.title')}</b></h2>
            <p className='m-0'>{i18next.t('related_content.subtitle')}</p>
            <Row gutter={[24]} className='contenido_relacionado d-flex jc-sb'>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                <a
                  onClick={() => navigate(`/${lang}/tipos-de-alergia/alergia-polen/sintomas-causas`)}
                  className='related_content_div'
                  style={{ backgroundImage: `url('${config.server_ip}assets/media/thumbnail-polenes.jpg')` }}
                >
                  <p className='title'>{lang === 'es' ? 'Alergia al polen' : 'Alergia ao polen'}</p>
                </a>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                <a
                  onClick={() => navigate(`/${lang}/tipos-de-alergia/${lang === 'es' ? 'alergia-hongos' : 'alergia-fungos'}/sintomas-causas`)}
                  className='related_content_div'
                  style={{ backgroundImage: `url('${config.server_ip}assets/media/thumbnail-hongos.jpg')` }}
                >
                  <p className='title'>{lang === 'es' ? 'Alergia a los hongos' : 'Alergia aos fungos'}</p>
                </a>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                <a onClick={() => navigate(`/${lang}/tipos-de-alergia/${lang === 'es' ? 'alergia-en-ninos' : 'alergia-em-criancas'}/sintomas-causas`)}
                  className='related_content_div'
                  style={{ backgroundImage: `url('${config.server_ip}assets/media/thumbnail-ninos.jpg')` }}
                >
                  <p className='title'>{lang === 'es' ? 'La alergia en niños' : 'Alergia em crianças'}</p>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default Testimonials;
