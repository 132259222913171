import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import './Dashboard.css';
import config from '../../../utils/config'
import endpoints from '../../../utils/endpoints';
import i18next from 'i18next';

function Tags({ lang }) {

  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState({});
  const [isOpenModalCreateTag, setIsOpenModalCreateTag] = useState(false);
  const [isOpenModalEditTag, setIsOpenModalEditTag] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetTags();
  }, [lang]);

  function handleGetTags() {
    axios.get(endpoints.dashboard.getInfo, {
      params: { lang }
    }).then((res) => {
      console.log(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <div className='dashboard'>
      <Row gutter={[24]}>
        <Col span={6}>
          <div className='dashboard_card_info'>
            <Row>
              <Col span={12}>
                <p className="f-26 bold mt-0 mb-0">allergies</p>
              </Col>
              <Col span={12} className="d-flex jc-end ai-center">
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div className='dashboard_card_info'>
            <Row>
              <Col span={12}>
                <p className="f-26 bold mt-0 mb-0">manifestations</p>
              </Col>
              <Col span={12} className="d-flex jc-end ai-center">
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div className='dashboard_card_info'>
            <Row>
              <Col span={12}>
                <p className="f-26 bold mt-0 mb-0">articles</p>
              </Col>
              <Col span={12} className="d-flex jc-end ai-center">
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div className='dashboard_card_info'>
            <Row>
              <Col span={12}>
                <p className="f-26 bold mt-0 mb-0">podcasts</p>
              </Col>
              <Col span={12} className="d-flex jc-end ai-center">
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Tags;
