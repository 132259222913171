import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover, Input } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import ModalAddTestimonial from '../../../components/dashboard/ModalAddTestimonial/ModalAddTestimonial';
import ModalEditTestimonial from '../../../components/dashboard/ModalEditTestimonial/ModalEditTestimonial';
import config from '../../../utils/config'
import endpoints from '../../../utils/endpoints';
import './Testimonials.css';

const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Video iframe',
    dataIndex: 'iframe',
    key: 'iframe',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];


function Testimonial({ lang }) {

  const [tableData, setTableData] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [selectedTestimonial, setSelectedTestimonial] = useState({});
  const [isOpenModalCreateTestimonial, setIsOpenModalCreateTestimonial] = useState(false);
  const [isOpenModalEditTestimonial, setIsOpenModalEditTestimonial] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetTestimonial();
  }, [lang]);

  function handleGetTestimonial() {
    axios.get(endpoints.testimonials.getTestimonials, {
      params: { lang }
    }).then((res) => {
      setTestimonial(res.data);
      handlePrepareTableData(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleDeleteTestimonial(item) {
    axios.post(endpoints.testimonials.deleteTestimonial, {
      data: { item }
    }).then((res) => {
      handleGetTestimonial();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handlePrepareTableData(data) {
    let auxTableData = [];

    for (let i = 0; i < data.length; i++) {

      const contentDelete = (
        <div>
          <p>Want to delete {data[i].name}?</p>
          <Button type="primary" onClick={() => handleDeleteTestimonial(data[i])}>Delete</Button>
        </div>
      );

      let aux = {
        key: i,
        name: data[i].name,
        description: data[i].description,
        iframe: <div dangerouslySetInnerHTML={{ __html: data[i].iframe }}></div>,
        actions: (<div className='icons_table'>
          <EditOutlined className="icon icon_edit mr-10" onClick={() => handleOpenModalEditTestimonial(data[i])} />
          <Popover placement="topLeft" title={"Delete"} content={contentDelete} trigger="click">
            <DeleteOutlined className="icon icon_edit mr-10" />
          </Popover>
        </div>)
      }

      auxTableData.push(aux);
    }

    setTableData(auxTableData);
  }

  function handleOpenModalCreateTestimonial() {
    setIsOpenModalCreateTestimonial(true);
  }

  function handleCloseModalCreateTestimonial() {
    setIsOpenModalCreateTestimonial(false);
    handleGetTestimonial();
  }

  function handleOpenModalEditTestimonial(data) {
    setSelectedTestimonial(data)
    setIsOpenModalEditTestimonial(true);
  }

  function handleCloseModalEditTestimonial() {
    setIsOpenModalEditTestimonial(false);
    handleGetTestimonial();
  }

  function handleSearch(e) {
    let searchString = e.target.value;
    let searchData = testimonial.filter((item) => item.name.includes(searchString));
    handlePrepareTableData(searchData);
  }

  return (
    <div className='dashboard_allergies'>
      <ModalAddTestimonial open={isOpenModalCreateTestimonial} close={handleCloseModalCreateTestimonial} lang={lang} />
      <ModalEditTestimonial data={selectedTestimonial} open={isOpenModalEditTestimonial} close={handleCloseModalEditTestimonial} lang={lang} />
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={16}>
              <h1 className="mt-0 mb-0">Testimonial</h1>
            </Col>
            <Col span={8} className="d-flex jc-end ai-center">
              <Input size='large' onChange={handleSearch} className='mr-10' placeholder='Search...' />
              <Button onClick={handleOpenModalCreateTestimonial}>Adicionar</Button>
            </Col>
          </Row>
          <Table dataSource={tableData} columns={columns} />
        </Col>
      </Row>
    </div>
  );
}

export default Testimonial;
