import {
  createBrowserRouter,
  RouterProvider,
  Router,
  useNavigate,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { ConfigProvider } from 'antd';
import axios from 'axios';
import { useSSR, useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import AOS from 'aos';
import config from "./utils/config";
import { initGA, logPageView } from './utils/analytics';


import Home from './pages/website/Home/Home';
import Search from './pages/website/Search/Search';
import Main from './components/website/Main/Main';
import AllergiesSymptoms from "./pages/website/Allergies/Details/Symptoms/Symptoms";
import AllergiesPrevention from "./pages/website/Allergies/Details/Prevention/Prevention";
import AllergiesDiagnostic from "./pages/website/Allergies/Details/Diagnostic/Diagnostic";
import Allergies from "./pages/website/Allergies/Allergies";
import Login from "./pages/auth/Login/Login";
import Loading from "./pages/website/Loading/Loading";
import Diagnostic from "./pages/website/DiagnosticAndTreatment/Diagnostic";
import Treatment from "./pages/website/DiagnosticAndTreatment/Treatment";
import Resources from "./pages/website/Resources/Resources";
import Articles from "./pages/website/Articles/Articles";
import Videos from "./pages/website/Videos/Videos";
import Images from "./pages/website/Images/Images";
import Map from "./pages/website/Map/Map";
import Testimoniales from "./pages/website/Testimonials/Testimonials";
import Podcasts from "./pages/website/Podcasts/Podcasts";
import PodcastDetails from "./pages/website/Podcasts/PodcastDetails";
import AdvicesKids from "./pages/website/Advices/AdvicesKids";
import ContactUs from "./pages/website/ContactUs/ContactUs";
import AboutUs from "./pages/website/AboutUs/AboutUs";
import Faqs from "./pages/website/Faqs/Faqs";
import LegalNotices from "./pages/website/LegalNotices/LegalNotices";
import CookiesPolicy from "./pages/website/CookiesPolicy/CookiesPolicy";
import AdvicesSchool from "./pages/website/Advices/AdvicesSchool";
import AdvicesPlay from "./pages/website/Advices/AdvicesPlay";
import ArticlesDetails from "./pages/website/Articles/ArticlesDetails";
import AllergicKit from "./pages/website/AllergicKit/AllergicKit";
import Glossary from "./pages/website/Glossary/Glossary";
import LinksOfInterest from "./pages/website/LinksOfInterest/LinksOfInterest";

import MainDashboard from './components/dashboard/MainDashboard/MainDashboard';
import Dashboard from "./pages/dashboard/Dashboard/Dashboard";
import DashboardAllergies from "./pages/dashboard/Allergies/Allergies";
import DashboardAllergiesDetails from "./pages/dashboard/AllergiesDetails/AllergiesDetails";
import DashboardManifestations from "./pages/dashboard/Manifestations/Manifestations";
import DashboardManifestationsDetails from "./pages/dashboard/ManifestationsDetails/ManifestationsDetails";
import Manifestations from "./pages/website/Manifestations/Manifestations"
import ManifestationsDetails from "./pages/website/Manifestations/Details/Details"
import DashboardDiagnosticAndTreatment from "./pages/dashboard/DiagnosticAndTreatment/DiagnosticAndTreatment";
import DashboardAdvices from "./pages/dashboard/Advices/Advices";
import DashboardTestimonials from "./pages/dashboard/Testimonials/Testimonials";
import DashboardPodcasts from "./pages/dashboard/Podcasts/Podcasts";
import DashboardPodcastsDetails from "./pages/dashboard/PodcastsDetails/PodcastsDetails";
import DashboardAllergicKit from "./pages/dashboard/AllergicKit/AllergicKit";
import DashboardGlossary from "./pages/dashboard/Glossary/Glossary";
import DashboardLinksOfInterest from "./pages/dashboard/LinksOfInterest/LinksOfInterest";
import DashboardLegalNotices from "./pages/dashboard/LegalNotices/LegalNotices";
import DashboardCookiesPolicy from "./pages/dashboard/CookiesPolicy/CookiesPolicy";
import ImagesAndVideos from "./pages/dashboard/ImagesAndVideos/ImagesAndVideos";
import Tags from "./pages/dashboard/Tags/Tags";
import Media from "./pages/dashboard/Media/Media";
import DashboardArticles from "./pages/dashboard/Articles/Articles";
import DashboardUsers from "./pages/dashboard/Users/Users";


import api from "./utils/api";

import 'aos/dist/aos.css';

api.axiosCreate();

function App() {
  initGA('GTM-TS4WL26'); 

  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  const { t, i18n } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  /* CODIGO GOOGLE ANALYTICS */
  useEffect(() => {
    logPageView();
  }, [location]);

  useEffect(() => {
    if (navigator.language.includes('pt') && !location.pathname.includes('/admin') && location.pathname === '/') {
      console.log(location)
      navigate('/pt');
    }
    AOS.init();
    i18n.changeLanguage(window.location.pathname.includes('/pt') ? 'pt' : 'es');
    setTimeout(() => {
      setIsLoading(false);
    }, 2000)
  }, []);

  function handleLogin(data) {
    setUser(data)
    setIsLoggedIn(true);
  }

  function handleChangeLanguage(lng, path) {
    setIsLoading(true);
    setTimeout(() => {
      i18n.changeLanguage(lng);
      navigate(`/${lng}`);
    }, 100);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000)
  }

  function handleChangeLanguageDashboard(lng) {
    i18n.changeLanguage(lng);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#061848',
          fontFamily: 'Red Hat Text'
        },
      }}
    >
      {isLoading ?
        <Loading />
        :
        <div className={`App ${window.location.pathname.includes('/admin') ? 'admin' : ''}`}>
          <Routes>
            <Route element={<Main lang={i18n.resolvedLanguage} handleChangeLanguage={handleChangeLanguage} />}>
              <Route exact path="/" element={<Navigate to={`/${i18n.resolvedLanguage}`} replace />} />
              <Route index exact path="/es" element={<Home lang={"es"} />} />
              <Route exact path="/pt" element={<Home lang={"pt"} />} />
              <Route exact path="/es/tipos-de-alergia" element={<Allergies lang={"es"} />} />
              <Route exact path="/pt/tipos-de-alergia" element={<Allergies lang={"pt"} />} />
              <Route exact path="/es/tipos-de-alergia/:allergy/sintomas-causas" element={<AllergiesSymptoms lang={"es"} />} />
              <Route exact path="/pt/tipos-de-alergia/:allergy/sintomas-causas" element={<AllergiesSymptoms lang={"pt"} />} />
              <Route exact path="/es/tipos-de-alergia/:allergy/prevencion" element={<AllergiesPrevention lang={"es"} />} />
              <Route exact path="/pt/tipos-de-alergia/:allergy/prevencao" element={<AllergiesPrevention lang={"pt"} />} />
              <Route exact path="/es/tipos-de-alergia/:allergy/diagnostico-tratamiento" element={<AllergiesDiagnostic lang={"es"} />} />
              <Route exact path="/pt/tipos-de-alergia/:allergy/diagnostico-tratamento" element={<AllergiesDiagnostic lang={"pt"} />} />
              <Route exact path="/es/manifestaciones" element={<Manifestations lang={"es"} />} />
              <Route exact path="/pt/manifestacoes" element={<Manifestations lang={"pt"} />} />
              <Route exact path="/es/manifestaciones/:manifestation" element={<ManifestationsDetails lang={"es"} />} />
              <Route exact path="/pt/manifestacoes/:manifestation" element={<ManifestationsDetails lang={"pt"} />} />
              <Route exact path="/es/diagnostico-y-tratamiento/diagnostico-alergias" element={<Diagnostic lang={"es"} />} />
              <Route exact path="/pt/diagnostico-e-tratamento/diagnostico-alergias" element={<Diagnostic lang={"pt"} />} />
              <Route exact path="/es/diagnostico-y-tratamiento/tratamiento-alergias" element={<Treatment lang={"es"} />} />
              <Route exact path="/pt/diagnostico-e-tratamento/tratamento-alergias" element={<Treatment lang={"pt"} />} />
              <Route exact path="/es/recursos" element={<Resources lang={"es"} />} />
              <Route exact path="/pt/recursos" element={<Resources lang={"pt"} />} />
              <Route exact path="/es/recursos/mapa-polinico" element={<Map lang={"es"} />} />
              <Route exact path="/es/recursos/testimoniales" element={<Testimoniales lang={"es"} />} />
              <Route exact path="/pt/recursos/testemunhos" element={<Testimoniales lang={"pt"} />} />
              <Route exact path="/es/recursos/sabias-que" element={<Articles lang={"es"} />} />
              <Route exact path="/pt/recursos/sabias-que" element={<Articles lang={"pt"} />} />
              <Route exact path="/es/recursos/sabias-que/:slug" element={<ArticlesDetails lang={"es"} />} />
              <Route exact path="/pt/recursos/sabias-que/:slug" element={<ArticlesDetails lang={"pt"} />} />
              <Route exact path="/es/recursos/podcasts" element={<Podcasts lang={"es"} />} />
              <Route exact path="/pt/recursos/podcasts" element={<Podcasts lang={"pt"} />} />
              <Route exact path="/es/recursos/podcasts/:slug" element={<PodcastDetails lang={"es"} />} />
              <Route exact path="/pt/recursos/podcasts/:slug" element={<PodcastDetails lang={"pt"} />} />
              <Route exact path="/es/recursos/kit-del-paciente-alergico" element={<AllergicKit lang={"es"} />} />
              <Route exact path="/pt/recursos/kit-do-paciente-alergico" element={<AllergicKit lang={"pt"} />} />
              <Route exact path="/es/recursos/consejos-para-padres/la-alergia-ninos" element={<AdvicesKids lang={"es"} />} />
              <Route exact path="/pt/recursos/conselhos-para-pais/alergia-criancas" element={<AdvicesKids lang={"pt"} />} />
              <Route exact path="/es/recursos/consejos-para-padres/alergia-colegio" element={<AdvicesSchool lang={"es"} />} />
              <Route exact path="/pt/recursos/conselhos-para-pais/alergia-escola" element={<AdvicesSchool lang={"pt"} />} />
              <Route exact path="/es/recursos/consejos-para-padres/aprender-jugando" element={<AdvicesPlay lang={"es"} />} />
              <Route exact path="/pt/recursos/conselhos-para-pais/aprender-a-jogar" element={<AdvicesPlay lang={"pt"} />} />
              <Route exact path="/es/videos" element={<Videos lang={"es"} />} />
              <Route exact path="/pt/videos" element={<Videos lang={"pt"} />} />
              <Route exact path="/es/imagenes" element={<Images lang={"es"} />} />
              <Route exact path="/pt/imagens" element={<Images lang={"pt"} />} />
              <Route exact path="/es/consultanos" element={<ContactUs lang={"es"} />} />
              <Route exact path="/pt/consultenos" element={<ContactUs lang={"pt"} />} />
              <Route exact path="/es/quienes-somos" element={<AboutUs lang={"es"} />} />
              <Route exact path="/pt/quem-somos" element={<AboutUs lang={"pt"} />} />
              <Route exact path="/es/faqs" element={<Faqs lang={"es"} />} />
              <Route exact path="/pt/faqs" element={<Faqs lang={"pt"} />} />
              <Route exact path="/es/glosario" element={<Glossary lang={"es"} />} />
              <Route exact path="/pt/glossario" element={<Glossary lang={"pt"} />} />
              <Route exact path="/es/enlaces-de-interes" element={<LinksOfInterest lang={"es"} />} />
              <Route exact path="/pt/links-de-interesse" element={<LinksOfInterest lang={"pt"} />} />
              <Route exact path="/es/avisos-legales" element={<LegalNotices lang={"es"} />} />
              <Route exact path="/pt/avisos-legais" element={<LegalNotices lang={"pt"} />} />
              <Route exact path="/es/politica-cookies" element={<CookiesPolicy lang={"es"} />} />
              <Route exact path="/pt/politica-cookies" element={<CookiesPolicy lang={"pt"} />} />
              <Route exact path="/es/search" element={<Search lang={"es"} />} />
              <Route exact path="/pt/search" element={<Search lang={"pt"} />} />
            </Route>

            <Route exact path="/login" element={<Login handleLogin={handleLogin} />} />
            <Route element={<MainDashboard lang={i18n.resolvedLanguage} handleChangeLanguage={handleChangeLanguageDashboard} />}>
              <Route exact path="/admin/" element={<Dashboard lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/allergies" element={<DashboardAllergies lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/allergies/:id" element={<DashboardAllergiesDetails lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/manifestations" element={<DashboardManifestations lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/manifestations/:id" element={<DashboardManifestationsDetails lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/diagnostic-and-treatment" element={<DashboardDiagnosticAndTreatment lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/allergic-kit" element={<DashboardAllergicKit lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/advices" element={<DashboardAdvices lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/testimonials" element={<DashboardTestimonials lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/podcasts" element={<DashboardPodcasts lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/podcasts/:id" element={<DashboardPodcastsDetails lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/media" element={<Media lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/images-and-videos" element={<ImagesAndVideos lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/articles" element={<DashboardArticles lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/tags" element={<Tags lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/glossary" element={<DashboardGlossary lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/users" element={<DashboardUsers lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/links-of-interest" element={<DashboardLinksOfInterest lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/legal-notices" element={<DashboardLegalNotices lang={i18n.resolvedLanguage} />} />
              <Route exact path="/admin/cookies-policy" element={<DashboardCookiesPolicy lang={i18n.resolvedLanguage} />} />
            </Route>
          </Routes>
        </div>
      }
    </ConfigProvider>
  );
}

export default App;
