import ReactDOM from "react-dom";
import React, { Component, useEffect, useRef, useState } from "react";
import { createReactEditorJS } from "react-editor-js";
import dayjs from 'dayjs';
import './Editor.css';

import { EDITOR_JS_TOOLS } from "./Constants";
import { Spin } from "antd";

const defaultValue = {
  time: dayjs().unix(),
  blocks: [{
    type: "header",
    data: {
      text: "Content right here",
      level: 2
    }
  }]
}

export default function Editor({ className, value, index, onChange }) {

  const ReactEditorJS = createReactEditorJS();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (value) {
      setIsLoading(false);
    }
  }, [value]);


  function handleChangeEditor(e) {
    if (e) {
      e.saver.save().then((outputData) => {
        onChange(outputData, index);
      }).catch((error) => {
        console.log('Saving failed: ', error)
      });
    }
  }

  return (
    <>
      {!isLoading ?
        <div className={className}>
          <ReactEditorJS
            tools={EDITOR_JS_TOOLS}
            onChange={handleChangeEditor}
            defaultValue={value ? value : defaultValue}
          />
        </div>
        :
        <Spin spinning={isLoading}></Spin>
      }
    </>
  );
}
