import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin, Typography, Collapse, Select, Form, Input, Checkbox } from 'antd';
import { ArrowRightOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import i18next from 'i18next';
import config from '../../../utils/config';
import Seo from '../../../components/website/Seo/Seo';
import endpoints from '../../../utils/endpoints';
import bannerImage from '../../../assets/images/img-header-chica-campo-flores.jpg'
import logoImage from '../../../assets/images/logo-leti.svg'

import './Faqs.css';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;


function Faqs({ lang }) {

  const navigate = useNavigate();

  return (
    <div className="allergies_details page">
      <Seo title={'FAQs'} description={'FAQs'} />
      <Row className='full_container allergies_banner'>
        <div style={{ backgroundImage: `url(${bannerImage})` }}></div>
      </Row>
      <Row className='container d-flex flex-column'>
        <h1 className='allergies_title mb-10'>{i18next.t('faqs.title')}</h1>
        <hr className='title_divider' />
      </Row>
      <Row className='container'>
        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
          <Row gutter={[24]}>
            <Col span={24}>
              <p className='bold'>{i18next.t('faqs.title_1')}</p>
              <div dangerouslySetInnerHTML={{ __html: i18next.t('faqs.text_1') }}></div>
            </Col>
          </Row>
          <Divider className='blue-divider' />
          <Row gutter={[24]}>
            <Col span={24}>
              <p className='bold'>{i18next.t('faqs.title_2')}</p>
              <div dangerouslySetInnerHTML={{ __html: i18next.t('faqs.text_2') }}></div>
            </Col>
          </Row>
          <Divider className='blue-divider' />
          <Row gutter={[24]}>
            <Col span={24}>
              <p className='bold'>{i18next.t('faqs.title_3')}</p>
              <div dangerouslySetInnerHTML={{ __html: i18next.t('faqs.text_3') }}></div>
            </Col>
          </Row>
          <Divider className='blue-divider' />
          <Row gutter={[24]}>
            <Col span={24}>
              <p className='bold'>{i18next.t('faqs.title_4')}</p>
              <div dangerouslySetInnerHTML={{ __html: i18next.t('faqs.text_4') }}></div>
            </Col>
          </Row>
          <Divider className='blue-divider' />
          <Row gutter={[24]}>
            <Col span={24}>
              <p className='bold'>{i18next.t('faqs.title_5')}</p>
              <div dangerouslySetInnerHTML={{ __html: i18next.t('faqs.text_5') }}></div>
            </Col>
          </Row>
          <Divider className='blue-divider' />
          <Row gutter={[24]} className='mb-40'>
            <Col span={24}>
                <p className='bold'>{i18next.t('faqs.title_6')}</p>
                <p className='blue'>{i18next.t('faqs.text_6')} <a target="_blank" onClick={() => navigate(`/${lang}/${lang === 'es' ? 'quienes-somos' : 'quem-somos'}`)}><u>{i18next.t('footer.about')}</u></a>.</p>
               
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Faqs;
