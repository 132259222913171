import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin, Select } from 'antd';
import axios from 'axios';
import Masonry from '@mui/lab/Masonry';
import i18next, { t } from 'i18next';

import Seo from '../../../components/website/Seo/Seo';
import config from '../../../utils/config';

import imageBanner from '../../../assets/images/manifestaciones-alergicas-hombre-estornudando.jpg'

import './Articles.css';
import { Box } from '@mui/material';
import endpoints from '../../../utils/endpoints';


function Articles({ lang }) {

  const [originalArticles, setOriginalArticles] = useState([]);
  const [articles, setArticles] = useState([]);
  const [articlesToShow, setArticlesToShow] = useState([]);
  const [articlesTags, setArticlesTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [itemsPerLoad, setItemsPerLoad] = useState(6);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetArticles();
  }, []);

  function handleGetArticles() {
    axios.get(endpoints.articles.getArticles, {
      params: { lang }
    }).then((res) => {
      let auxArticles = JSON.parse(JSON.stringify(res.data[0]));
      let auxArticlesTags = res.data[1];

      for (let i = 0; i < auxArticles.length; i++) {
        if (auxArticles[i].tags) {
          auxArticles[i].tags = JSON.parse(auxArticles[i].tags);

          let aux = [];
          for (let z = 0; z < auxArticles[i].tags.length; z++) {
            let filterTag = auxArticlesTags.filter((item) => item.id === auxArticles[i].tags[z])[0];
            if (filterTag) {
              aux.push(filterTag.name);
            }
          }

          auxArticles[i].tags = aux;
        }
      }

      setOriginalArticles(res.data[0]);

      const ids = auxArticles.map(({ id }) => id);
      const filteredArticles = auxArticles.filter(({ id }, index) => !ids.includes(id, index + 1));
      auxArticles = filteredArticles.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);

      });

      setArticles(auxArticles);
      setArticlesToShow(auxArticles);

      let tagsOptions = [];

      tagsOptions.push({ value: '', label: 'Todos' });

      res.data[1].map((item) => {
        tagsOptions.push({ value: item.name, label: item.name });
      });

      setArticlesTags(tagsOptions);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e)
    })
  }

  function handleLoadMore() {
    setIsButtonLoading(true);
    setIsLoading(true);
    setTimeout(() => {
      setItemsPerLoad(itemsPerLoad + 6)
    }, 500)
    setTimeout(() => {
      setIsButtonLoading(false);
      setIsLoading(false);
    }, 1000)
  }

  function handleReadArticle(data) {
    navigate(`/${lang}/recursos/sabias-que/${data.slug}`)
  }

  const handleChange = (value) => {
    if (value === '') {
      setArticlesToShow(articles);
      setItemsPerLoad(6);
      return;
    }

    let thisFilter = articles.filter((article) => {
      if (article.tags.includes(value)) {
        return article;
      }
    });

    setItemsPerLoad(6);
    setArticlesToShow(thisFilter);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="allergies page">
        <Seo title={'¿Sabías que...?'} description={'Artículos'} />
        <div className="container fixed-articles-div">
          <Row gutter={[24]} className='container d-flex flex-column block-mobile'>
            <Col xs={24} sm={18} md={18} lg={18} xl={18}>
              <h1 className='allergies_title mb-10'>{i18next.t('menu.articles')}</h1>
              <hr className='title_divider' />
            </Col>
          </Row>
          <Row className='container d-flex flex-column' >
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Select
                size='large'
                className="select-articles mobile-w-100"
                defaultValue=""
                onChange={handleChange}
                options={articlesTags}
              />
            </Col>
          </Row>
        </div>
        <Row gutter={[24]} className='container d-flex mt-articles-list-div block-mobile'>
          <Box
            sx={{
              overflowAnchor: "none", // this prevents page from scrolling to top when new products are added on Firefox
            }}
          >
            <Masonry columns={3} spacing={1} sx={{ width: "auto" }}>
              {articlesToShow.slice(0, itemsPerLoad).map((item) => {
                return (
                  <Box key={item.id} onClick={() => handleReadArticle(item)} className="hover-cursor">
                    <div className='d-flex flex-column jc-start ai-start bg-light-blue-opacity p-20'>
                      <p className='m-0 f-14 text-uppercase bold green'>{item.tags[0]}</p>
                      <div className='mt-10 minh-200 maxh-200 w-100 bg-center bg-cover' style={{ backgroundImage: `url(${config.server_ip}assets/media/${item.img})` }}></div>
                      <p className='f-20 bold mt-20 mb-20'>{item.name}</p>
                      <div className='ellipsis description' dangerouslySetInnerHTML={{ __html: item.content }}></div>
                      <Button onClick={() => handleReadArticle(item)} className='w-100 bg-green border-green mt-20' type='primary' size='large'>{i18next.t('home.articles.button')}</Button>
                    </div>
                  </Box>
                )
              })}
            </Masonry>
          </Box>
        </Row>
        {itemsPerLoad < articlesToShow.length ?
          <Row gutter={[24]} className='mb-40 mt-20'>
            <Col span={24}>
              <Button size='large' type='primary' loading={isButtonLoading} onClick={handleLoadMore}>{i18next.t('home.articles.load_more')}</Button>
            </Col>
          </Row>
          :
          null
        }
      </div>
    </Spin >
  );
}

export default Articles;
