import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import config from "../../../utils/config";

export default function Seo({ title, description, img, style }) {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log('----- HELMET -----');
  }, [pathname, title]);

  return (

    <Helmet
      style={[{
        "cssText": `
        ${style}
    `
      }]}
    >
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${config.server_ip}assets/media/${img}`} />
      <meta property="og:url" content={pathname} />
      <meta property="og:type" content="website" />
    </Helmet>
  )
}