import { Breadcrumb, Layout, Menu, theme, Row, Col, Button } from 'antd';
import { useState, useEffect } from 'react';
import {
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Link } from 'react-router-dom';
import { DashboardOutlined, FileOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useSSR, useTranslation } from 'react-i18next';
import config from '../../../utils/config';
import './MainDashboard.css';
import endpoints from '../../../utils/endpoints';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem('DASHBOARD', 'admin/', <DashboardOutlined />),
  getItem('ALERGIAS', 'admin/allergies', <DashboardOutlined />),
  getItem('MANIFESTATIONS', 'admin/manifestations', <DashboardOutlined />),
  getItem('DIAGNOSTIC AND TREATMENT', 'admin/diagnostic-and-treatment', <DashboardOutlined />),
  getItem('TESTIMONIALS', 'admin/testimonials', <DashboardOutlined />),
  getItem('MEDIA', 'admin/media', <DashboardOutlined />),
  getItem('RESOURCES', 'sub2', <DashboardOutlined />, [
    getItem('ARTICLES', 'admin/articles', <FileOutlined />),
    getItem('TAGS', 'admin/tags', <FileOutlined />),
    getItem('ADVICES', 'admin/advices', <DashboardOutlined />),
    getItem('TESTIMONIALS', 'admin/testimonials', <FileOutlined />),
    getItem('IMG/VIDEOS', 'admin/images-and-videos', <FileOutlined />),
    getItem('PODCASTS', 'admin/podcasts', <DashboardOutlined />),
    getItem('ALLERGIC KIT', 'admin/allergic-kit', <DashboardOutlined />),
  ]),
  getItem('OTHER', 'sub3', <DashboardOutlined />, [
    getItem('GLOSSARY', 'admin/glossary', <DashboardOutlined />),
    getItem('LINKS OF INTEREST', 'admin/links-of-interest', <DashboardOutlined />),
    getItem('LEGAL NOTICES', 'admin/legal-notices', <DashboardOutlined />),
    getItem('COOKIES POLICY', 'admin/cookies-policy', <DashboardOutlined />),
  ]),
  getItem('USERS', 'admin/users', <DashboardOutlined />),
];

const lngs = {
  es: { nativeName: 'es' },
  pt: { nativeName: 'pt' }
}

const MainDashboard = ({ user, lang, handleChangeLanguage }) => {

  const location = useLocation();
  let pathname = location.pathname.split('/');

  const [current, setCurrent] = useState(pathname[pathname.length - 1] === "" ? "admin/" : `admin/${pathname[pathname.length - 1]}`);
  const [isLoading, setIsLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    let tokenData = localStorage.getItem('token');
    if (tokenData) {
      axios.post(endpoints.auth.verifyToken, {
        data: tokenData
      }).then((res) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }).catch((err) => {
        console.log(err);
        return navigate('/login');
      })
    } else {
      return navigate('/login');
    }
  }, []);

  function handleClickMenu(e) {
    navigate(e.key)
  }

  function handleLogout(){
    localStorage.removeItem('token');
    window.location.href = '/';
  }

  return (
    <Layout className='admin_layout'>
      <Sider
        width={250}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className='logo' onClick={() => navigate('/')}>
        </div>
        <Menu theme="dark" defaultSelectedKeys={[current]} mode="inline" items={items} onClick={handleClickMenu} />
      </Sider>
      <Layout className="site-layout">
        <Header>
          <div className='d-flex jc-end ai-center h-100'>
            <div className='d-flex'>
              <Button type='text' className='white' onClick={handleLogout}>Logout</Button>
            </div>
          <div className='languages_dashboard d-flex'>
            {Object.keys(lngs).map((lng) => (
              <div className={i18n.resolvedLanguage === lng ? 'lang-item selected' : 'lang-item'} key={lng} onClick={() => handleChangeLanguage(lng)}>
                {lngs[lng].nativeName}
              </div>
            ))}
          </div>
          </div>
        </Header>
        <Content
          className="admin_layout_content"
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
};
export default MainDashboard;