import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Spin, Modal } from 'antd';
import axios from 'axios';
import { ArrowRightOutlined } from '@ant-design/icons';

import Seo from '../../../components/website/Seo/Seo';
import config from '../../../utils/config';

import imageBanner from '../../../assets/images/podcasts-alergia-medicos.jpg'
import imageRelatedContentThree from '../../../assets/images/testimonial-montse-jandula-alergia-acaros-polenes.jpg';

import './Podcasts.css';
import i18next from 'i18next';
import endpoints from '../../../utils/endpoints';
import { Box } from '@mui/material';
import Masonry from '@mui/lab/Masonry';


function Podcasts({ lang }) {

  const [podcasts, setPodcasts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetPodcasts();
  }, []);

  function handleGetPodcasts() {
    axios.get(endpoints.podcasts.getPodcasts, {
      params: { lang }
    }).then((res) => {
      handlePrepareData(res.data);
    }).catch((e) => {
      console.log(e)
    })
  }

  function handlePrepareData(data) {
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      let auxInfo = JSON.parse(data[i].info);
      let intervener = "";
      for (let z = 0; z < auxInfo.length; z++) {
        if (z === 0) {
          intervener += auxInfo[z].intervener;
        } else {
          intervener += auxInfo[z].intervener;
        }

        if (auxInfo[z + 1] && auxInfo[z + 2]) {
          intervener += ", "
        } else if (auxInfo[z + 1] && !auxInfo[z + 2]) {
          intervener += " e "
        }
      }

      aux.push({
        name: data[i].name,
        intervener: auxInfo[0].intervener,
        description: data[i].description,
        thumbnail: data[i].thumbnail,
        intervener: intervener,
        slug: data[i].slug
      })
    }

    setPodcasts(aux);
    setIsLoading(false);
  }

  const handlePodcastToShow = (podcast) => {
    if (!podcast.slug) return;
    navigate(`/${lang}/recursos/podcasts/${podcast.slug}`);
  }

  return (
    <Spin spinning={isLoading}>
      <div className="allergies page">
        <Seo title={i18next.t('podcasts.title')} description={(lang === 'es' ? 'Consejos del alergólogo para pacientes' : 'Conselhos do alergologista para doentes')} />
        <Row className='full_container allergies_banner'>
          <div title={(lang === 'es' ? 'Consejos del alergólogo para pacientes' : 'Conselhos do alergologista para doentes')} style={{ backgroundImage: `url(${imageBanner})` }}></div>
        </Row>
        <Row gutter={[24]} className='container d-flex flex-column'>
          <Col xs={24} sm={18} md={18} lg={18} xl={18} >
            <h1 className='allergies_title mb-10'>{i18next.t('podcasts.title')}</h1>
            <hr className='title_divider' />
            <p className='mt-30'>{i18next.t('podcasts.subtitle')}</p>
          </Col>
        </Row>
        <Row gutter={[24]} className='container'>
          <Box
            sx={{
              overflowAnchor: "none", // this prevents page from scrolling to top when new products are added on Firefox
            }}
          >
            <Masonry columns={3} spacing={2} sx={{ width: "auto" }}>
              {podcasts.map((item) => {
                return (
                  <Box key={item.id} className="hover-cursor" onClick={() => handlePodcastToShow(item)}>
                    <div className='d-flex flex-column jc-start ai-start bg-light-blue-opacity p-20'>
                      <p className='m-0 title_podcast'>VIDEO PODCAST</p>
                      <div className='mt-10 minh-200 maxh-200 w-100 bg-center bg-cover' style={{ backgroundImage: `url(${config.server_ip}assets/media/${item.thumbnail})` }}></div>
                      <p className='f-20 bold mt-20 mb-0'>{item.name}</p>
                      <p className='mt-10 mb-20'>{item.intervener}</p>
                      <div className='ellipsis description mt-0' dangerouslySetInnerHTML={{ __html: item.description }}></div>
                      <Button className='w-100 bg-light-blue mt-20 border-0' type='primary' size='large' onClick={() => handlePodcastToShow(item)}>Ver podcast</Button>
                    </div>
                  </Box>
                )
              })}
            </Masonry>
          </Box>
        </Row>

        <Row className='container pb-40'>
          <Col span={24}>
            <h2 className='mb-10'><b>{i18next.t('related_content.title')}</b></h2>
            <p className='m-0'>{i18next.t('related_content.subtitle')}</p>
            <Row gutter={[24]} className='contenido_relacionado d-flex jc-sb'>
              <Col span={8}>
                <a
                  onClick={() => navigate(`/${lang}/tipos-de-alergia/alergia-polen/sintomas-causas`)}
                  className='related_content_div'
                  style={{ backgroundImage: `url('${config.server_ip}assets/media/thumbnail-polenes.jpg')` }}
                >
                  <p className='title'>{lang === 'es' ? 'Alergia al polen' : 'Alergia ao polen'}</p>
                </a>
              </Col>
              <Col span={8}>
                <a
                  onClick={() => navigate(`/${lang}/tipos-de-alergia/${lang === 'es' ? 'alergia-hongos' : 'alergia-fungos'}/sintomas-causas`)}
                  className='related_content_div'
                  style={{ backgroundImage: `url('${config.server_ip}assets/media/thumbnail-hongos.jpg')` }}
                >
                  <p className='title'>{lang === 'es' ? 'Alergia a los hongos' : 'Alergia aos fungos'}</p>
                </a>
              </Col>
              <Col span={8}>
                <a
                  onClick={() => navigate(`/`)}
                  className='related_content_div'
                  style={{ backgroundImage: `url(${imageRelatedContentThree})` }}
                >
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default Podcasts;
