import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Table, Popover, Input } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import axios from 'axios';
import ModalAddImageVideo from '../../../components/dashboard/ModalAddImageVideo/ModalAddImageVideo';
import ModalEditImageVideo from '../../../components/dashboard/ModalEditImageVideo/ModalEditImageVideo';
import config from '../../../utils/config'
import endpoints from '../../../utils/endpoints';
import './ImagesAndVideos.css';

const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Video iframe',
    dataIndex: 'iframe',
    key: 'iframe',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];


function ImagesAndVideos({ lang }) {

  const [tableData, setTableData] = useState([]);
  const [imagesVideos, setImagesVideos] = useState([]);
  const [selectedImageVideo, setSelectedImageVideo] = useState({});
  const [isOpenModalCreateImageVideo, setIsOpenModalCreateImageVideo] = useState(false);
  const [isOpenModalEditImageVideo, setIsOpenModalEditImageVideo] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetImagesVideos();
  }, [lang]);

  function handleGetImagesVideos() {
    axios.get(endpoints.images_videos.getImagesVideos, {
      params: { lang }
    }).then((res) => {
      setImagesVideos(res.data);
      handlePrepareTableData(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handlePrepareTableData(data) {
    let auxTableData = [];

    for (let i = 0; i < data.length; i++) {

      const contentDelete = (
        <div>
          <p>Want to delete {data[i].name}?</p>
          <Button type="primary" onClick={() => handleDeleteImageVideo(data[i])}>Delete</Button>
        </div>
      );

      let aux = {
        key: i,
        name: data[i].name,
        description: data[i].description,
        iframe: <div dangerouslySetInnerHTML={{ __html: data[i].iframe }}></div>,
        actions: (<div className='icons_table'>
          <EditOutlined className="icon icon_edit mr-10" onClick={() => handleOpenModalEditImageVideo(data[i])} />
          <Popover placement="topLeft" title={"Delete"} content={contentDelete} trigger="click">
            <DeleteOutlined className="icon icon_edit mr-10" />
          </Popover>
        </div>)
      }

      auxTableData.push(aux);
    }

    setTableData(auxTableData);
  }

  function handleOpenModalCreateImageVideo() {
    setIsOpenModalCreateImageVideo(true);
  }

  function handleCloseModalCreateImageVideo() {
    setIsOpenModalCreateImageVideo(false);
    handleGetImagesVideos();
  }

  function handleOpenModalEditImageVideo(data) {
    setSelectedImageVideo(data)
    setIsOpenModalEditImageVideo(true);
  }

  function handleCloseModalEditImageVideo() {
    setIsOpenModalEditImageVideo(false);
    handleGetImagesVideos();
  }

  function handleDeleteImageVideo(item) {
    axios.post(endpoints.images_videos.deleteImageVideo, {
      data: { id: item.id, lang }
    }).then((res) => {
      handleGetImagesVideos();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleSearch(e) {
    let searchString = e.target.value;
    let searchData = imagesVideos.filter((item) => item.name.includes(searchString));
    handlePrepareTableData(searchData);
  }

  return (
    <div className='dashboard_allergies'>
      <ModalAddImageVideo open={isOpenModalCreateImageVideo} close={handleCloseModalCreateImageVideo} lang={lang} />
      <ModalEditImageVideo data={selectedImageVideo} open={isOpenModalEditImageVideo} close={handleCloseModalEditImageVideo} lang={lang} />
      <Row>
        <Col span={24} className='dashboard_card'>
          <Row className='title_row mb-20'>
            <Col span={16}>
              <h1 className="mt-0 mb-0">Imagens And Videos</h1>
            </Col>
            <Col span={8} className="d-flex jc-end ai-center">
              <Input size='large' onChange={handleSearch} className='mr-10' placeholder='Search...' />
              <Button size='large' onClick={handleOpenModalCreateImageVideo}>Adicionar</Button>
            </Col>
          </Row>
          <Table dataSource={tableData} columns={columns} />
        </Col>
      </Row>
    </div>
  );
}

export default ImagesAndVideos;
