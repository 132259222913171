import { Modal, Form, Input, Upload, Row, Col, Tabs, Drawer, Button, Select, InputNumber } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import axios from 'axios'
import './DrawerEditAllergy.css';
import config from '../../../utils/config';

const { useForm } = Form;
const { Dragger } = Upload;


export default function PrincipalContent({ data, filePreview, fileBannerPreview, uploadProps, uploadPropsBanner, normFile }) {

  useEffect(() => {
    //console.log(data);
  }, []);

  return (
    <div>
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            hidden
            name="id"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="img"
            valuePropName='fileList'
            getValueFromEvent={normFile}
            label="Image"
            rules={[
              {
                required: true,
                message: 'Este campo é obrigatório'
              }
            ]}
          >
            <Dragger {...uploadProps} className={`dragger ${filePreview ? 'not_show' : ''}`}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                banned files.
              </p>
              <div
                className={`preview_file ${filePreview ? '' : 'not_show'}`}
                style={{ backgroundImage: `url(${(filePreview && filePreview.includes('base64')) ? filePreview : `${config.server_ip}assets/media/${filePreview}`})` }}
              >
              </div>
            </Dragger>
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            name="name"
            label="Nome"
            rules={[
              {
                required: true,
                message: 'Este campo é obrigatório'
              }
            ]}
          >
            <Input size='large' placeholder='Nome'></Input>
          </Form.Item>
          <Row gutter={[24]}>
            <Col span={12}>
              <Form.Item
                name="menu_name"
                label="Nome menu"
                rules={[
                  {
                    required: true,
                    message: 'Este campo é obrigatório'
                  }
                ]}
              >
                <Input size='large' placeholder='Nome menu'></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="menu_position"
                label="Posição do menu"
                rules={[
                  {
                    required: true,
                    message: 'Este campo é obrigatório'
                  }
                ]}
              >
                <InputNumber size='large' placeholder='Posição do menu' className='w-100' />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="slug"
            label="Slug"
            rules={[
              {
                required: true,
                message: 'Este campo é obrigatório'
              }
            ]}
          >
            <Input size='large' placeholder='Nome'></Input>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="img_alt"
        label="Image Alt"
        rules={[
          {
            required: true,
            message: 'Este campo é obrigatório'
          }
        ]}
      >
        <Input size='large' placeholder='Image alt'></Input>
      </Form.Item>
      <Form.Item
        name="banner"
        valuePropName='fileListBanner'
        getValueFromEvent={normFile}
        label="Banner"
        rules={[
          {
            required: true,
            message: 'Este campo é obrigatório'
          }
        ]}
      >
        <Dragger {...uploadPropsBanner} className={`dragger ${fileBannerPreview || data.img_banner ? 'not_show' : ''}`}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            banned files.
          </p>
          <div
            className={`preview_file ${fileBannerPreview ? '' : 'not_show'}`}
            style={{ backgroundImage: `url(${(fileBannerPreview && fileBannerPreview.includes('base64')) ? fileBannerPreview : `${config.server_ip}assets/media/${fileBannerPreview}`})` }}
          >
          </div>
        </Dragger>
      </Form.Item>
      <Form.Item
        name="banner_alt"
        label="Banner Alt"
        rules={[
          {
            required: true,
            message: 'Este campo é obrigatório'
          }
        ]}
      >
        <Input size='large' placeholder='Banner alt'></Input>
      </Form.Item>

      <Col span={24} className='d-flex jc-center w-100'>
        <Form.Item wrapperCol={{ span: 24 }} className='d-flex jc-center ai-center'>
          <Button type="primary" htmlType="submit" size='large'>
            Save
          </Button>
        </Form.Item>
      </Col>
    </div>
  )
}