import { Modal, Form, Input, Upload, Row, Col, Tabs, Drawer, Button, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import axios from 'axios'
import './DrawerEditAllergy.css';
import config from '../../../utils/config';
import RelatedContent from './RelatedContent';
import PrincipalContent from './PrincipalContent';
import endpoints from '../../../utils/endpoints';

const { useForm } = Form;
const { Dragger } = Upload;

export default function DrawerEditAllergy({ data, open, close, lang }) {

  const [form] = useForm();
  const [formRelatedContent] = useForm();

  const [fileList, setFileList] = useState([]);
  const [fileListBanner, setFileListBanner] = useState([]);
  const [fileListRelatedContentOne, setFileListRelatedContentOne] = useState([]);
  const [fileListRelatedContentTwo, setFileListRelatedContentTwo] = useState([]);
  const [fileListRelatedContentThree, setFileListRelatedContentThree] = useState([]);
  const [filePreview, setFilePreview] = useState('');
  const [fileBannerPreview, setFileBannerPreview] = useState('');
  const [filePreviewRelatedContent, setFilePreviewRelatedContent] = useState(['', '', '']);

  const uploadProps = {
    key: "file_img",
    accept: 'image/*',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview('');
    },
    beforeUpload: (file) => {
      handlePreview(file, 'img');
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: []
  };

  const uploadPropsBanner = {
    key: "file_banner",
    accept: 'image/*',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      const index = fileListBanner.indexOf(file);
      const newFileList = fileListBanner.slice();
      newFileList.splice(index, 1);
      setFileListBanner(newFileList);
      setFileBannerPreview('');
    },
    beforeUpload: (file) => {
      handlePreview(file, 'banner');
      setFileListBanner([file]);
      return false;
    },
    fileList: fileListBanner,
    defaultFileList: []
  };

  const uploadPropsRelatedContent = [
    {
      key: "related_content_1_file_img",
      accept: 'image/*',
      multiple: false,
      maxCount: 1,
      onRemove: (file) => {
        const index = fileListRelatedContentOne.indexOf(file);
        const newFileList = fileListRelatedContentOne.slice();
        newFileList.splice(index, 1);
        setFileListRelatedContentOne(newFileList);
        let filePreviewRelatedContentAux = JSON.parse(JSON.stringify(filePreviewRelatedContent))
        filePreviewRelatedContentAux[0] = "";
        setFilePreviewRelatedContent(filePreviewRelatedContentAux);
      },
      beforeUpload: async (file) => {
        setFileListRelatedContentOne([file]);
        let filePreviewRelatedContentAux = JSON.parse(JSON.stringify(filePreviewRelatedContent))
        filePreviewRelatedContentAux[0] = await handlePreviewRelatedContent(file);
        setFilePreviewRelatedContent(filePreviewRelatedContentAux);
        return false;
      },
      fileList: fileListRelatedContentOne,
      defaultFileList: []
    },
    {
      key: "related_content_2_file_img",
      accept: 'image/*',
      multiple: false,
      maxCount: 1,
      onRemove: (file) => {
        const index = fileListRelatedContentTwo.indexOf(file);
        const newFileList = fileListRelatedContentTwo.slice();
        newFileList.splice(index, 1);
        setFileListRelatedContentTwo(newFileList);
        let filePreviewRelatedContentAux = JSON.parse(JSON.stringify(filePreviewRelatedContent))
        filePreviewRelatedContentAux[1] = "";
        setFilePreviewRelatedContent(filePreviewRelatedContentAux);
      },
      beforeUpload: async (file) => {
        setFileListRelatedContentTwo([file]);
        let filePreviewRelatedContentAux = JSON.parse(JSON.stringify(filePreviewRelatedContent))
        filePreviewRelatedContentAux[1] = await handlePreviewRelatedContent(file);
        setFilePreviewRelatedContent(filePreviewRelatedContentAux);
        return false;
      },
      fileList: fileListRelatedContentTwo,
      defaultFileList: []
    },
    {
      key: "related_content_3_file_img",
      accept: 'image/*',
      multiple: false,
      maxCount: 1,
      onRemove: (file) => {
        const index = fileListRelatedContentThree.indexOf(file);
        const newFileList = fileListRelatedContentThree.slice();
        newFileList.splice(index, 1);
        setFileListRelatedContentThree(newFileList);
        let filePreviewRelatedContentAux = JSON.parse(JSON.stringify(filePreviewRelatedContent))
        filePreviewRelatedContentAux[2] = "";
        setFilePreviewRelatedContent(filePreviewRelatedContentAux)
      },
      beforeUpload: async (file) => {
        setFileListRelatedContentThree([file]);
        let filePreviewRelatedContentAux = JSON.parse(JSON.stringify(filePreviewRelatedContent))
        filePreviewRelatedContentAux[2] = await handlePreviewRelatedContent(file);
        setFilePreviewRelatedContent(filePreviewRelatedContentAux);
        return false;
      },
      fileList: fileListRelatedContentThree,
      defaultFileList: []
    }
  ];

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    data.related_content = (data.related_content && (typeof data.related_content !== 'object')) ? JSON.parse(data.related_content).related_content : data.related_content;

    if (data.img) {
      setFileList([{ uid: 'not_new', name: data.img }]);
      setFilePreview(data.img);
      data.img = [{ uid: 'not_new', name: data.img }];
    }

    if (data.banner) {
      setFileListBanner([{ uid: 'not_new', name: data.banner }]);
      setFileBannerPreview(data.banner);
      data.banner = [{ uid: 'not_new', name: data.banner }];
    }

    if (data.related_content) {
      for (let i = 0; i < data.related_content.length; i++) {
        if (i === 0 && data.related_content[i].img) {
          setFileListRelatedContentOne([{ uid: 'not_new', name: data.related_content[i].img }]);
          let aux = JSON.parse(JSON.stringify(filePreviewRelatedContent));
          aux[i] = data.related_content[i].img;
          data.related_content[i].img = [{ uid: 'not_new', name: data.related_content[i].img }];
          setFilePreviewRelatedContent(aux);
        }

        if (i === 1 && data.related_content[i].img) {
          setFileListRelatedContentTwo([{ uid: 'not_new', name: data.related_content[i].img }]);
          let aux = JSON.parse(JSON.stringify(filePreviewRelatedContent));
          aux[i] = data.related_content[i].img;
          data.related_content[i].img = [{ uid: 'not_new', name: data.related_content[i].img }];
          setFilePreviewRelatedContent(aux);
        }

        if (i === 2 && data.related_content[i].img) {
          setFileListRelatedContentThree([{ uid: 'not_new', name: data.related_content[i].img }]);
          let aux = JSON.parse(JSON.stringify(filePreviewRelatedContent));
          aux[i] = data.related_content[i].img;
          data.related_content[i].img = [{ uid: 'not_new', name: data.related_content[i].img }];
          setFilePreviewRelatedContent(aux);
        }
      }
    }

    form.setFieldsValue(data);
    formRelatedContent.setFieldsValue(data);
  }, [data])

  function handleSubmit(values) {

    let formData = new FormData();

    fileList.forEach((file) => {
      if (file.uid !== 'not_new') {
        formData.append('file', file);
      }
    });

    fileListBanner.forEach((file) => {
      if (file.uid !== 'not_new') {
        formData.append('file_banner', file);
      }
    });

    formData.append('data', JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.allergies.editAllergy}`, {
      method: "POST",
      body: formData
    }).then(res => res.json())
      .then((resData) => {
        setFileBannerPreview('');
        setFilePreview('');
        setFilePreviewRelatedContent(['', '', ''])
        setFileList([]);
        setFileListBanner([]);
        setFileListRelatedContentOne([]);
        setFileListRelatedContentTwo([]);
        setFileListRelatedContentThree([]);
        form.resetFields();
        formRelatedContent.resetFields();
        close();
      }).catch((error) => {
        console.log(error);
      });
  }

  function handleSubmitRelatedContent(values) {

    let formData = new FormData();

    fileListRelatedContentOne.forEach((file) => {
      if (file.uid !== 'not_new') {
        formData.append('file_1', file);
      }
    });

    fileListRelatedContentTwo.forEach((file) => {
      if (file.uid !== 'not_new') {
        formData.append('file_2', file);
      }
    });

    fileListRelatedContentThree.forEach((file) => {
      if (file.uid !== 'not_new') {
        formData.append('file_3', file);
      }
    });

    formData.append('data', JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.allergies.editAllergyRelatedContent}`, {
      method: "POST",
      body: formData
    }).then(res => res.json())
      .then((resData) => {
        setFileBannerPreview('');
        setFilePreview('');
        setFilePreviewRelatedContent(['', '', ''])
        setFileList([]);
        setFileListBanner([]);
        setFileListRelatedContentOne([]);
        setFileListRelatedContentTwo([]);
        setFileListRelatedContentThree([]);
        form.resetFields();
        formRelatedContent.resetFields();
        close();
      }).catch((error) => {
        console.log(error);
      });
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    if (type === "banner") {
      setFileBannerPreview(filePreview)
    } else {
      setFilePreview(filePreview)
    }
  };

  async function handlePreviewRelatedContent(file) {
    return new Promise(async (resolve, reject) => {
      let filePreview = await getBase64(file);
      resolve(filePreview);
    })
  };


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Drawer
      className='edit_allergies'
      open={open}
      onClose={close}
      width={1000}
      title="Edit Allergy"
    >
      <Row>
        <Col span={24}>
          <Tabs
            defaultActiveKey="1"
            type="card"
            style={{ marginBottom: 32 }}
            items={[
              {
                label: 'Principal Content',
                key: '1',
                children:
                  <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    initialValues={{ related_content: [{ type: 'dynamic' }, { type: 'dynamic' }, { type: 'dynamic' }] }}
                  >
                    <PrincipalContent
                      data={data}
                      filePreview={filePreview}
                      fileBannerPreview={fileBannerPreview}
                      uploadProps={uploadProps}
                      uploadPropsBanner={uploadPropsBanner}
                      normFile={normFile}
                      lang={lang}
                    />
                  </Form>
              },
              {
                label: 'Contenido Relacionado',
                key: '2',
                children:
                  <Form
                    form={formRelatedContent}
                    onFinish={handleSubmitRelatedContent}
                    layout="vertical"
                    initialValues={{ related_content: [{ type: 'dynamic' }, { type: 'dynamic' }, { type: 'dynamic' }] }}
                  >
                    <RelatedContent
                      data={data}
                      filePreview={filePreviewRelatedContent}
                      uploadProps={uploadPropsRelatedContent}
                      normFile={normFile}
                      lang={lang}
                    />
                  </Form>
              }
            ]}
          />
        </Col>
      </Row>
    </Drawer >
  )
};