import { Menu, Row, Col, Button, Drawer, Layout, Icon, List, Input } from 'antd';
import {
  Outlet, useLocation,
} from "react-router-dom";
import './DrawerMenu.css';
import { useEffect, useState } from 'react';
import i18next, { t } from 'i18next';
import axios from 'axios';
import endpoints from '../../../utils/endpoints';
import iconSearch from '../../../assets/icons/icon-lupa-busqueda.svg';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseCircleOutlined, CloseOutlined, YoutubeOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import DrawerMenuAllergies from './DrawerMenuAllergies';
import DrawerMenuManifestations from './DrawerMenuManifestations';
import DrawerMenuResources from './DrawerMenuResources';
import logoCura from '../../../assets/images/logo-curatualergia.svg'

const lngs = {
  es: { nativeName: 'es' },
  pt: { nativeName: 'pt' }
}

export default function DrawerMenu({ lang, open, close, handleChangeLanguage }) {

  const [allergies, setAllergies] = useState([]);
  const [manifestations, setManifestations] = useState([]);
  const [items, setItems] = useState([]);
  const [itemsAllergies, setItemsAllergies] = useState([]);
  const [itemsManifestations, setItemsManifestations] = useState([]);
  const [itemsResources, setItemsResources] = useState([]);

  const [isOpenMenuAllergies, setIsOpenMenuAllergies] = useState(false);
  const [isOpenMenuManifestations, setIsOpenMenuManifestations] = useState(false);
  const [isOpenMenuResources, setIsOpenMenuResources] = useState(false);

  const [current, setCurrent] = useState('');
  const [currentAllergies, setCurrentAllergies] = useState('');


  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(endpoints.allergies.getAllergies, {
      params: { lang }
    }).then((res) => {
      let allergiesItems = res.data;

      axios.get(endpoints.manifestations.getManifestations, {
        params: { lang }
      }).then((resManifestations) => {
        let manifestationsItems = resManifestations.data;


        allergiesItems.sort((a, b) => {
          if (a.menu_position % 2 > b.menu_position % 2) {
            return -1
          } else if (a.menu_position % 2 <= b.menu_position % 2) {
            return 1
          }

          return 0
        })
        let auxItemsAllergies = []
        allergiesItems.map((item) => {
          auxItemsAllergies.push({
            label: item.menu_name,
            key: `${lang}_${item.name.toLowerCase().replaceAll(' ', '')}_symptoms`,
            path: {
              es: `/${lang}/tipos-de-alergia/${item.slug}/sintomas-causas`,
              pt: `/${lang}/tipos-de-alergia/${item.slug}/sintomas-causas`
            },
          });
        })

        manifestationsItems.sort((a, b) => {
          if (a.menu_position % 2 > b.menu_position % 2) {
            return -1
          } else if (a.menu_position % 2 <= b.menu_position % 2) {
            return 1
          }

          return 0
        })

        let auxItemsManifestations = []
        manifestationsItems.map((item) => {
          auxItemsManifestations.push({
            label: item.menu_name,
            key: `${lang}_${item.name.toLowerCase().replaceAll(' ', '')}`,
            path: {
              es: `/${lang}/manifestaciones/${item.slug}/`,
              pt: `/${lang}/manifestacoes/${item.slug}`
            },
          });
        })


        let auxItemsResources = [{
          label: t('menu.map'),
          key: `${lang}_map`,
          path: {
            es: `/${lang}/recursos/mapa-polinico`,
            pt: `https://www.rpaerobiologia.com/previsao-polinica/lisboa`,
          },
        },
        {
          label: t('menu.testimonials'),
          key: `${lang}_testimonials`,
          path: {
            es: `/${lang}/recursos/testimoniales`,
            pt: `/${lang}/recursos/testemunhos`,
          },
        },
        {
          label: t('menu.articles'),
          key: `${lang}_articles`,
          path: {
            es: `/${lang}/recursos/sabias-que`,
            pt: `/${lang}/recursos/sabias-que`,
          },
        },
        {
          label: t('related_content.allergist'),
          key: `${lang}_podcasts`,
          path: {
            es: `/${lang}/recursos/podcasts`,
            pt: `/${lang}/recursos/podcasts`,
          },
        },
        {
          label: t('menu.advices'),
          key: `${lang}_advices`,
          path: {
            es: `/${lang}/recursos/consejos-para-padres/la-alergia-ninos`,
            pt: `/${lang}/recursos/conselhos-para-pais/alergia-criancas`,
          },
        },
        {
          label: t('images_videos.images'),
          key: `${lang}_images`,
          path: {
            es: `/${lang}/imagenes`,
            pt: `/${lang}/imagens`,
          },
        },
        {
          label: t('home.resources.allergic_kit'),
          key: `${lang}_allergic_kit`,
          path: {
            es: `/${lang}/recursos/kit-del-paciente-alergico`,
            pt: `/${lang}/recursos/kit-do-paciente-alergico`,
          },
        },
        {
          label: t('images_videos.videos'),
          key: `${lang}_videos`,
          path: {
            es: `/${lang}/videos`,
            pt: `/${lang}/videos`,
          },
        }
        ]

        setManifestations(manifestationsItems);
        setAllergies(allergiesItems);

        setItemsAllergies(auxItemsAllergies);
        setItemsManifestations(auxItemsManifestations);
        setItemsResources(auxItemsResources);
      }).catch((err) => {
        console.log(err);
      })
    }).catch((err) => {
      console.log(err);
    })
  }, [lang]);

  useEffect(() => {
    if (location.pathname.includes('tipos-de-alergia')) {
      let auxPath = location.pathname.split('tipos-de-alergia');
      if (auxPath.length > 1 && auxPath[1].length > 0) {
        if (open) setIsOpenMenuAllergies(true);
      }
    } else if (location.pathname.includes('manifestacoes') || location.pathname.includes('manifestaciones')) {
      let auxPath = lang === 'es' ? location.pathname.split('manifestaciones') : location.pathname.split('manifestacoes')
      if (auxPath.length > 1 && auxPath[1].length > 0) {
        if (open) setIsOpenMenuManifestations(true);
      }
    } else if (location.pathname.includes('recursos')) {
      let auxPath = location.pathname.split('recursos')
      if (auxPath.length > 1 && auxPath[1].length > 0) {
        if (open) setIsOpenMenuResources(true);
      }
    }
  }, [location, open === true])

  function onClickAllergies(e) {
    let menuItems = itemsAllergies;
    if (e.keyPath.length === 2) {
      let filterParent = menuItems.filter((item) => item.key === e.keyPath[1])[0];
      let filter = filterParent.children.filter((item) => item.key === e.key)[0];
      navigate(`${filter.path[`${lang}`]}`);
      setCurrent(e.key);
      close();
      setIsOpenMenuAllergies(false);
    } else {
      let filter = menuItems.filter((item) => item.key === e.key)[0];
      navigate(`${filter.path[`${lang}`]}`);
      setCurrent(e.key);
      close();
      setIsOpenMenuAllergies(false);
    }
  }

  function onClickManifestations(e) {
    let menuItems = itemsManifestations;
    if (e.keyPath.length === 2) {
      let filterParent = menuItems.filter((item) => item.key === e.keyPath[1])[0];
      let filter = filterParent.children.filter((item) => item.key === e.key)[0];
      navigate(`${filter.path[`${lang}`]}`);
      setCurrent(e.key);
      close();
      setIsOpenMenuManifestations(false);
    } else {
      let filter = menuItems.filter((item) => item.key === e.key)[0];
      navigate(`${filter.path[`${lang}`]}`);
      setCurrent(e.key);
      close();
      setIsOpenMenuManifestations(false);
    }
  }

  function onClickResources(e) {
    let menuItems = itemsResources;
    if (e.key == 'pt_map') {
      let filterParent = menuItems.filter((item) => item.key === e.key)[0];
      window.open(
        filterParent.path[`${lang}`], "_blank");
      close();
      setIsOpenMenuResources(false);
    } else {
      if (e.keyPath.length === 2) {
        let filterParent = menuItems.filter((item) => item.key === e.keyPath[1])[0];
        let filter = filterParent.children.filter((item) => item.key === e.key)[0];
        navigate(`${filter.path[`${lang}`]}`);
        setCurrent(e.key);
        close();
        setIsOpenMenuResources(false);
      } else {
        let filter = menuItems.filter((item) => item.key === e.key)[0];
        navigate(`${filter.path[`${lang}`]}`);
        setCurrent(e.key);
        close();
        setIsOpenMenuResources(false);
      }
    }
  }

  function handleClickMenuItem(e) {
    switch (e) {
      case 'allergies':
        navigate(`/${lang}/tipos-de-alergia`);
        close();
        break;
      case 'manifestations':
        navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}`);
        close();
        break;
      case 'resources':
        navigate(`/${lang}/recursos`);
        close();
        break;
      case 'diagnostic':
        navigate(`/${lang}/${lang === 'es' ? 'diagnostico-y-tratamiento' : 'diagnostico-e-tratamento'}/diagnostico-alergias`);
        close();
        break;
      default:
        break;
    }
  }

  function handleCloseMenuAllergies() {
    setIsOpenMenuAllergies(false);
  }

  function handleOpenMenuAllergies() {
    setIsOpenMenuAllergies(true);
  }

  function handleCloseMenuManifestations() {
    setIsOpenMenuManifestations(false);
  }

  function handleOpenMenuManifestations() {
    setIsOpenMenuManifestations(true);
  }

  function handleCloseMenuResources() {
    setIsOpenMenuResources(false);
  }

  function handleOpenMenuResources() {
    setIsOpenMenuResources(true);
  }

  function handleSearch(e) {
    navigate(`/${lang}/search?s=${e}`);
    setIsOpenMenuAllergies(false);
    setIsOpenMenuManifestations(false);
    setIsOpenMenuResources(false);
    close();
  }

  return (
    <>
      <Drawer
        className='mobile-drawer-menu'
        size={"large"}
        open={open}
        onClose={close}
        closable={false}
        footer={
          <div className='footer-drawer d-flex flex-column jc-center ai-center pb-30'>
            <div>
              <p className='f-16 white'>© 2023 LETI Pharma</p>
            </div>
            <img src={logoCura} alt="logo" onClick={() => navigate(`/${lang}`)} />
          </div>
        }
      >
        <div className='header-mobile-menu d-flex jc-sb ai-center'>
          <div className='d-flex jc-center ai-center'>
            <Search
              enterButton={<img src={iconSearch} alt="languages" className='h-100 maxh-20 hover-cursor' />}
              size='large'
              placeholder={i18next.t('home.search.placeholder')}
              onSearch={handleSearch}
            />
          </div>
          <div className='d-flex jc-center ai-center'>
            <CloseOutlined className='f-20' onClick={close} />
          </div>
        </div>
        <div className='mobile-menu'>
          <div className='mobile-menu-list'>
            <div className='mobile-menu-list-item d-flex jc-sb ai-center'>
              <div className='d-flex text' onClick={() => handleClickMenuItem('allergies')} >
                {t('menu.allergies')}
              </div>
              <div className='d-flex icon' onClick={handleOpenMenuAllergies}>
                <ArrowRightOutlined classID='white' />
              </div>
            </div>
            <div className='mobile-menu-list-item d-flex jc-sb ai-center'>
              <div className='d-flex text' onClick={() => handleClickMenuItem('manifestations')} >
                {t('menu.manifestations')}
              </div>
              <div className='d-flex icon' onClick={handleOpenMenuManifestations}>
                <ArrowRightOutlined classID='white' />
              </div>
            </div>
            <div className='mobile-menu-list-item d-flex jc-sb ai-center'>
              <div className='d-flex text' onClick={() => handleClickMenuItem('diagnostic')} >
                {t('menu.diagnostic_treatment')}
              </div>
            </div>
            <div className='mobile-menu-list-item d-flex jc-sb ai-center'>
              <div className='d-flex text' onClick={() => handleClickMenuItem('resources')} >
                {t('menu.resources')}
              </div>
              <div className='d-flex icon' onClick={handleOpenMenuResources}>
                <ArrowRightOutlined classID='white' />
              </div>
            </div>
          </div>
        </div>

        <div className='p-20 mt-30'>
          <div className='languages-mobile'>
            {Object.keys(lngs).map((lng) => (
              <div className={i18next.resolvedLanguage === lng ? 'language-item selected' : 'language-item'} key={lng} onClick={() => handleChangeLanguage(lng, window.location.pathname)}>
                {lngs[lng].nativeName}
              </div>
            ))}
          </div>
        </div>
      </Drawer>

      <DrawerMenuAllergies
        lang={lang}
        open={isOpenMenuAllergies}
        close={handleCloseMenuAllergies}
        items={itemsAllergies}
        onClick={onClickAllergies}
        handleSearch={handleSearch}
      />

      <DrawerMenuManifestations
        lang={lang}
        open={isOpenMenuManifestations}
        close={handleCloseMenuManifestations}
        items={itemsManifestations}
        onClick={onClickManifestations}
        handleSearch={handleSearch}
      />

      <DrawerMenuResources
        lang={lang}
        open={isOpenMenuResources}
        close={handleCloseMenuResources}
        items={itemsResources}
        onClick={onClickResources}
        handleSearch={handleSearch}
      />
    </>
  )
};