import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Collapse, Divider, Row, Select, Spin, Typography } from 'antd';
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import axios from 'axios';
import { ArrowRightOutlined } from '@ant-design/icons';

import Seo from '../../../components/website/Seo/Seo';
import config from '../../../utils/config';

import imageBanner from '../../../assets/images/consejos-padres-padre-nino.jpg'
import './Advices.css';
import i18next, { t } from 'i18next';
import endpoints from '../../../utils/endpoints';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

// Boilerplate stuff
const htmlParser = new Parser(React);
const processNodeDefinitions = new ProcessNodeDefinitions(React);
function isValidNode() {
  return true;
}

// Custom instructions for processing nodes
const processingInstructions = [
  // Create instruction for custom elements
  {
    shouldProcessNode: (node) => {
      // Process the node if it matches a custom element
      return (node.name && (node.name === 'collapse' || node.name === 'panel' || node.name === 'img'));
    },
    processNode: function (node, children, index) {
      if (node.name === 'collapse') {
        return <Collapse defaultactivekey={node.attribs.defaultactivekey}>{children}</Collapse>
      } else if (node.name === 'panel') {
        return <Panel header={node.attribs.header} key={node.attribs.key}>{children}</Panel>
      } else if (node.name === 'img') {
        return <img className={node.attribs.classname} src={`${config.server_ip}${node.attribs.src}`} alt={node.attribs.alt} title={node.attribs.title} />
      }
    }
  },
  // Default processing
  {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode
  }
];


function AdvicesKids({ lang }) {

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    handleGetAdvice()
  }, []);

  function handleGetAdvice() {
    axios.get(endpoints.advices.getAdvice, {
      params: { lang, type: 'kids' }
    }).then((res) => {
      handleRenderToHTML(res.data[0]);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleRenderToHTML(pageData) {
    let auxData = JSON.parse(pageData.content);
    let html = [];

    for (let y = 0; y < auxData.length; y++) {

      let htmlItems = auxData[y].editor.blocks.map((item) => {
        return (<>
          {item.type === "header" && <Title level={item.data.level}>{item.data.text}</Title>}
          {item.type === "paragraph" && <p dangerouslySetInnerHTML={{ __html: item.data.text }}></p>}
          {item.type === "list" && item.data.style === "unordered" &&
            <ul>
              {item.data.items.map(e => (
                <li dangerouslySetInnerHTML={{ __html: e }}></li>
              ))}
            </ul>
          }
          {item.type === "list" && item.data.style !== "unordered" &&
            <ol>
              {item.data.items.map(e => (
                <li dangerouslySetInnerHTML={{ __html: e }}></li>
              ))}
            </ol>
          }
          {item.type === "image" &&
            <img src={`${config.server_ip}assets/media/${item.name}`} />
          }
          {item.type === "raw" &&
            stringToHTML(item.data.html)
          }
        </>)
      })

      html.push(<div
        className='full_container'
        style={{
          backgroundColor: auxData[y].background ? auxData[y].backgroundColor : 'unset',
          paddingTop: auxData[y].paddingTop ? `${auxData[y].paddingTop}px` : 'unset',
          paddingBottom: auxData[y].paddingBottom ? `${auxData[y].paddingBottom}px` : 'unset',
          marginTop: auxData[y].marginTop ? `${auxData[y].marginTop}px` : 'unset',
          marginBottom: auxData[y].marginBottom ? `${auxData[y].marginBottom}px` : 'unset'
        }}
      ><div className='container'>{htmlItems}</div></div>)

    }

    let auxDataRelatedContent = pageData.related_content.filter(item => item.is_active === 1)
    pageData.content = html;
    pageData.related_content = auxDataRelatedContent;

    setData(pageData);
    setIsLoading(false);
  }

  const stringToHTML = (html) => {
    let htmlWithoutSpaces = html.replace(/\s+/g, ' ').trim()
    htmlWithoutSpaces = htmlWithoutSpaces.replace(/>\s+</g, '><');
    let reactComponent = htmlParser.parseWithInstructions(htmlWithoutSpaces,
      isValidNode, processingInstructions);
    return reactComponent;
  }

  function handleChangePage(e) {
    if (e === "kids") {
      navigate(`/${lang}/recursos/${lang === 'es' ? 'consejos-para-padres' : 'conselhos-para-pais'}/${lang === 'es' ? 'la-alergia-ninos' : 'alergia-criancas'}`)
    } else if (e === "school") {
      navigate(`/${lang}/recursos/${lang === 'es' ? 'consejos-para-padres' : 'conselhos-para-pais'}/${lang === 'es' ? 'alergia-colegio' : 'alergia-escola'}`)
    } else if (e === "play") {
      navigate(`/${lang}/recursos/${lang === 'es' ? 'consejos-para-padres' : 'conselhos-para-pais'}/${lang === 'es' ? 'aprender-jugando' : 'aprender-a-jogar'}`)
    }
  }

  return (
    <Spin spinning={isLoading}>
      <div className="allergies page advices_details">
        <Seo title={t('advices.title')} description={(lang === 'es' ? 'Padre y hijo alérgico' : 'Pai e filho alérgico')} />
        <Row className='full_container allergies_banner'>
          <div title={(lang === 'es' ? 'Padre y hijo alérgico' : 'Pai e filho alérgico')}style={{ backgroundImage: `url(${imageBanner})` }}></div>
        </Row>
        <Row gutter={[24]} className='container d-flex flex-column'>
          <Col span={18}>
            <h1 className='allergies_title mb-10'>{t('advices.title')}</h1>
            <hr className='title_divider' />
          </Col>
        </Row>
        <Row gutter={[24]} className='buttons_allergies container'>
          <Col span={12}>
            <Button
              className='active'
            >
              {i18next.t('advices.button_kids')}
            </Button>
            <Button
              onClick={() => navigate(`/${lang}/recursos/${lang === 'es' ? 'consejos-para-padres' : 'conselhos-para-pais'}/${lang === 'es' ? 'alergia-colegio' : 'alergia-escola'}`)}>
              {i18next.t('advices.button_school')}
            </Button>
            <Button
              onClick={() => navigate(`/${lang}/recursos/${lang === 'es' ? 'consejos-para-padres' : 'conselhos-para-pais'}/${lang === 'es' ? 'aprender-jugando' : 'aprender-a-jogar'}`)}>
              {i18next.t('advices.button_play')}
            </Button>
          </Col>
        </Row>
        <Row className='buttons_allergies_mobile container'>
          <Col span={24}>
            <Select
              className='w-100'
              size="large"
              onChange={handleChangePage}
              defaultValue={'kids'}
              options={[
                { value: 'kids', label: i18next.t('advices.button_kids') },
                { value: 'school', label: i18next.t('advices.button_school') },
                { value: 'play', label: i18next.t('advices.button_play') },
              ]}
            />
          </Col>
        </Row>
        {data.content}
      </div>
      <Row className='container pb-40 ml-10'>
        <Col span={24}>
          <h2 className='mb-10'><b>{i18next.t('related_content.title')}</b></h2>
          <p className='m-0'>{i18next.t('related_content.subtitle')}</p>
          <Row gutter={[24]} className='contenido_relacionado d-flex jc-sb'>
            <Col span={8}>
              <a
                onClick={() => navigate(`/${lang}/tipos-de-alergia/${lang === 'es' ? 'alergia-en-ninos' : 'alergia-em-criancas'}/sintomas-causas`)}
                className='related_content_div'
                style={{ backgroundImage: `url('${config.server_ip}assets/media/thumbnail-ninos.jpg')` }}
              >
                <p className='title'>{i18next.t('allergies.allergy_in_children')}</p>
              </a>
            </Col>
            <Col span={8}>
              <a
                onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${lang === 'es' ? 'alergia-ninos-sintomas' : 'alergia-criancas-sintomas'}/`)}
                className='related_content_div'
                style={{ backgroundImage: `url('${config.server_ip}assets/media/thumbnail-manifestaciones-ninos.jpg')` }}
              >
                <p className='title text-center p-5'>{i18next.t('home.manifestations.children_manifestations')}</p>
              </a>
            </Col>
            <Col span={8}>
              <a
                onClick={() => navigate(`/${lang}/tipos-de-alergia/alergia-polen/sintomas-causas`)}
                className='related_content_div'
                style={{ backgroundImage: `url('${config.server_ip}assets/media/thumbnail-polenes.jpg')` }}
              >
                <p className='title'>{i18next.t('home.allergies.al_pollen')}</p>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
}

export default AdvicesKids;
