import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Row, Input } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons'
import AOS from 'aos';
import { ReactComponent as ImageRinitis } from '../../../assets/icons/icon-manifestaciones-rinitis.svg'
import { ReactComponent as ImageAllergiesSking } from '../../../assets/icons/icon-manifestaciones-piel.svg'
import { ReactComponent as ImageAsma } from '../../../assets/icons/icon-manifestaciones-asma.svg'
import { ReactComponent as ImageOtherManifestations } from '../../../assets/icons/icon-manifestaciones-otras.svg'
import './AllergicManifestations.css';
import i18next from 'i18next';

AOS.init();

const { Search } = Input

export default function AllergicManifestations({ lang }) {

  const navigate = useNavigate();

  return (
    <div className="full_container allergic_manifestations">
      <div className="container">
        <Row gutter={[24]}>
          <Col span={24} className='d-flex flex-column jc-start ai-start'>
            <h1 className="mb-0 white">{i18next.t('home.manifestations.title')}</h1>
            <p className="mt-0 white f-26">{i18next.t('home.manifestations.subtitle')}</p>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} 
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${lang === 'es' ? 'rinitis-alergica-sintomas-causas-diagnostico-tratamiento' : 'rinitis-alergica-sintomas-causas-diagnostico-tratamiento'}`)}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="100">
              <ImageRinitis className="image maxw-65" />
              <p className='title'>{i18next.t('home.manifestations.rhinitis')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${lang === 'es' ? 'manifestaciones-alergicas-en-la-piel' : 'manifestacoes-alergicas-na-pele'}`)}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="200">
              <ImageAllergiesSking className="image maxw-65" />
              <p className='title'>{i18next.t('home.manifestations.skin_allergy')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} 
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}/${lang === 'es' ? 'asma-alergica-sintomas-causas-diagnostico-tratamiento/' : 'asma-alergica-sintomas-causas-diagnostico-tratamento'}`)}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="300">
              <ImageAsma className="image maxw-65" />
              <p className='title'>{i18next.t('home.manifestations.asthma')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} 
            onClick={() => navigate(`/${lang}/${lang === 'es' ? 'manifestaciones' : 'manifestacoes'}`)}>
            <div className='allergies_card' data-aos="fade-right" data-aos-delay="400">
              <ImageOtherManifestations className="image maxw-65" />
              <p className='title'>{i18next.t('home.manifestations.other')}</p>
              <ArrowRightOutlined />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
