import React, { useEffect, useState } from "react";
import StyleEditor from 'react-style-editor';
import { Spin } from "antd";

export default function Editor({ value, onChange }) {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if(value){
      setIsLoading(false);
    }
  }, [value])

  return (
    <>
      {!isLoading ?
        <StyleEditor
          value={value}
          onChange={onChange}
        />
        :
        <Spin spinning={isLoading}></Spin>
      }
    </>
  );
}
